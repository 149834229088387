<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useNotificationStore } from '@/stores/notifications'
import type { GetAssignmentInstanceStudentTask } from '@/open-api/generated'
import useCombineQueries from '@/composables/api/useCombineQueries'
import { injectUseGetMyAssignmentInstance } from '@/composables/api/queries/useGetMyAssignmentInstance'
import { injectUseGetMyAssignmentInstances } from '@/composables/api/queries/useGetMyAssignmentInstances'
import useActivityTaskState from '@/composables/useActivityTaskState'
import useEnterTaskAttempt from '@/composables/api/useEnterTaskAttempt'
import useScrollToTopOnChange from '@/composables/useScrollToTopOnChange'
import { Breadcrumb, TopLine, BackButton } from '@/components/modern/page-navigation'
import { Button } from '@/components/modern/ui/button'
import { LoadingCover } from '@/components/modern/ui/loading'
import Activity from '@/components/modern/activities-tasks-view'
import { PlayIcon, LoopIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern - Learner Activity View',
  meta: {
    permissionLevel: 'Student',
    isModern: true
  }
})

const router = useRouter()
const route = useRoute('Modern - Learner Activity View')
const assignmentInstanceId = computed(() => route.params.activityId)

const notificationStore = useNotificationStore()
const { track, isLoading, refetch } = useCombineQueries()
const { assignmentInstance } = track(
  injectUseGetMyAssignmentInstance({
    assignmentInstanceId,
    notificationStore
  })
)
track(injectUseGetMyAssignmentInstances({ notificationStore }))

const taskId = ref<string | null>(null)
const selectTask = (task_id: string) => {
  taskId.value = task_id
}
const deselectTask = () => {
  taskId.value = null
}

const { task, attempts, hasAttempts, hasIncompleteAttempt, canStartTask, canReviewTask } =
  useActivityTaskState({ assignmentInstance, taskId })

const enterTaskAttempt = useEnterTaskAttempt({
  assignmentInstanceId,
  notificationStore
})
const startTaskLoading = ref<boolean>(false)
const startTask = async (task: GetAssignmentInstanceStudentTask) => {
  startTaskLoading.value = true
  const taskAttemptId = await enterTaskAttempt(task)
  if (taskAttemptId) {
    router
      .push({
        name: 'Modern - Attempt Task or Review Task Attempt',
        params: {
          activityId: assignmentInstanceId.value,
          taskId: task.task_id,
          taskAttemptId
        }
      })
      .then(() => {
        refetch()
        selectTask(task.task_id)
      })
  } else {
    startTaskLoading.value = false
  }
}

useScrollToTopOnChange(taskId)
</script>

<template>
  <Breadcrumb v-if="task" :route :name="task.name" />
  <TopLine>
    <template #left>
      <BackButton v-if="task" name="activity" @click="deselectTask" />
      <BackButton v-else :to="{ name: 'Modern - My Activities' }" name="my activities" />
    </template>
    <template v-if="task" #right>
      <Button
        v-if="canStartTask"
        variant="default"
        size="xs"
        class="w-min"
        :disabled="startTaskLoading"
        @click="startTask(task)"
      >
        <PlayIcon class="mr-2 size-4" />
        <span v-if="hasIncompleteAttempt">Continue Task</span>
        <span v-else-if="hasAttempts">Retry Task</span>
        <span v-else>Start Task</span>
      </Button>
      <Button v-else-if="canReviewTask" variant="outline" size="xs" class="w-min" asChild>
        <RouterLink
          :to="{
            name: 'Modern - Attempt Task or Review Task Attempt',
            params: {
              activityId: assignmentInstanceId,
              taskId: task.task_id,
              taskAttemptId: attempts[0].task_attempt_id
            }
          }"
        >
          <LoopIcon class="mr-2 size-4" />
          <span>Review Task</span>
        </RouterLink>
      </Button>
    </template>
  </TopLine>
  <LoadingCover :isLoading centerScreen>
    <Activity
      v-if="assignmentInstance"
      :activity="assignmentInstance"
      :task
      :attempts
      :startTask
      @selectTask="selectTask"
    />
  </LoadingCover>
</template>
