<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useNotificationStore } from '@/stores/notifications'
import { mockInstanceFromInsights, findUserId } from '@/utils/preview-mock-instance'
import useCombineQueries from '@/composables/api/useCombineQueries'
import { injectUseGetCohortV2 } from '@/composables/api/queries/useGetCohortV2'
import { injectUseGetCohortAssignmentInsights } from '@/composables/api/queries/useGetCohortAssignmentInsights'
import { injectUseGetAssignmentInstanceStudentInsights } from '@/composables/api/queries/useGetAssignmentInstanceStudentInsights'
import useGetAssignmentInstanceStudentInsight from '@/composables/api/queries/useGetAssignmentInstanceStudentInsight'
import usePreviewActivity from '@/composables/api/usePreviewActivity'
import useTaskAttemptState from '@/composables/useTaskAttemptState'
import useScrollToTopOnChange from '@/composables/useScrollToTopOnChange'
import { ReviewTask, PerformTask } from '@/components/modern/task-attempts'
import Activity from '@/components/modern/activities-tasks-view'
import { Breadcrumb, TopLine, BackButton, ForwardButton } from '@/components/modern/page-navigation'
import { Button } from '@/components/modern/ui/button'
import { PlayIcon } from '@radix-icons/vue'
import { LoadingCover } from '@/components/modern/ui/loading'
import { ArrowLeftStartOnRectangleIcon } from '@heroicons/vue/24/outline'

definePage({
  name: 'Modern Cohorts - Preview Activity Instance',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const route = useRoute('Modern Cohorts - Preview Activity Instance')
const cohortId = computed(() => route.params.cohortId)
const assignmentInstanceId = computed(() => route.params.assignmentInstanceId)

const notificationStore = useNotificationStore()

const { track, isLoading } = useCombineQueries()
const { cohort } = track(injectUseGetCohortV2({ cohortId, notificationStore }))
const { activityInstances } = track(
  injectUseGetCohortAssignmentInsights({ cohortId, notificationStore })
)
const { insights } = track(
  injectUseGetAssignmentInstanceStudentInsights({ assignmentInstanceId, notificationStore })
)

const instance = computed(
  () =>
    activityInstances.value.find((a) => a.assignment_instance_id === assignmentInstanceId.value) ??
    null
)
const userId = computed(() => findUserId(insights))

const { tasks } = track(
  useGetAssignmentInstanceStudentInsight({ assignmentInstanceId, userId, notificationStore })
)

const mockAssignmentInstance = computed(() => {
  if (cohort.value && instance.value && userId.value) {
    return mockInstanceFromInsights(cohort.value, instance.value, tasks.value)
  } else {
    return null
  }
})

const {
  selectTask,
  deselectTask,

  viewAttempt,
  stopViewingAttempt,

  activity,
  task,
  taskAttempt,
  attempts,
  allAttempts,

  startTaskPreview,
  submitting,
  handleCompleted,
  returnToPreviewingActivity,
  dismissAttempt,

  hasIncompleteAttempts,
  inAttempt
} = usePreviewActivity({ mockAssignmentInstance, notificationStore })

const { completed, inRubric, showRubric, canShowRubric, hideRubric, canHideRubric } =
  useTaskAttemptState({ task, taskAttempt })

useScrollToTopOnChange(() => task.value?.task_id)
</script>

<template>
  <Breadcrumb :route name="Preview" />
  <TopLine>
    <template #center>
      <Button variant="destructive" size="xs" class="w-min" asChild>
        <RouterLink
          :to="{
            name: 'Modern Cohorts - Activity Instance',
            params: { cohortId, assignmentInstanceId }
          }"
        >
          <ArrowLeftStartOnRectangleIcon class="mr-2 size-4" />
          <span>Exit Preview</span>
        </RouterLink>
      </Button>
    </template>
    <template #left>
      <BackButton v-if="inAttempt && canHideRubric" name="transcript" @click="hideRubric" />
      <BackButton v-else-if="inAttempt" name="previewing task" @click="stopViewingAttempt" />
      <BackButton v-else-if="task" name="previewing activity" @click="deselectTask" />
    </template>
    <template v-if="!isLoading" #right>
      <ForwardButton
        v-if="inAttempt && canShowRubric"
        text="Continue to feedback"
        @click="showRubric"
      />
      <ForwardButton
        v-else-if="inAttempt && completed"
        text="Return to previewing activity"
        @click="returnToPreviewingActivity"
      />
      <template v-else-if="!inAttempt && task">
        <Button
          v-if="hasIncompleteAttempts"
          variant="default"
          size="xs"
          class="w-min"
          @click="startTaskPreview(task)"
        >
          <PlayIcon class="mr-2 size-4" />
          <span>Continue Task</span>
        </Button>
        <Button
          v-else-if="attempts.length && attempts.length < task.max_attempts"
          variant="default"
          size="xs"
          class="w-min"
          @click="startTaskPreview(task)"
        >
          <PlayIcon class="mr-2 size-4" />
          <span>Retry Task</span>
        </Button>
        <Button
          v-else-if="attempts.length < task.max_attempts"
          variant="default"
          size="xs"
          class="w-min"
          @click="startTaskPreview(task)"
        >
          <PlayIcon class="mr-2 size-4" />
          <span>Start Task</span>
        </Button>
      </template>
    </template>
  </TopLine>
  <LoadingCover :isLoading centerScreen>
    <ReviewTask
      v-if="inAttempt && completed && task && taskAttempt"
      :taskAttemptId="taskAttempt.task_attempt_id"
      :taskPayload="task.payload"
      :attemptPayload="taskAttempt.payload"
      :inRubric
    />
    <PerformTask
      v-else-if="inAttempt && task"
      :taskPayload="task.payload"
      :attemptPayload="taskAttempt?.payload ?? null"
      :handleCompleted
      :submitting
    />
    <Activity
      v-else-if="activity"
      :activity
      :task
      :attempts
      :previewAttempts="allAttempts"
      :startTask="startTaskPreview"
      @selectTask="selectTask"
      @viewAttempt="viewAttempt"
      @dismissAttempt="dismissAttempt"
    />
    <div v-else class="w-full text-center">
      You must add at least one learner to a cohort to view the preview.
    </div>
  </LoadingCover>
</template>
