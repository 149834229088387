<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { Breadcrumb } from '@/components/modern/page-navigation'
import { useNotificationStore } from '@/stores/notifications'
import { injectUseGetCohortV2 } from '@/composables/api/queries/useGetCohortV2'

definePage({
  name: 'Cohort Activities Breadcrumb',
  redirect: 'Modern Cohorts - Cohort Activities List',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const route = useRoute('Cohort Activities Breadcrumb')
const cohortId = computed(() => route.params.cohortId)

const notificationStore = useNotificationStore()
const { cohort } = injectUseGetCohortV2({ cohortId, notificationStore })
</script>

<template>
  <Breadcrumb :route="{ name: 'Modern Cohorts - Cohort Activities List' }" :name="cohort?.name" />
  <RouterView />
</template>
