<script setup lang="ts">
import type { CharacterOutput, CreateCharacterInput } from '@/open-api/generated'
import { onBeforeMount, ref, computed, watch } from 'vue'
import { ArrowRightIcon, LockOpenIcon, TrashIcon, PlayIcon } from '@heroicons/vue/24/outline'
import { NotificationStatus } from '@/types/notification'
import { useContentStore } from '@/stores/content'
import { useNotificationStore } from '@/stores/notifications'
import { useAuthStore } from '@/stores/auth'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomDeleteModal from '@/components/utils/CustomDeleteModal.vue'
import CustomModal from '@/components/utils/CustomModal.vue'
import CustomInput from '@/components/utils/CustomInput.vue'
import Api from '@/open-api'
import CustomTabs, { type TabOption } from '@/components/utils/CustomTabs.vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import useSearchRubrics from '@/composables/api/useSearchRubrics'
import useStartConversation from '@/composables/api/mutations/useStartConversation'
import { CommandState } from '@/types/api'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose
} from '@/components/modern/ui/dialog'
import { SearchSelect, organizationConfig } from '@/components/modern/ui/search-select'
import { Button } from '@/components/modern/ui/button'
import { CopyIcon, Cross1Icon } from '@radix-icons/vue'
import { ChevronLeftIcon } from '@heroicons/vue/24/outline'
import type { RouteLocationRaw } from 'vue-router'
import useAllOrganizations from '@/composables/api/useAllOrganizations'
import useCopyCharacterToOrganization from '@/composables/api/mutations/useCopyCharacterToOrganization'
import useScrollToTop from '@/composables/useScrollToTop'
import { Breadcrumb } from '@/components/modern/page-navigation'

definePage({
  name: 'Character',
  meta: {
    permissionLevel: 'Educator',
    requiresAuthoring: true
  }
})

// ==================================================
// Init
// ==================================================
const route = useRoute<
  | 'Character'
  | 'Character Access Controls'
  | 'Character Prompts'
  | 'Character Investigations'
  | 'Character Actions'
>()
const router = useRouter()
const authStore = useAuthStore()
const contentStore = useContentStore()
const notificationStore = useNotificationStore()
const characterLoading = ref(false)
const saveLoading = ref(false)
const deleteCharacterModalStatus = ref(false)
const deleteCharacterLoading = ref(false)
const { state, execute, reset } = useStartConversation({ notificationStore })
const previewLoading = computed((): boolean => state.value === CommandState.IN_PROGRESS)
const isReadOnly = computed(() => {
  return (
    route.params.characterId !== 'new' &&
    !authStore.isAtLeastStaffUser &&
    !(
      authStore.organizationId &&
      authStore.organizationId ===
        (contentStore.editingCharacter as CharacterOutput)?.owning_organization_id
    )
  )
})

onBeforeMount(async () => {
  await setCurrentCharacter()
})

const setCurrentCharacter = async () => {
  if (route.params.characterId === 'new') {
    contentStore.setEditingCharacter(contentStore.newCharacter)
  } else if (
    (contentStore.editingCharacter as CharacterOutput)?.character_id !== route.params.characterId
  ) {
    characterLoading.value = true
    try {
      const character = await Api.Content.getCharacterEndpoint(route.params.characterId as string)

      if (!character) {
        router.push({ name: 'Characters List' })
      }

      contentStore.setEditingCharacter(character)
    } catch (err: any) {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    } finally {
      characterLoading.value = false
    }
  }
}
watch(() => route.params.characterId, setCurrentCharacter)

const characterName = computed<string>(() =>
  characterLoading.value
    ? ''
    : `${contentStore.editingCharacter?.given_name} ${contentStore.editingCharacter?.family_name}`
)

onBeforeRouteLeave((to, from, next) => {
  if (
    route.params.characterId === 'new' &&
    !(to.name as string).includes('Character') &&
    characterComplete.value
  ) {
    const answer = window.confirm(
      'Warning: You have unsaved changes. \nNavigating away from this page will delete any unsaved changes.'
    )
    if (answer) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
})

// ==================================================
// Character Nav
// ==================================================
const tabOptions = computed((): TabOption[] => {
  if (!contentStore.editingCharacter) {
    return []
  }
  const {
    given_name,
    family_name,
    avatar_url,
    clinician_role,
    internal_label,
    public_description,
    voice_id,
    prompt
  } = contentStore.editingCharacter

  return [
    {
      displayName: 'Config',
      routeName: 'Character Config',
      needsAction:
        !(given_name || '').trim() ||
        !(clinician_role || '').trim() ||
        !(avatar_url || '') ||
        !(family_name || '').trim() ||
        !(internal_label || '').trim() ||
        !(public_description || '').trim() ||
        !voice_id
    },
    {
      displayName: 'Prompts',
      routeName: 'Character Prompts',
      needsAction: !(prompt || '').trim()
    },
    {
      displayName: 'Investigations',
      routeName: 'Character Investigations'
    },
    {
      displayName: 'Actions',
      routeName: 'Character Actions'
    }
  ]
})

// ==================================================
//  Create Character
// ==================================================
const characterComplete = computed(() => {
  if (!contentStore.editingCharacter) {
    return true
  }

  const {
    given_name,
    family_name,
    avatar_url,
    clinician_role,
    public_description,
    internal_label,
    voice_id,
    prompt
  } = contentStore.editingCharacter
  return (
    !(given_name || '').trim() ||
    !clinician_role ||
    !avatar_url ||
    !(family_name || '').trim() ||
    !(internal_label || '').trim() ||
    !voice_id ||
    !(prompt || '').trim() ||
    !(public_description || '').trim()
  )
})

const next = () => {
  const routeName = route.name as string
  const nextRoute = tabOptions.value.findIndex((nav) => routeName.includes(nav.displayName))

  if (nextRoute >= 0) {
    // Annoying as shit typescript bug means we need to cast the router.push arg to RouteLocationRaw
    router.push({
      name: tabOptions.value[nextRoute + 1].routeName
    } as RouteLocationRaw)
  }
}

const createCharacter = async () => {
  saveLoading.value = true

  await Api.Content.createCharacterEndpoint(contentStore.editingCharacter as CreateCharacterInput)
    .then((res) => {
      router
        .push({
          name: 'Character Config',
          params: { characterId: res.character_id }
        })
        .then(async () => {
          await setCurrentCharacter()
        })
      notificationStore.addNotification({
        subtitle: 'Character successfully created',
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      saveLoading.value = false
    })
}

// ==================================================
//  Simulate Character
// ==================================================
const previewRubricId = ref('')
const rubricSelectModalOpen = ref(false)
const previewRubricSearchString = ref('')
const { rubricList: rubricSearchResults, isLoading: isRubricSearchLoading } = useSearchRubrics({
  rubricSearchString: previewRubricSearchString,
  notificationStore
})

const onPreviewRubricSearch = (searchString: string) => {
  // Need to do this because custom input clears the search string on selection
  if (searchString) {
    previewRubricSearchString.value = searchString
  }
}
const previewCharacter = async () => {
  if (
    !(
      contentStore.editingCharacter &&
      'character_id' in contentStore.editingCharacter &&
      contentStore.editingCharacter.character_id
    )
  ) {
    notificationStore.addNotification({
      subtitle: 'You need to save the character first.',
      status: NotificationStatus.WARNING
    })
    return
  }
  const characterId = ref<string>(contentStore.editingCharacter.character_id)

  if (!previewRubricId.value) {
    notificationStore.addNotification({
      subtitle: 'You need to select a rubric first.',
      status: NotificationStatus.DANGER
    })
    return
  }

  if (state.value === CommandState.IN_PROGRESS) {
    return
  }

  const conversationId = await execute({
    characterId,
    rubricId: previewRubricId
  })

  if (state.value === CommandState.SUCCESS && conversationId) {
    reset()
    router.push({
      name: 'Cohort Conversation',
      params: { conversationId }
    })
  } else {
    reset()
    notificationStore.addDANGER('Sorry, there was an error trying to start the conversation.')
  }
}

// ==================================================
// Delete Rubric
// ==================================================
const confirmDeleteCharacter = () => {
  deleteCharacterLoading.value = true
  Api.Content.deleteCharacterEndpoint({
    character_id: route.params.characterId as string
  })
    .then(() => {
      deleteCharacterModalStatus.value = false

      router.push({
        name: 'Characters List'
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      deleteCharacterLoading.value = false
    })
}

const copyModalOpen = ref<boolean>(false)
const targetOrganizationId = ref<string | undefined>(undefined)
const { organizations, organizationsLoading } = useAllOrganizations({ notificationStore })
const characterId = computed<string>(() => route.params.characterId)
const scrollToTop = useScrollToTop()
const copyCharacterRequest = useCopyCharacterToOrganization({
  characterId,
  targetOrganizationId,
  notificationStore
})

const copyCharacter = async () => {
  const { state, execute, reset } = copyCharacterRequest
  if (state.value === CommandState.IN_PROGRESS) {
    return
  }
  const newCharacterId = await execute().finally(reset)

  if (newCharacterId) {
    copyModalOpen.value = false
    const targetOrganization = organizations.value.find(
      (org) => org.organization_id === targetOrganizationId.value
    )
    if (targetOrganization) {
      notificationStore.addWARNING(`Now viewing copy belonging to ${targetOrganization.name}.`)
    }
    router.push({
      name: 'Character Config',
      params: { characterId: newCharacterId }
    })
    scrollToTop()
  }
}
</script>

<template>
  <Breadcrumb :route="{ name: 'Character Config' }" :name="characterName" />
  <div
    :class="[
      'flex flex-col gap-y-5',
      {
        'h-full': ['Character Prompts', 'Character Access Controls'].includes(route.name as string)
      }
    ]"
  >
    <div class="sticky -top-3 z-50 flex flex-col gap-3 bg-white pt-3">
      <div
        v-if="route.name !== 'Character Access Controls'"
        class="flex cursor-pointer items-center gap-2 px-5 text-sc-grey-700"
        @click="router.push({ name: 'Characters List' })"
      >
        <ChevronLeftIcon class="h-4 w-4" />
        <h4 class="font-normal text-sc-grey-700">Back to all characters</h4>
      </div>
      <div class="flex justify-between px-5">
        <div class="flex flex-row items-center">
          <h1 class="mr-5 text-2xl font-medium">
            {{ route.params?.characterId === 'new' ? 'Create Character' : characterName }}
          </h1>
          <CustomButton v-if="isReadOnly" buttonType="grey" buttonSize="sm" :canSelect="false">
            READ-ONLY
          </CustomButton>
        </div>

        <div class="flex gap-3">
          <CustomButton
            v-if="route.name === 'Character Access Controls'"
            buttonType="admin-secondary"
            @click="router.push({ name: 'Character Config' })"
          >
            Back to Character
          </CustomButton>
          <template v-if="route.params.characterId === 'new'">
            <CustomButton
              v-if="route.name !== 'Character Actions'"
              buttonType="admin-primary"
              :endIcon="ArrowRightIcon"
              @click="next"
            >
              Next
            </CustomButton>
            <CustomButton
              v-else
              :disabled="characterComplete"
              :loading="saveLoading"
              buttonType="admin-primary"
              @click="createCharacter"
            >
              Create Character
            </CustomButton>
          </template>

          <!-- Copy character to organization modal -->
          <template
            v-if="
              route.params.characterId !== 'new' &&
              authStore.isAtLeastStaffUser &&
              authStore.flagAuthoringEnabled &&
              route.name !== 'Character Access Controls'
            "
          >
            <Dialog v-model:open="copyModalOpen">
              <DialogTrigger asChild>
                <CustomButton buttonType="admin-secondary" :startIcon="CopyIcon">
                  Copy to Organization
                </CustomButton>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Copy character to organization</DialogTitle>
                  <DialogDescription>
                    Select an organization to copy this character to.
                  </DialogDescription>
                </DialogHeader>
                <div class="min-w-full">
                  <SearchSelect
                    v-bind="organizationConfig"
                    v-model:value="targetOrganizationId"
                    :data="organizations"
                    :isLoading="organizationsLoading"
                  />
                </div>
                <DialogFooter>
                  <DialogClose asChild>
                    <Button variant="outline" size="xs">
                      <Cross1Icon class="mr-2 size-4" />
                      <span>Cancel</span>
                    </Button>
                  </DialogClose>
                  <Button
                    variant="default"
                    size="xs"
                    :disabled="copyCharacterRequest.state.value === CommandState.IN_PROGRESS"
                    @click="copyCharacter"
                  >
                    <CopyIcon class="mr-2 size-4" />
                    <span>Copy</span>
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </template>

          <CustomButton
            v-if="
              route.params.characterId !== 'new' &&
              route.name !== 'Character Access Controls' &&
              !isReadOnly
            "
            buttonType="admin-secondary"
            :startIcon="PlayIcon"
            :loading="previewLoading"
            @click="rubricSelectModalOpen = true"
          >
            Preview Character
          </CustomButton>

          <CustomButton
            v-if="
              route.params.characterId !== 'new' &&
              authStore.isAtLeastStaffUser &&
              route.name !== 'Character Access Controls' &&
              !isReadOnly
            "
            buttonType="admin-secondary"
            :startIcon="LockOpenIcon"
            @click="router.push({ name: 'Character Access Controls' })"
          />
          <CustomButton
            v-if="
              route.params.characterId !== 'new' &&
              route.name !== 'Character Access Controls' &&
              !isReadOnly
            "
            buttonType="admin-secondary"
            :startIcon="TrashIcon"
            @click="deleteCharacterModalStatus = true"
          />
        </div>
      </div>
      <CustomTabs v-if="route.name !== 'Character Access Controls'" :tabOptions />
    </div>

    <div v-if="characterLoading" class="flex h-full w-full items-center justify-center">
      <AppLoadingSpinner class="py-20" :loading="characterLoading" />
    </div>
    <RouterView v-else class="px-5" />
  </div>

  <!-- Delete modal -->
  <CustomDeleteModal
    title="Delete Character"
    message="Are you sure you want to delete this character? This cannot be undone."
    :modalStatus="deleteCharacterModalStatus"
    :loading="deleteCharacterLoading"
    @confirm="confirmDeleteCharacter"
    @cancel="deleteCharacterModalStatus = false"
  />
  <!-- Preview Rubric Selection Modal -->
  <CustomModal v-model="rubricSelectModalOpen" @onClose="rubricSelectModalOpen = false">
    <div class="flex w-full flex-col gap-5">
      <h3 class="basis-auto text-xl">Select a rubric to test with</h3>
      <div class="min-w-full">
        <CustomInput
          v-model="previewRubricId"
          inputType="select-search"
          :options="
            rubricSearchResults.map((res) => ({ name: res.internal_label, value: res.rubric_id }))
          "
          label="Select Rubric"
          :loading="isRubricSearchLoading"
          @search="onPreviewRubricSearch"
        />
      </div>
      <div class="flex justify-between gap-5">
        <CustomButton
          buttonType="admin-secondary"
          class="basis-auto self-end"
          @click="rubricSelectModalOpen = false"
        >
          Cancel
        </CustomButton>

        <CustomButton
          :startIcon="PlayIcon"
          buttonType="admin-primary"
          class="basis-auto self-end"
          :loading="previewLoading"
          @click="previewCharacter"
        >
          Preview Character
        </CustomButton>
      </div>
    </div>
  </CustomModal>
</template>
