export const PRIVACY_POLICY = 'https://www.simconverse.com/documents/privacy'

export const CHANGELOG =
  'https://simconverse.notion.site/Changelog-6f77b987e3e048a5a56074d5563c06fd'

export const SUPPORT_SUPERADMIN = 'https://app.frontapp.com/knowledge-base/443905/list?path=root'

export const SUPPORT_INSTITUTIONADMIN =
  'https://simconverse.frontkb.com/en/categories/736449-for-institution-administrators'

export const SUPPORT_AUTHORING = 'https://simconverse.frontkb.com/en/categories/736513-for-authors'

export const SUPPORT_EDUCATOR = 'https://simconverse.frontkb.com/en/categories/736385-for-educators'

export const SUPPORT_LEARNER =
  'https://simconverse.notion.site/Learner-Documentation-331de8a87bb8441e85a9094b399ed622'

export const WALKTHROUGH_VIDEO_EMBED =
  'https://www.loom.com/embed/fa7dc6acf495416bb01757d2ba49bf6e?sid=db9a7e6a-57af-49f0-b4f5-f64591a80abc'

export const DOWNLOAD_CHROME =
  'https://www.google.com/intl/en_au/chrome/dr/download/?brand=YTUH&gclid=CjwKCAjwyY6pBhA9EiwAMzmfwUW2SmAywCCkk-L92SPTkre0IUDW76Oyklw02VFM3CQ_FPEFkSH_TxoCnwkQAvD_BwE&gclsrc=aw.ds'
export const DOWNLOAD_FIREFOX = 'https://www.mozilla.org/en-US/firefox/new/'
export const DOWNLOAD_SAFARI = 'https://support.apple.com/en_AU/downloads/safari'
export const DOWNLOAD_EDGE = 'https://www.microsoft.com/en-us/edge/download?form=MA13FJ'

export const COUNTRY_ISO_WIKI = 'https://en.wikipedia.org/wiki/ISO_3166-1'
export const POSTHOG_DASHBOARD = 'https://eu.posthog.com/project/12445/toolbar'
