<script setup lang="ts">
import { ref, onBeforeMount, watch } from 'vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import { type SelectOption } from '@/components/utils/CustomInput.vue'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import { useUtilsStore } from '@/stores/utils'
import Api from '@/open-api'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import { useAssignmentStore } from '@/stores/assignment'
import type { MyAssignment } from '@/open-api/generated'
import { useRouter } from 'vue-router'
import AssignmentCard from '@/components/AssignmentCard.vue'
import WalkthroughModal from '@/components/WalkthroughModal.vue'
import { Breadcrumb } from '@/components/modern/page-navigation'

definePage({
  name: 'MyAssignments',
  meta: {
    permissionLevel: 'Student'
  }
})

// ==================================================
// Init
// ==================================================
const notificationStore = useNotificationStore()
const utilsStore = useUtilsStore()
const assignmentStore = useAssignmentStore()
const router = useRouter()

const myCohortsLoading = ref(false)
const myAssignmentsLoading = ref(false)
const myAssignments = ref<MyAssignment[]>([])
const myAssignmentsContainer = ref<HTMLDivElement | undefined>(undefined)

// ==================================================
// My Assignments
// ==================================================
const myCohortOptions = ref<SelectOption[]>([])
const selectedCohorts = ref<string[]>([])

onBeforeMount(async () => {
  await loadMyCohorts()
})

async function loadMyCohorts() {
  myCohortsLoading.value = true

  try {
    const res = await Api.MyAssignments.getMyCohortsEndpoint()

    // Map to cohort options and include all options
    const cohortOptions = []
    const allCohortsValue: string[] = []

    res.cohorts.forEach((cohort) => {
      cohortOptions.push({
        name: cohort.name,
        value: [cohort.cohort_id]
      })
      allCohortsValue.push(cohort.cohort_id)
    })
    cohortOptions.unshift({
      name: 'All cohorts',
      value: allCohortsValue
    })

    myCohortOptions.value = cohortOptions
    selectedCohorts.value = allCohortsValue
  } catch (err: any) {
    notificationStore.addNotification({
      subtitle: err?.body?.message,
      status: NotificationStatus.DANGER
    })
  } finally {
    myCohortsLoading.value = false
  }
}

const loadMyAssignments = async () => {
  if (selectedCohorts.value?.length) {
    myAssignmentsLoading.value = true
    // does not provide a search query string
    Api.MyAssignments.listMyAssignmentsEndpoint({
      cohort_ids: selectedCohorts.value
    })
      .then((res) => {
        myAssignments.value = res.assignments as MyAssignment[]
      })
      .catch((err: any) => {
        notificationStore.addNotification({
          subtitle: err?.body?.message,
          status: NotificationStatus.DANGER
        })
      })
      .finally(() => {
        myAssignmentsLoading.value = false
      })
  }
}

watch(selectedCohorts, () => {
  loadMyAssignments()
})

/* --------------------- Sticky View Assignment Panel (End) --------------------- */

const setViewDetails = (assignment: MyAssignment) => {
  assignmentStore.setCurrentAssignment(assignment)

  router.push({
    name: 'MyAssignments Details',
    params: { assignmentId: assignment.assignment_id }
  })
}

// ==================================================
// Walkthrough modal
// ==================================================

const walkthroughModalVisible = ref<boolean>(false)

const closeWalkthrough = () => {
  utilsStore.setHasViewedWalkthrough(true)
  walkthroughModalVisible.value = false
}

onBeforeMount(() => {
  if (!utilsStore.hasViewedWalkthrough) {
    walkthroughModalVisible.value = true
  }
})
</script>

<template>
  <Breadcrumb :route="{ name: 'MyAssignments' }" name="My Assignments" />
  <div class="mb-8 hidden flex-none items-center justify-end bg-white pb-2 md:flex">
    <div class="flex items-center gap-5">
      <CustomButton buttonType="admin-secondary" @click="walkthroughModalVisible = true">
        Watch tutorial
      </CustomButton>
    </div>
  </div>
  <!---------------------- <Mobile> Header ---------------------->
  <div class="mb-5 flex flex-none items-center justify-between px-1 md:hidden">
    <h1 class="text-lg">My Assignments</h1>
    <div class="flex items-center gap-5">
      <CustomButton
        buttonSize="md"
        buttonType="admin-secondary"
        @click="walkthroughModalVisible = true"
      >
        Watch tutorial
      </CustomButton>
    </div>
  </div>

  <div v-if="myAssignmentsLoading || myCohortsLoading" class="w-full flex-1 py-20">
    <AppLoadingSpinner class="w-full" loading />
  </div>
  <div v-else class="flex flex-1 flex-col">
    <div
      v-if="!myAssignments?.length"
      class="flex flex-1 items-center justify-center text-sc-grey-600"
    >
      You have no assignments to display
    </div>
    <div v-else ref="myAssignmentsContainer" class="flex flex-1 overflow-hidden">
      <div id="assignment-list" class="flex w-full flex-1 grow flex-col gap-3">
        <div
          v-for="(assignment, assignmentIndex) in myAssignments"
          :key="`assignment_${assignmentIndex}`"
        >
          <AssignmentCard :assignment @view="setViewDetails" />
        </div>
      </div>
    </div>
  </div>

  <WalkthroughModal :visible="walkthroughModalVisible" :onClose="closeWalkthrough" />
</template>
