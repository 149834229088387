<script setup lang="ts">
import {
  GradingStateApiEnum,
  type GetGradedRubricOutput,
  type GetConversationOutput
} from '@/open-api/generated/'
import { useSimulationStore } from '@/stores/simulation'
import CustomInput from '@/components/utils/CustomInput.vue'
import ProgressBar from '@/components/utils/ProgressBar.vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import Api from '@/open-api'
import { useNotificationStore } from '@/stores/notifications'
import {
  ArrowDownTrayIcon,
  BookOpenIcon,
  CheckCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/vue/24/outline'
import { computed, onMounted, ref, reactive } from 'vue'
import { NotificationStatus } from '@/types/notification'
import { useUtilsStore } from '@/stores/utils'
import CustomTabs from '@/components/utils/CustomTabs.vue'
import { useAssignmentStore } from '@/stores/assignment'
import GradedRubric, {
  type DataScienceModalData,
  type GradedRubricApiEdited,
  type GradedRubricProps
} from '@/components/GradedRubricWithDataScience.vue'
import CustomModal from '@/components/utils/CustomModal.vue'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import { useAuthStore } from '@/stores/auth'
import { useRoute, useRouter } from 'vue-router'
import type { VrConversationOutput } from '@/vr/types/vr'
import { useCurrentConversation } from '@/composables/useCurrentConversation'
import { generateRubricPdf } from '@/utils/pdf'
import useHandledTimeout from '@/composables/useHandledTimeout'
import { GET_GRADED_RUBRIC_POLLING_PERIOD } from '@/constants/server'

definePage({
  name: 'Cohort Conversation Rubric',
  meta: {
    permissionLevel: 'Educator'
  }
})

const router = useRouter()
const route = useRoute('Cohort Conversation Rubric')
const simulationStore = useSimulationStore()
const notificationStore = useNotificationStore()
const utilsStore = useUtilsStore()
const assignmentStore = useAssignmentStore()
const authStore = useAuthStore()

const isHistory = computed(() => {
  return (route.name as string).includes('History')
})

const isMyAssignments = computed(() => {
  return (route.name as string).includes('MyAssignments')
})

const isCohort = computed(() => {
  return (route.name as string).includes('Cohort') || (route.query.type as string) === 'Cohort'
})

const {
  selectionId: selectedRubric,
  currentConversation,
  currentConversationIndex
} = useCurrentConversation()

type GetGradedRubricOutputWithEdited = Omit<GetGradedRubricOutput, 'rubric'> & {
  rubric?: GradedRubricApiEdited | null
}

const gradedRubrics = ref<GetGradedRubricOutputWithEdited[]>([])
const { set: setGradedRubricTimeout } = useHandledTimeout()
const rubricInitLoading = ref(false)

const getGradedRubric = () => {
  let conversations: Partial<GetConversationOutput>[] = []

  if (route.params.conversationId !== 'vr-scene' && simulationStore?.conversation) {
    conversations.push(simulationStore.conversation)
  } else if (simulationStore.vrConversation?.length) {
    conversations = simulationStore.vrConversation
  }

  let axiosRequests = conversations?.map((c) => {
    return Api.Conversation.getGradedRubricEndpoint(
      (c as VrConversationOutput)?.conversation_id || (route.params.conversationId as string)
    )
  })

  Promise.all(axiosRequests)
    .then(async (res) => {
      gradedRubrics.value = res
      if (res.find((r) => r?.rubric?.grading_state !== GradingStateApiEnum.GRADED)) {
        setGradedRubricTimeout(getGradedRubric, GET_GRADED_RUBRIC_POLLING_PERIOD)
      } else if (authStore.isSuperAdminUser) {
        await getRubricItemCorrections()
      }
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      rubricInitLoading.value = false
    })
}

onMounted(() => {
  rubricInitLoading.value = true
  getGradedRubric()
})

const generatePdf = async () => {
  const { rubric } = gradedRubrics.value[currentConversationIndex.value]
  if (rubric) {
    try {
      await generateRubricPdf(rubric)
    } catch (error) {
      notificationStore.addDANGER('Failed to generate the requested PDF.')
    }
  }
}

const backToText = computed(() => {
  if (isHistory.value) {
    return 'Back to History'
  } else if (isMyAssignments.value) {
    return 'Back to My Assignments'
  }
  return 'Back to Character'
})

const backTo = () => {
  if (isHistory.value) {
    router.push({ name: 'History' })
  } else if (isMyAssignments.value) {
    if (utilsStore.myAssignmentId) {
      router.push({
        name: 'MyAssignments Details',
        params: { assignmentId: utilsStore.myAssignmentId }
      })
    } else {
      router.push({ name: 'MyAssignments' })
    }
  } else {
    router.push({
      name: 'Characters List'
    })
  }
}

// ==================================================
// Data Science
// ==================================================

const dataScience = reactive({
  modalStatus: false,
  correctedResponse: '',
  correctedSatisfactory: undefined as undefined | boolean,
  loading: false,
  selectedItem: undefined as undefined | DataScienceModalData
})

const getRubricItemCorrections = () => {
  if (!gradedRubrics.value[currentConversationIndex.value]?.rubric) {
    return
  }
  dataScience.loading = true
  Api.DataScienceApi.getRubricItemCorrectionsEndpoint(
    gradedRubrics.value?.[currentConversationIndex.value]?.rubric?.graded_rubric_id || ''
  )
    .then((res) => {
      res.corrections.forEach((correction) => {
        if (
          gradedRubrics.value[currentConversationIndex.value].rubric?.sections[
            correction.section_position
          ].items[correction.item_position]
        ) {
          const { item_position, section_position } = correction

          gradedRubrics.value[currentConversationIndex.value].rubric!.sections[
            section_position
          ].items[item_position].editedFeedback = correction.corrected_feedback
          gradedRubrics.value[currentConversationIndex.value].rubric!.sections[
            section_position
          ].items[item_position].editedSatisfactory = correction.corrected_satisfactory
        }
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      dataScience.loading = false
    })
}

const openDataModal = (modalData: DataScienceModalData) => {
  dataScience.modalStatus = true
  dataScience.selectedItem = modalData
  dataScience.correctedResponse = modalData.item?.editedFeedback || ''
  dataScience.correctedSatisfactory =
    typeof modalData.item?.editedSatisfactory === 'boolean'
      ? modalData.item?.editedSatisfactory
      : undefined
}

const approveFeedback = (modalData: DataScienceModalData) => {
  dataScience.selectedItem = modalData
  submitNewResponse(true)
}

const submitNewResponse = (approved = false) => {
  if (!dataScience.selectedItem) {
    return
  }
  dataScience.loading = true
  Api.DataScienceApi.correctGradedRubricItemEndpoint({
    graded_rubric_id: dataScience.selectedItem.graded_rubric_id,
    corrected_feedback: approved
      ? (dataScience.selectedItem.item.feedback as string)
      : dataScience.correctedResponse,
    item_position: dataScience.selectedItem.item_position,
    section_position: dataScience.selectedItem.section_position,
    corrected_satisfactory: approved
      ? (dataScience.selectedItem.item.satisfactory as boolean)
      : (dataScience.correctedSatisfactory as boolean)
  })
    .then(() => {
      notificationStore.addNotification({
        subtitle: approved ? 'Response approved successfully' : 'Response updated successfully',
        status: NotificationStatus.SUCCESS
      })

      if (dataScience.selectedItem) {
        const { item_position, section_position } = dataScience.selectedItem
        if (gradedRubrics.value?.[currentConversationIndex.value]?.rubric) {
          gradedRubrics.value[currentConversationIndex.value].rubric!.sections[
            section_position
          ].items[item_position].editedFeedback = approved
            ? (dataScience.selectedItem.item.feedback as string)
            : dataScience.correctedResponse

          gradedRubrics.value[currentConversationIndex.value].rubric!.sections[
            section_position
          ].items[item_position].editedSatisfactory = approved
            ? (dataScience.selectedItem.item.satisfactory as boolean)
            : dataScience.correctedSatisfactory
        }
      }

      dataScience.correctedResponse = ''
      dataScience.selectedItem = undefined
      dataScience.modalStatus = false
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      dataScience.loading = false
    })
}
</script>

<template>
  <div :class="['flex flex-col gap-y-5 md:h-full']">
    <div
      v-if="isHistory && route.query.taskId && assignmentStore?.currentAssignment?.assignment_id"
      class="flex cursor-pointer items-center gap-2 px-3 text-sc-grey-700 md:px-5"
      @click="
        router.push({
          name: 'MyAssignments Task History',
          params: {
            assignmentId: assignmentStore?.currentAssignment?.assignment_id,
            taskId: route.query.taskId as string
          }
        })
      "
    >
      <ChevronLeftIcon class="h-4 w-4" />
      <h4 class="font-normal text-sc-grey-700">Back to My Assignments History</h4>
    </div>
    <div class="sticky -top-3 z-40 flex w-full flex-col gap-y-5 bg-white pt-3">
      <div :class="['justify-between px-3 md:flex md:px-5', { 'hidden md:mb-5': !isHistory }]">
        <h1 class="hidden whitespace-nowrap text-2xl font-medium md:flex">
          {{ `${currentConversation?.given_name} ${currentConversation?.family_name}` }}
        </h1>
        <div class="hidden items-center gap-3 md:flex">
          <CustomInput
            v-if="gradedRubrics.length > 1"
            v-model="selectedRubric"
            class="!my-0"
            :options="
              gradedRubrics.map((r, index) => {
                return {
                  name: `${index + 1} ${r.rubric?.label || ''}`,
                  value: r.rubric?.conversation_id || ''
                }
              })
            "
            placeholder="Select one..."
            inputType="select"
            :required="false"
            label="Rubric"
          />
          <CustomButton
            :startIcon="ArrowDownTrayIcon"
            buttonType="admin-secondary"
            :disabled="
              gradedRubrics[currentConversationIndex]?.rubric?.grading_state !==
              GradingStateApiEnum.GRADED
            "
            @click="generatePdf"
          >
            Download PDF
          </CustomButton>
          <CustomButton
            v-if="!isHistory && !isCohort"
            :endIcon="ChevronRightIcon"
            buttonType="admin-primary"
            @click="backTo"
          >
            {{ backToText }}
          </CustomButton>
        </div>
      </div>

      <CustomTabs
        v-if="isHistory"
        :tabOptions="[
          { displayName: 'Transcript', routeName: 'History Transcript' },
          { displayName: 'Rubric', routeName: 'History Rubric' }
        ]"
      />
    </div>

    <div :class="['my-3 flex h-full flex-col gap-5 px-3 md:my-5 md:flex-row md:px-5']">
      <div v-if="!isHistory" class="flex basis-auto items-center justify-between gap-3 md:hidden">
        <p class="tex-sm whitespace-nowrap font-medium">Step 3: Rubric</p>
        <div class="w-full max-w-[162px]">
          <ProgressBar :step="3" class="h-[9px]" :steps="3" />
        </div>
      </div>

      <div :class="['flex w-full grow flex-col md:grow-0']">
        <div id="pdf-element" class="flex h-full flex-col items-center gap-5">
          <h2 class="hidden self-start text-lg md:flex">Rubric</h2>

          <GradedRubric
            :rubric="(gradedRubrics[currentConversationIndex]?.rubric as GradedRubricProps) || null"
            @editFeedback="(data: DataScienceModalData) => openDataModal(data)"
            @approveFeedback="(data: DataScienceModalData) => approveFeedback(data)"
          />

          <CustomButton
            v-if="!isCohort"
            :startIcon="isHistory ? BookOpenIcon : CheckCircleIcon"
            buttonSize="lg"
            class="w-full md:hidden"
            @click="backTo"
          />

          <CustomButton
            class="w-full md:hidden"
            buttonSize="lg"
            buttonType="admin-secondary"
            :startIcon="ArrowDownTrayIcon"
            :disabled="
              gradedRubrics[currentConversationIndex]?.rubric?.grading_state !==
              GradingStateApiEnum.GRADED
            "
            @click="generatePdf"
          >
            Download PDF
          </CustomButton>
        </div>
      </div>
    </div>
    <!-- data science modal -->
    <CustomModal
      v-model="dataScience.modalStatus"
      @onClose="() => (dataScience.modalStatus = false)"
    >
      <div class="flex w-full flex-col gap-5">
        <h3 class="basis-auto text-xl">Correct character response</h3>
        <div>
          <CustomInput
            :modelValue="dataScience?.selectedItem?.item.feedback || ''"
            label="Original Response"
            inputType="textarea"
            readOnly
          />
          <CustomInput
            :modelValue="dataScience?.selectedItem?.item.satisfactory || false"
            label="Original Satisfactory"
            inputType="select"
            :options="[
              { name: 'Satisfactory', value: true },
              { name: 'Not Satisfactory', value: false }
            ]"
            readOnly
          />
          <template v-if="!dataScience.loading">
            <CustomInput
              v-model="dataScience.correctedResponse"
              inputType="textarea"
              :loading="dataScience.loading"
              label="Corrected Response"
            />
            <CustomInput
              v-model="dataScience.correctedSatisfactory"
              label="Corrected Satisfactory"
              inputType="select"
              :options="[
                { name: 'Select an Option', value: undefined },
                { name: 'Satisfactory', value: true },
                { name: 'Not Satisfactory', value: false }
              ]"
            />
          </template>
          <AppLoadingSpinner v-else loading />
        </div>
        <div class="flex gap-5">
          <CustomButton
            buttonType="admin-secondary"
            class="basis-auto self-end"
            @click="() => (dataScience.modalStatus = false)"
          >
            Cancel
          </CustomButton>
          <CustomButton
            class="basis-auto self-end"
            :disabled="
              !dataScience.correctedResponse || dataScience.correctedSatisfactory === undefined
            "
            @click="() => submitNewResponse()"
          >
            Submit new feedback
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  </div>
</template>
