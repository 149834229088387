import type { CreateOrganizationCountryInput } from '../models/CreateOrganizationCountryInput';
import type { CreateOrganizationInput } from '../models/CreateOrganizationInput';
import type { CreateOrganizationOutput } from '../models/CreateOrganizationOutput';
import type { CreatePasswordlessUserInput } from '../models/CreatePasswordlessUserInput';
import type { CreatePasswordlessUserOutput } from '../models/CreatePasswordlessUserOutput';
import type { DeleteOrganizationCountryInput } from '../models/DeleteOrganizationCountryInput';
import type { DeleteUserInput } from '../models/DeleteUserInput';
import type { GetOrganizationCountriesOutput } from '../models/GetOrganizationCountriesOutput';
import type { GetOrganizationUsersOutput } from '../models/GetOrganizationUsersOutput';
import type { i32 } from '../models/i32';
import type { ListAllOrganizationsOutput } from '../models/ListAllOrganizationsOutput';
import type { PrivateOrganizationData } from '../models/PrivateOrganizationData';
import type { PublicOrganizationData } from '../models/PublicOrganizationData';
import type { SearchOrganizationUsersOutput } from '../models/SearchOrganizationUsersOutput';
import type { UpdateOrganizationInput } from '../models/UpdateOrganizationInput';
import type { UpdateOrganizationOutput } from '../models/UpdateOrganizationOutput';
import type { UpdateUserInput } from '../models/UpdateUserInput';
import type { UpdateUserOutput } from '../models/UpdateUserOutput';
import type { UserRoleApi } from '../models/UserRoleApi';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';



export class ManagementService {

	/**
	 * CreateOrganization
	 * Creates a new organization in the database and in Auth0.
	 * @param requestBody 
	 * @returns CreateOrganizationOutput Organization created successfully.
	 * @throws ApiError
	 */
	public static createOrganizationEndpoint(
requestBody: CreateOrganizationInput,
): CancelablePromise<CreateOrganizationOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/management/CreateOrganization',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to create organizations.`,
			},
		});
	}

	/**
	 * CreateOrganizationCountry
	 * Adds a country to an organization.
	 * @param requestBody 
	 * @returns void Country added successfully to organization.
	 * @throws ApiError
	 */
	public static createOrganizationCountryEndpoint(
requestBody: CreateOrganizationCountryInput,
): CancelablePromise<void> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/management/CreateOrganizationCountry',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to add organization countries.`,
			},
		});
	}

	/**
	 * CreatePasswordlessUser
	 * Creates a new user.
	 * @param requestBody 
	 * @returns CreatePasswordlessUserOutput User created successfully.
	 * @throws ApiError
	 */
	public static createPasswordlessUserEndpoint(
requestBody: CreatePasswordlessUserInput,
): CancelablePromise<CreatePasswordlessUserOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/management/CreatePasswordlessUser',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to create users.`,
			},
		});
	}

	/**
	 * DeleteOrganizationCountry
	 * Removes a country from an organization.
	 * @param requestBody 
	 * @returns void Country removed successfully from organization.
	 * @throws ApiError
	 */
	public static deleteOrganizationCountryEndpoint(
requestBody: DeleteOrganizationCountryInput,
): CancelablePromise<void> {
				return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v2/management/DeleteOrganizationCountry',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to delete organization countries.`,
			},
		});
	}

	/**
	 * DeleteUser
	 * Deletes a user in Auth0 and marks them as deleted in the database.
	 * @param requestBody 
	 * @returns void User deleted successfully.
	 * @throws ApiError
	 */
	public static deleteUserEndpoint(
requestBody: DeleteUserInput,
): CancelablePromise<void> {
				return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v2/management/DeleteUser',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to delete users.`,
			},
		});
	}

	/**
	 * GetOrganizationCountries
	 * Get the organization's countries.
	 * @param organizationId The ID of the organization to retrieve countries for.
	 * @returns GetOrganizationCountriesOutput Countries retrieved successfully.
	 * @throws ApiError
	 */
	public static getOrganizationCountriesEndpoint(
organizationId: string,
): CancelablePromise<GetOrganizationCountriesOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/management/GetOrganizationCountries',
			query: {
				organization_id: organizationId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetOrganizationPrivate
	 * Retrieve an organization by ID.
	 * @param organizationId The ID of the organization to retrieve.
	 * @returns PrivateOrganizationData Organization retrieved successfully.
	 * @throws ApiError
	 */
	public static getOrganizationPrivateEndpoint(
organizationId: string,
): CancelablePromise<PrivateOrganizationData> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/management/GetOrganizationPrivate',
			query: {
				organization_id: organizationId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetOrganizationPublic
	 * Retrieve public information about an organization from its ID.
	 * @param organizationId The ID of the organization to retrieve.
	 * @returns PublicOrganizationData Organization retrieved successfully.
	 * @throws ApiError
	 */
	public static getOrganizationPublicEndpoint(
organizationId: string,
): CancelablePromise<PublicOrganizationData> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/management/GetOrganizationPublic',
			query: {
				organization_id: organizationId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetOrganizationUsers
	 * Get the users belonging to an organization.
	 * @param organizationId The ID of the organization to retrieve.
	 * @param itemsPerPage Number of items per page.
	 * @param page Page number. Starts from 1.
	 * @param userRole Filter by user role (if provided).
	 * @param email Filter by email address (if provided).
	 * @returns GetOrganizationUsersOutput Organization retrieved successfully.
	 * @throws ApiError
	 */
	public static getOrganizationUsersEndpoint(
organizationId: string,
itemsPerPage: i32,
page: i32,
userRole?: null | UserRoleApi,
email?: string | null,
): CancelablePromise<GetOrganizationUsersOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/management/GetOrganizationUsers',
			query: {
				organization_id: organizationId, user_role: userRole, email, items_per_page: itemsPerPage, page
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * ListAllOrganizations
	 * Retrieve all organizations. This doesn't use pagination because we use it
 * to provide the login box, where the user types the organization name and
 * gets back a list of organizations. We don't expose pagination to the user,
 * and so we don't expose it in the API for this purpose.
	 * @param searchName Search query for the organization name.
	 * @returns ListAllOrganizationsOutput Organizations retrieved successfully.
	 * @throws ApiError
	 */
	public static listAllOrganizationsEndpoint(
searchName: string,
): CancelablePromise<ListAllOrganizationsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/management/ListAllOrganizations',
			query: {
				search_name: searchName
			},
			errors: {
				400: `Bad input.`,
			},
		});
	}

	/**
	 * SearchOrganizationUsers
	 * @param organizationId The ID of the organization to search users in.
	 * @param query Query string to search for users. Searches for email addresses.
	 * @returns SearchOrganizationUsersOutput Success.
	 * @throws ApiError
	 */
	public static searchOrganizationUsersEndpoint(
organizationId: string,
query: string,
): CancelablePromise<SearchOrganizationUsersOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/management/SearchOrganizationUsers',
			query: {
				organization_id: organizationId, query
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * UpdateOrganization
	 * Update an existing organization in the database and in Auth0.
	 * @param requestBody 
	 * @returns UpdateOrganizationOutput Organization updated successfully.
	 * @throws ApiError
	 */
	public static updateOrganizationEndpoint(
requestBody: UpdateOrganizationInput,
): CancelablePromise<UpdateOrganizationOutput> {
				return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v2/management/UpdateOrganization',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to update organizations.`,
			},
		});
	}

	/**
	 * UpdateUser
	 * Update an existing user in the database and in Auth0.
	 * @param requestBody 
	 * @returns UpdateUserOutput User updated successfully.
	 * @throws ApiError
	 */
	public static updateUserEndpoint(
requestBody: UpdateUserInput,
): CancelablePromise<UpdateUserOutput> {
				return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v2/management/UpdateUser',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to update users.`,
			},
		});
	}

}