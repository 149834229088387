<script setup lang="ts">
import { useSimulationStore } from '@/stores/simulation'
import CustomButton from '@/components/utils/CustomButton.vue'
import { ref, onBeforeMount, onUnmounted, onMounted, watch } from 'vue'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import VoiceVisualiser from '@/components/VoiceVisualiser.vue'
import ProgressBar from '@/components/utils/ProgressBar.vue'
import CustomDeleteModal from '@/components/utils/CustomDeleteModal.vue'
import { useNotificationStore } from '@/stores/notifications'
import {
  ChevronRightIcon,
  MicrophoneIcon,
  SpeakerWaveIcon,
  ChevronDownIcon,
  ChevronUpIcon
} from '@heroicons/vue/24/outline'
import { useUtilsStore } from '@/stores/utils'
import { toBase64 } from '@/utils'
import Api from '@/open-api'
import { NotificationStatus } from '@/types/notification'
import type { CharacterActionSummary, GetConversationOutput } from '@/open-api/generated'
import { useAVLine } from 'vue-audio-visual'
import dayjs from 'dayjs'
import { useRoute, useRouter } from 'vue-router'
import {
  TRANSCRIPTION_AUDIO_BITRATE,
  MAX_SPEAKING_TIME,
  MINIMUM_RESPONSE_SIZE
} from '@/constants/server'
import MicrophoneSetupGuide from '@/components/MicrophoneSetupGuide.vue'
import useHandledTimeout from '@/composables/useHandledTimeout'
import useHandledInterval from '@/composables/useHandledInterval'
import { InvestigationModal } from '@/components/conversation'

definePage({
  name: 'MyAssignments Conversation',
  meta: {
    permissionLevel: 'Student'
  }
})

// ==================================================
// Init
// ==================================================
const simulationStore = useSimulationStore()
const utilsStore = useUtilsStore()
const notificationStore = useNotificationStore()
const router = useRouter()
const route = useRoute('MyAssignments Conversation')

// ==================================================
// Recording
// ==================================================
let mediaRecorder = ref<MediaRecorder | undefined>(undefined)
let chunks: Blob[] = []
const mediaRecorderInitialising = ref(true)
const isSubmittingAudio = ref(false)
// Robot Vars
const robotText = ref(simulationStore.initCharacterMessage?.message?.message_text || '')
const robotSpeaking = ref(false)
const robotResponding = ref(false)
const robotAudioUrl = ref(simulationStore.initCharacterMessage?.message?.audio_url || '')
// Student Vars
const studentText = ref('')
const maxSpeakingTime = MAX_SPEAKING_TIME
const micHolding = ref(false)
const isTranscribing = ref(false)
const { set: setSpeakingInterval, clear: clearSpeakingInterval } = useHandledInterval()
const speakingProgress = ref(0)
const spaceHeld = ref(false)

// For safari mute & unmute events removal
let removeMuteEventListeners: (() => void) | undefined = undefined

const enterUserSpeakingModeForIOS = () => {
  // @ts-expect-error - safari
  if (navigator.audioSession) {
    // @ts-expect-error - safari
    navigator.audioSession.type = 'play-and-record'
  }
}

onBeforeMount(() => {
  // Convo has been ended = kick out
  if (simulationStore.conversation?.ended_at) {
    router.push({ name: 'MyAssignments Conversation Transcript' })
  }
  window.addEventListener('keydown', onSpaceDown)
  window.addEventListener('keyup', onSpaceUp)
  window.addEventListener('mouseup', windowMouseUp)
  document.body.addEventListener('mouseleave', windowMouseLeave)
})

onUnmounted(() => {
  window.removeEventListener('keydown', onSpaceDown)
  window.removeEventListener('keyup', onSpaceUp)
  window.removeEventListener('mouseup', windowMouseUp)
  document.body.removeEventListener('mouseleave', windowMouseLeave)
  // Stop recording session and the horrible red dot that follows you around making you feeling like SC is always listening
  if (mediaRecorder.value) {
    mediaRecorder.value?.stream?.getTracks().forEach((track: any) => track.stop())
  }
  // Clear robot text
  simulationStore.setInitCharacterMessage(null)

  // Clear safari mute/unmute event listener
  if (removeMuteEventListeners) {
    removeMuteEventListeners()
  }
})

//used to fix the on ended event not firing on safari:
// https://stackoverflow.com/questions/7085941/safari-for-mac-desktop-version-html-5-audio-ended-event-doesnt-fire-problem
const safariHackUrl =
  'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV'
const modelVoice = ref(new Audio(safariHackUrl))

// Play mp3 audio hosted on S3
const playModelAudio = (url: string) => {
  if (robotSpeaking.value) {
    return
  }

  if (url) {
    modelVoice.value.src = url
    modelVoice.value.id = url
    robotSpeaking.value = true
    modelVoice.value.onended = () => {
      robotSpeaking.value = false

      robotResponding.value = false
    }
  }

  modelVoice.value.play()?.catch(() => {
    // silently disregard .pause() errors
  })
}

// Transcribe audio
async function submitAudio(audioFile: File) {
  isSubmittingAudio.value = true
  const result = await toBase64(audioFile)
  const conversation_id = route.params.conversationId as string
  if (result && conversation_id && conversation_id.indexOf('Conversation:') > -1) {
    robotResponding.value = true
    isTranscribing.value = true

    throw new Error('deprecated')
  } else {
    isTranscribing.value = false
    isSubmittingAudio.value = false
    robotResponding.value = false
    notificationStore.addNotification({
      subtitle:
        'Sorry, but it seems that no audio is currently being recorded. Please ensure that your microphone is both connected and enabled.',
      status: NotificationStatus.DANGER,
      timeoutVal: 5000
    })
  }
}

const micAllowed = ref(false)

// Safari mute/unmute event listeners
const onMuted = () => {
  micAllowed.value = false
}
const onUnmuted = () => {
  micAllowed.value = true
}
// Chrome remove microphone permission event listener
const onBlockMicrophone = () => {
  micAllowed.value = false
}

// User Media - Success Helper
function onSuccess(stream: MediaStream) {
  mediaRecorder.value = new MediaRecorder(stream, {
    audioBitsPerSecond: TRANSCRIPTION_AUDIO_BITRATE
  })

  // For safari only, to detect if mic is muted
  if (removeMuteEventListeners) {
    removeMuteEventListeners()
  }
  stream.getAudioTracks()[0].addEventListener('mute', onMuted)
  stream.getAudioTracks()[0].addEventListener('unmute', onUnmuted)
  stream.getAudioTracks()[0].addEventListener('ended', onBlockMicrophone)

  removeMuteEventListeners = () => {
    stream?.getAudioTracks()?.[0]?.removeEventListener('mute', onMuted)
    stream?.getAudioTracks()?.[0]?.removeEventListener('unmute', onUnmuted)
    stream?.getAudioTracks()?.[0]?.removeEventListener('ended', onBlockMicrophone)
    removeMuteEventListeners = undefined
  }

  // Stream audio blob data into the chunks array
  mediaRecorder.value.ondataavailable = function (e: BlobEvent) {
    chunks.push(e.data)
  }
  mediaRecorderInitialising.value = false
  micAllowed.value = true
}

// User Media - Error Helper
function onError(err: Error) {
  notificationStore.addNotification({
    title: 'Your microphone is not enabled',
    subtitle: err?.message,
    status: NotificationStatus.DANGER
  })
  mediaRecorderInitialising.value = false
  micAllowed.value = false
}

onMounted(() => {
  if (simulationStore.conversation?.ended_at) {
    router.push({ name: 'MyAssignments Conversation Transcript' })
  }

  enterUserSpeakingModeForIOS()

  modelVoice.value.src = safariHackUrl

  // Listen to microphone enable and retrigger permission prompt
  if (navigator?.permissions?.query) {
    navigator.permissions
      .query({ name: 'microphone' as PermissionName })
      .then((permissionStatus) => {
        permissionStatus.addEventListener('change', () => {
          if (micAllowed.value === false) {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(onSuccess, onError)
          }
        })
      })
      .catch(() => {
        // Skip if browser doesn't support navigator.permissions
      })
  }

  // Ask for user permission to access microphone
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(onSuccess, onError)
  } else {
    notificationStore.addNotification({
      subtitle: 'This browser does not support microphone access.',
      status: NotificationStatus.DANGER
    })
  }
})

const windowMouseUp = () => {
  if (micHolding.value) {
    stopSpeaking()
  }
}

const windowMouseLeave = () => {
  if (micHolding.value) {
    stopSpeaking()
  }
}

const onSpaceDown = ($event: KeyboardEvent) => {
  if ($event.code === 'Space' && !spaceHeld.value) {
    spaceHeld.value = true
    startSpeaking()
  }
}

const onSpaceUp = ($event: KeyboardEvent) => {
  if ($event.code === 'Space') {
    stopSpeaking()
  }
}

const { set: setStartSpeakingTimeout, isRunning: startSpeakingRunning } = useHandledTimeout()

const startSpeaking = () => {
  if (
    robotResponding.value ||
    !mediaRecorder.value ||
    isTranscribing.value ||
    startSpeakingRunning.value
  ) {
    return
  }

  // interrupt speaking
  if (robotAudioUrl.value && robotSpeaking.value && modelVoice.value?.src) {
    //stop speaking
    robotAudioUrl.value = ''
    robotSpeaking.value = false
    modelVoice.value?.pause()
  }

  setStartSpeakingTimeout(() => {
    // sets startSpeakingRunning to true,
    // then sets it to false 1 second later
  }, 1000)

  if (modelVoice.value.src === safariHackUrl) {
    playModelAudio('')
  }

  micHolding.value = true

  try {
    mediaRecorder.value.stop()
    mediaRecorder.value.start()
  } catch (err) {
    micAllowed.value = false
    mediaRecorderInitialising.value = false
  }

  setSpeakingInterval(() => {
    speakingProgress.value += 1000

    if (speakingProgress.value >= maxSpeakingTime) {
      clearSpeakingInterval()
      stopSpeaking()
    }
  }, 1000)
}

const stopSpeaking = () => {
  if (!mediaRecorder.value || mediaRecorder.value.state === 'inactive') {
    return
  }

  speakingProgress.value = 0
  mediaRecorder.value.stop()
  clearSpeakingInterval()
  spaceHeld.value = false
  micHolding.value = false

  // Recording stopped, create a file and submit it
  mediaRecorder.value.onstop = function () {
    const audioBlob = new Blob(chunks, { type: 'audio/wav' })
    const audioFile = new File([audioBlob], 'audio.wav', { type: 'audio/wav' })

    // Emtpy chunks array so they can submit audio again
    chunks = []
    // prevent clicking clicking once
    // if no text is detected in transcription it default to 'you' which has a size of 1429
    // the default response size is the size of a person saying 'I' which is 3400

    const defaultResponseSize = MINIMUM_RESPONSE_SIZE

    if (audioFile.size >= defaultResponseSize) {
      submitAudio(audioFile)
    } else {
      notificationStore.addNotification({
        title: 'We didn’t quite catch that',
        subtitle: 'Press and hold the button while you speak',
        status: NotificationStatus.DANGER,
        timeoutVal: 5000
      })
    }
  }
}

// ==================================================
// Conversation
// ==================================================
const endConversationLoading = ref(false)
const endModalStatus = ref(false)

const endConversation = () => {
  endConversationLoading.value = true

  /* ------------- End My Conversation Task ------------- */
  const conversation_task_attempt_id = route.params.taskAttemptId as string

  Api.MyAssignments.endMyConversationTaskEndpoint({ conversation_task_attempt_id })
    .then(() => {
      simulationStore.setConversation({
        ...simulationStore.conversation,
        ended_at: `${dayjs()}`
      } as GetConversationOutput)

      router.push({ name: 'MyAssignments Conversation Transcript' })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        title: err?.response?.data.error,
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      endConversationLoading.value = false
    })
}

// ==================================================
// Actions
// ==================================================
const actionsDropDownOpen = ref(false)
const actionLoading = ref(false)

const actionInteraction = (action: CharacterActionSummary) => {
  if (actionLoading.value) {
    return
  }

  actionLoading.value = true
  studentText.value = `Action Taken: ${action.public_label}`
  robotText.value = ''

  Api.Conversation.performActionEndpoint({
    character_action_id: action.character_action_id,
    conversation_id: route.params.conversationId as string
  })
    .then(() => {})
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      actionLoading.value = false
    })
}

// ==================================================
// Investigations
// ==================================================
const investigationsDropDownOpen = ref(false)
const investigationLoading = ref(false)

const investigationInteraction = (character_investigation_id: string) => {
  if (investigationLoading.value) {
    return
  }
  investigationLoading.value = true
  Api.Conversation.performInvestigationEndpoint({
    character_investigation_id,
    conversation_id: route.params.conversationId as string
  })
    .then(() => {})
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      investigationLoading.value = false
    })
}

// ==================================================
// Investigation Modal
// ==================================================
const selectedInvestigationIndex = ref(0)
const modalStatus = ref(false)

const openModal = (index: number) => {
  selectedInvestigationIndex.value = index
  modalStatus.value = true
}

const closeModal = () => {
  modalStatus.value = false
  setTimeout(() => {
    selectedInvestigationIndex.value = 0
  }, 300)
}

/* ------------------------- Audio File Player ------------------------- */
const audioPlayer = ref<HTMLAudioElement | undefined>(undefined)
const audioCanvas = ref<HTMLCanvasElement | undefined>(undefined)

watch(modalStatus, (val) => {
  if (val) {
    useAVLine(audioPlayer, audioCanvas, {
      lineColor: '#FC894B'
    })
  }
})
</script>

<template>
  <div :class="['flex flex-col gap-y-5 pb-5 md:h-full md:overflow-hidden']">
    <div class="mb-5 flex justify-between max-md:hidden">
      <h1 class="whitespace-nowrap text-2xl font-medium">
        {{
          `${simulationStore?.conversation?.given_name} ${simulationStore?.conversation?.family_name}`
        }}
      </h1>
      <div class="flex gap-3">
        <CustomButton
          buttonType="admin-primary"
          :endIcon="ChevronRightIcon"
          :loading="endConversationLoading"
          :disabled="!mediaRecorder"
          @click="endModalStatus = true"
        >
          End conversation & review
        </CustomButton>
      </div>
    </div>

    <div :class="['flex h-full flex-col gap-5 md:flex-row md:overflow-hidden']">
      <div class="flex basis-auto items-center justify-between gap-3 md:hidden">
        <p class="tex-sm whitespace-nowrap font-medium">Step 1: Conversation</p>
        <div class="w-full max-w-[162px]">
          <ProgressBar :step="1" class="h-[9px]" :steps="3" />
        </div>
      </div>

      <div :class="['flex grow flex-col md:w-[60%] md:grow-0 md:overflow-hidden']">
        <div class="flex h-full flex-col gap-3">
          <div class="flex flex-col gap-3 max-md:hidden">
            <h2 class="text-lg">Overview</h2>
            <p class="text-sc-grey-800">
              To speak with the character, press and hold the button below. Depending on your
              assigned task, you may be required to review interactions or perform actions
              throughout your conversation.
            </p>
          </div>

          <h2 class="flex text-lg max-md:hidden">Conversation</h2>
          <div
            v-if="mediaRecorder && micAllowed"
            class="relative flex flex-col justify-between gap-3 rounded-mlg border border-sc-grey-300 p-4 md:h-full md:overflow-hidden md:p-6 md:pb-16"
          >
            <div class="flex flex-col gap-3 md:grow md:flex-row md:overflow-hidden">
              <div class="flex flex-col items-center gap-3">
                <div
                  :class="[
                    'relative m-0.5 h-[70px] w-[70px] rounded-full md:h-[140px] md:w-[140px] md:min-w-[140px]',
                    { 'ring-2': robotSpeaking }
                  ]"
                >
                  <img
                    :src="simulationStore.conversation?.avatar_url"
                    :alt="`Character avatar for ${simulationStore.conversation?.given_name} ${simulationStore.conversation?.family_name}`"
                    class="center-cropped h-full w-full rounded-full object-cover"
                  />
                  <div
                    v-if="robotSpeaking"
                    class="absolute bottom-0 left-[70%] flex h-6 w-6 items-center justify-center gap-0.5 rounded-full bg-black"
                  >
                    <div class="voice-blob h-1.5 w-1 rounded-full bg-white" />
                    <div class="voice-blob h-3 w-1 rounded-full bg-white" />
                    <div class="voice-blob h-2 w-1 rounded-full bg-white" />
                  </div>
                </div>
                <p class="md:hidden">
                  {{
                    `${simulationStore.conversation?.given_name} ${simulationStore.conversation?.family_name}`
                  }}
                </p>
              </div>
              <div class="flex w-full flex-col items-center gap-3">
                <div
                  class="hidden-scroll-bar relative flex h-[156px] w-full flex-col gap-3 overflow-y-auto md:h-full"
                >
                  <AppLoadingSpinner
                    v-if="isTranscribing"
                    :class="['absolute inset-0 top-1/2 z-20 w-full -translate-y-1/2 md:top-[95px]']"
                    loading
                  />
                  <template v-else-if="studentText || robotText">
                    <!-- STUDENT TEXT -->
                    <div v-if="studentText" class="flex max-w-[75%] items-center gap-3 self-end">
                      <div
                        :class="[
                          'flex self-end rounded-[20px] rounded-tr-none bg-sc-orange-300 p-3'
                        ]"
                      >
                        <div>
                          {{ studentText }}
                        </div>
                      </div>
                    </div>

                    <!-- CHARACTER TEXT -->
                    <div
                      v-if="robotText && !robotResponding"
                      class="flex max-w-[75%] items-center gap-3"
                    >
                      <div class="flex rounded-[20px] rounded-tl-none bg-sc-grey-100 p-3">
                        <div>
                          {{ robotText }}
                        </div>
                      </div>
                      <div
                        v-if="robotAudioUrl && !robotSpeaking"
                        class="flex h-6 w-6 min-w-[1.5rem] cursor-pointer items-center justify-center rounded-full bg-sc-grey-100 text-sc-grey-600 hover:bg-sc-grey-200"
                        @click="playModelAudio(robotAudioUrl)"
                      >
                        <SpeakerWaveIcon class="h3 w-3" />
                      </div>
                    </div>
                    <AppLoadingSpinner
                      v-else-if="robotResponding"
                      class="h-[48px] self-start"
                      loading
                    />
                  </template>
                  <template v-else>
                    <div
                      class="mt-[60px] flex w-full items-center justify-center gap-3 text-sc-grey-600"
                    >
                      No messages to display
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <div
              class="relative flex w-full cursor-pointer select-none flex-col items-center justify-center gap-3 md:basis-auto"
            >
              <div class="flex flex-col items-center gap-3">
                <p :class="['mb-2 text-center text-sc-grey-600 md:mb-5']">
                  {{ isTranscribing ? 'Speak into the microphone' : `Press and hold to speak` }}
                  <span class="-ml-1 inline-block max-md:hidden">, or hold down the space bar</span>
                </p>
              </div>

              <div
                :class="[
                  {
                    'pointer-events-none opacity-60':
                      isSubmittingAudio || robotResponding || !mediaRecorder || isTranscribing
                  },
                  'relative flex h-[50px] w-full select-none items-center justify-center gap-2 overflow-hidden rounded-full bg-black text-white md:max-w-[350px]'
                ]"
                @mousedown="
                  () => {
                    startSpeaking()
                  }
                "
                @touchstart="
                  () => {
                    startSpeaking()
                  }
                "
                @touchend="stopSpeaking"
                @mouseup="stopSpeaking"
              >
                <div
                  :class="[
                    'pointer-events-none absolute inset-0 z-10 w-0 rounded-full bg-[var(--org-color)]',
                    { 'w-full transition-all ease-linear [transition-duration:90s]': micHolding }
                  ]"
                />
                <div class="pointer-events-none z-20 flex">
                  <div v-if="micHolding">
                    <VoiceVisualiser
                      :class="[
                        'pointer-events-none flex h-[50px] w-full items-center justify-center self-center',
                        { invisible: !micHolding }
                      ]"
                      :stream="mediaRecorder?.stream"
                    />
                  </div>
                  <div v-else class="pointer-events-none flex select-none gap-1">
                    <MicrophoneIcon class="h-6" />
                    Press and hold
                  </div>
                </div>
              </div>
              <CustomButton
                buttonType="admin-secondary"
                buttonSize="lg"
                :loading="endConversationLoading"
                class="w-full md:hidden md:max-w-[350px]"
                @click="endModalStatus = true"
              >
                End conversation
              </CustomButton>
            </div>
          </div>
          <MicrophoneSetupGuide v-else-if="!mediaRecorderInitialising || !micAllowed" />
        </div>
      </div>

      <div class="flex flex-col gap-3 md:w-[40%] md:overflow-hidden">
        <h2 class="flex text-lg max-md:hidden">Progress</h2>

        <div class="flex basis-auto items-center justify-between gap-3 max-md:hidden">
          <p class="tex-sm whitespace-nowrap font-medium">Step 1: Conversation</p>
          <div class="ml-20 w-full">
            <ProgressBar :step="1" class="h-[9px]" :steps="3" />
          </div>
        </div>

        <div :class="['flex h-full flex-col gap-3 md:overflow-hidden']">
          <div
            class="flex flex-col border-b border-sc-grey-300 pb-1.5 md:grow md:overflow-hidden md:border-b-0"
          >
            <div
              class="flex cursor-pointer items-center justify-between"
              @click="() => (investigationsDropDownOpen = !investigationsDropDownOpen)"
            >
              <h3 class="-mt-[15px] flex h-[50px] min-h-[50px] items-center text-lg md:mt-0">
                Investigations
                <span class="pl-1 text-base text-sc-grey-600"
                  >({{ simulationStore.conversation?.investigations.length || 0 }})</span
                >
              </h3>
              <component
                :is="investigationsDropDownOpen ? ChevronUpIcon : ChevronDownIcon"
                class="h-5 text-sc-grey-600 md:hidden"
              />
            </div>

            <Transition name="slide">
              <div
                v-if="investigationsDropDownOpen || !utilsStore.isMobile"
                class="mb-3 flex w-full origin-top flex-col gap-3 transition-transform md:scale-100 md:overflow-hidden"
              >
                <template v-if="simulationStore.conversation?.investigations.length">
                  <div class="flex flex-col gap-3 md:overflow-y-auto">
                    <div
                      v-for="(investigation, investigationIndex) in simulationStore.conversation
                        ?.investigations"
                      :key="investigation?.character_investigation_id"
                      :class="[
                        {
                          'pointer-events-none cursor-not-allowed opacity-60':
                            !mediaRecorder || investigationLoading || robotResponding
                        },
                        'flex h-[50px] min-h-[50px] w-full cursor-pointer items-center justify-center rounded-md border hover:bg-sc-grey-50'
                      ]"
                      @click="
                        () => {
                          openModal(investigationIndex)
                          investigationInteraction(investigation?.character_investigation_id)
                        }
                      "
                    >
                      <p class="text-lg">
                        {{ investigation?.public_label }}
                      </p>
                    </div>
                  </div>
                </template>

                <p v-else class="mt-3 w-full text-center text-sc-grey-600">
                  {{ 'This task does not have any investigations' }}
                </p>
              </div>
            </Transition>
          </div>

          <div class="flex flex-col md:grow md:overflow-hidden">
            <div
              class="flex cursor-pointer items-center justify-between"
              @click="() => (actionsDropDownOpen = !actionsDropDownOpen)"
            >
              <h3 class="flex h-[50px] min-h-[50px] items-center text-lg">
                Actions
                <span class="pl-1 text-base text-sc-grey-600"
                  >({{ simulationStore.conversation?.actions.length || 0 }})</span
                >
              </h3>
              <component
                :is="actionsDropDownOpen ? ChevronUpIcon : ChevronDownIcon"
                class="h-5 text-sc-grey-600 md:hidden"
              />
            </div>
            <Transition name="slide">
              <div
                v-if="actionsDropDownOpen || !utilsStore.isMobile"
                class="flex w-full origin-top flex-col gap-3 transition-transform md:overflow-hidden"
              >
                <template v-if="simulationStore.conversation?.actions.length">
                  <div class="flex flex-col gap-3 md:overflow-y-auto">
                    <div
                      v-for="action in simulationStore.conversation?.actions"
                      :key="action?.character_action_id"
                      :class="[
                        {
                          'pointer-events-none cursor-not-allowed opacity-60':
                            !mediaRecorder || actionLoading || robotResponding
                        },
                        'flex h-[50px] min-h-[50px] w-full cursor-pointer items-center justify-center rounded-md border hover:bg-sc-grey-50'
                      ]"
                      @click="actionInteraction(action)"
                    >
                      <p class="text-lg">
                        {{ action?.public_label }}
                      </p>
                    </div>
                  </div>
                </template>
                <p v-else class="mt-3 w-full text-center text-sc-grey-600">
                  {{ 'This task does not have any actions' }}
                </p>
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </div>
    <CustomDeleteModal
      :modalStatus="endModalStatus"
      title="End conversation"
      :message="`Are you sure you want to end this conversation and review\n your transcript and grade?`"
      deleteButtonText="Yes, end conversation"
      @cancel="endModalStatus = false"
      @confirm="
        () => {
          endModalStatus = false
          endConversation()
        }
      "
    />
    <InvestigationModal
      v-model="modalStatus"
      :selectedInvestigation="
        simulationStore?.conversation?.investigations?.[selectedInvestigationIndex]
      "
      @closeModal="closeModal"
    />
  </div>
</template>

<style scoped>
.slide-enter-from,
.slide-leave-to {
  @apply scale-0;
}

.voice-blob:nth-child(1) {
  animation: voice-move 1.4s infinite 0.2s linear;
}

.voice-blob:nth-child(2) {
  animation: voice-move 1.4s infinite linear;
}

.voice-blob {
  animation: voice-move 1.4s infinite 0.5s linear;
}

@keyframes voice-move {
  0% {
    transform: scaleY(1);
  }

  25% {
    transform: scaleY(1.3);
  }

  50% {
    transform: scaleY(1);
  }

  75% {
    transform: scaleY(0.5);
  }

  100% {
    transform: scaleY(1);
  }
}
</style>
