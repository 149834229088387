<script setup lang="ts">
import type {
  CreateSectionTemplateInput,
  ItemTemplate,
  SectionTemplateOutput
} from '@/open-api/generated'
import type {
  RubricItemPayloadQuestion,
  RubricItemPayloadAction,
  RubricItemPayloadInvestigation
} from '@/types/subtypes'
import { onBeforeMount, ref, computed, watch } from 'vue'
import { NotificationStatus } from '@/types/notification'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomDeleteModal from '@/components/utils/CustomDeleteModal.vue'
import {
  DocumentDuplicateIcon,
  LockOpenIcon,
  TrashIcon,
  ChevronLeftIcon
} from '@heroicons/vue/24/outline'
import { useContentStore } from '@/stores/content'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import { useNotificationStore } from '@/stores/notifications'
import { useAuthStore } from '@/stores/auth'
import Api from '@/open-api'
import dayjs from 'dayjs'
import { useRoute, useRouter } from 'vue-router'
import { SearchSelect, organizationConfig } from '@/components/modern/ui/search-select'
import { Button } from '@/components/modern/ui/button'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose
} from '@/components/modern/ui/dialog'
import { CopyIcon, Cross1Icon } from '@radix-icons/vue'
import useAllOrganizations from '@/composables/api/useAllOrganizations'
import useCopySectionToOrganization from '@/composables/api/mutations/useCopySectionToOrganization'
import { CommandState } from '@/types/api'
import useScrollToTop from '@/composables/useScrollToTop'
import { Breadcrumb } from '@/components/modern/page-navigation'

definePage({
  name: 'Section',
  meta: {
    permissionLevel: 'Educator',
    requiresAuthoring: true
  }
})

// ==================================================
// Init
// ==================================================
const route = useRoute<'Section' | 'Section View' | 'Section Access Controls'>()
const router = useRouter()
const contentStore = useContentStore()
const notificationStore = useNotificationStore()
const authStore = useAuthStore()
const sectionLoading = ref(false)
const saveLoading = ref(false)
const isReadOnly = computed(() => {
  return (
    route.params.sectionTemplateId !== 'new' &&
    !authStore.isAtLeastStaffUser &&
    !(
      authStore.organizationId &&
      authStore.organizationId ===
        (contentStore.editingSection as SectionTemplateOutput)?.owning_organization_id
    )
  )
})

const deleteSectionModalStatus = ref(false)
const deleteSectionLoading = ref(false)

onBeforeMount(async () => {
  setCurrentSection()
})

// ==================================================
// Section
// ==================================================
const itemsErrorArr = computed((): { index: number; error: string }[] => {
  if (!contentStore.editingSection?.items?.length) {
    return []
  }
  return contentStore.editingSection.items.reduce(
    (acc: { index: number; error: string }[], item: ItemTemplate, index: number) => {
      if (
        !item.public_label.trim() ||
        (!(item.payload as RubricItemPayloadQuestion).Question?.question.trim() &&
          !(item.payload as RubricItemPayloadAction).Action?.public_label.trim() &&
          !(item.payload as RubricItemPayloadInvestigation).Investigation?.public_label.trim())
      ) {
        acc.push({ index, error: 'Missing value' })
      }
      return acc
    },
    [] as { index: number; error: string }[]
  )
})

const sectionNotComplete = computed(() => {
  const { public_label, minimum_score, items, internal_label } = contentStore.editingSection

  return (
    !public_label?.trim() ||
    !internal_label?.trim() ||
    !minimum_score ||
    minimum_score <= 0 ||
    !items ||
    minimum_score > items.length ||
    items.length === 0 ||
    !!itemsErrorArr.value.length
  )
})

const setCurrentSection = async () => {
  if (route.params.sectionTemplateId === 'new') {
    contentStore.setEditingSection(contentStore.newSection)
  } else if (
    (contentStore.editingSection as SectionTemplateOutput)?.section_template_id !==
    route.params.sectionTemplateId
  ) {
    sectionLoading.value = true
    try {
      const section = await Api.Content.getSectionTemplateEndpoint(
        route.params.sectionTemplateId as string
      )

      if (!section) {
        router.push({ name: 'Sections List' })
      }

      contentStore.setEditingSection(section)
    } catch (err: any) {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    } finally {
      sectionLoading.value = false
    }
  }
}
watch(() => route.params.sectionTemplateId, setCurrentSection)

// ==================================================
// Create Section
// ==================================================
const createSectionTemplate = () => {
  saveLoading.value = true

  Api.Content.createSectionTemplateEndpoint(
    contentStore.editingSection as CreateSectionTemplateInput
  )
    .then((res) => {
      notificationStore.addNotification({
        subtitle: 'Section successfully created',
        status: NotificationStatus.SUCCESS
      })
      router
        .push({
          name: route.name,
          params: { sectionTemplateId: res.section_template_id }
        })
        .then(async () => {
          await setCurrentSection()
        })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      saveLoading.value = false
    })
}

// ==================================================
// Duplicate Section
// ==================================================
const duplicateSectionLoading = ref(false)

const duplicateSection = async () => {
  duplicateSectionLoading.value = true

  const editingSection = contentStore.editingSection as SectionTemplateOutput
  const duplicatedSection: CreateSectionTemplateInput = {
    items: editingSection.items,
    mandatory: editingSection.mandatory,
    minimum_score: editingSection.minimum_score,
    public_label: `Copy of ${editingSection.public_label} ${dayjs().format('DD/MM/YYYY HH:mm:ss')}`,
    internal_label: `Copy of ${editingSection.internal_label} ${dayjs().format(
      'DD/MM/YYYY HH:mm:ss'
    )}`,
    allowlist: editingSection.allowlist?.map((org) => org.organization_id)
  }

  await Api.Content.createSectionTemplateEndpoint(duplicatedSection)
    .then(() => {
      router.push({
        name: 'Sections List'
      })
      notificationStore.addNotification({
        subtitle: `${editingSection.public_label} successfully cloned`,
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      duplicateSectionLoading.value = false
    })
}

// ==================================================
// Delete Section
// ==================================================
const confirmDeleteSection = () => {
  deleteSectionLoading.value = true
  Api.Content.deleteSectionTemplateEndpoint({
    section_template_id: route.params.sectionTemplateId as string
  })
    .then(() => {
      deleteSectionModalStatus.value = false

      router.push({
        name: 'Sections List'
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      deleteSectionLoading.value = false
    })
}

const copyModalOpen = ref<boolean>(false)
const targetOrganizationId = ref<string | undefined>(undefined)
const { organizations, organizationsLoading } = useAllOrganizations({ notificationStore })
const scrollToTop = useScrollToTop()
const sectionTemplateId = computed<string>(() => route.params.sectionTemplateId)
const copySection = useCopySectionToOrganization({
  sectionTemplateId,
  targetOrganizationId,
  notificationStore
})

const copySectionToOrganization = async () => {
  const { execute, reset } = copySection
  const newSectionId = await execute().finally(reset)

  if (newSectionId) {
    copyModalOpen.value = false
    const targetOrganization = organizations.value.find(
      (org) => org.organization_id === targetOrganizationId.value
    )
    if (targetOrganization) {
      notificationStore.addWARNING(`Now viewing copy belonging to ${targetOrganization.name}.`)
    }
    router.push({
      name: 'Section View',
      params: { sectionTemplateId: newSectionId }
    })
    scrollToTop()
  }
}
</script>

<template>
  <Breadcrumb :route="{ name: 'Section View' }" :name="contentStore.editingSection?.public_label" />
  <div
    :key="(contentStore.editingSection as SectionTemplateOutput)?.section_template_id"
    :class="['flex h-full flex-col gap-y-5']"
  >
    <div class="sticky -top-3 z-50 flex flex-col gap-3 bg-white pt-3">
      <div
        v-if="route.name !== 'Section Access Controls'"
        class="flex cursor-pointer items-center gap-2 px-5 text-sc-grey-700"
        @click="router.push({ name: 'Sections List' })"
      >
        <ChevronLeftIcon class="h-4 w-4" />
        <h4 class="font-normal text-sc-grey-700">Back to all sections</h4>
      </div>
      <div class="flex justify-between px-5">
        <h1 class="mr-5 pr-10 text-2xl font-medium">
          {{
            route.query?.mode === 'create'
              ? 'Create Section'
              : contentStore.editingSection.public_label
          }}
        </h1>
        <CustomButton v-if="isReadOnly" buttonType="grey" buttonSize="sm" :canSelect="false">
          READ-ONLY
        </CustomButton>

        <div class="flex gap-3">
          <CustomButton
            v-if="route.name === 'Section Access Controls'"
            buttonType="admin-secondary"
            @click="router.push({ name: 'Section View' })"
          >
            Back to Section
          </CustomButton>
          <CustomButton
            v-if="route.params.sectionTemplateId === 'new'"
            :loading="saveLoading"
            buttonType="admin-primary"
            :disabled="sectionNotComplete"
            @click="createSectionTemplate"
          >
            Create Section
          </CustomButton>

          <template
            v-if="
              route.params.sectionTemplateId !== 'new' &&
              authStore.isAtLeastStaffUser &&
              authStore.flagAuthoringEnabled &&
              route.name !== 'Section Access Controls'
            "
          >
            <Dialog v-model:open="copyModalOpen">
              <DialogTrigger asChild>
                <CustomButton buttonType="admin-secondary" :startIcon="CopyIcon">
                  Copy to Organization
                </CustomButton>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Copy section to organization</DialogTitle>
                  <DialogDescription>
                    Select an organization to copy this section to.
                  </DialogDescription>
                </DialogHeader>
                <div class="min-w-full">
                  <SearchSelect
                    v-bind="organizationConfig"
                    v-model:value="targetOrganizationId"
                    :data="organizations"
                    :isLoading="organizationsLoading"
                  />
                </div>
                <DialogFooter>
                  <DialogClose asChild>
                    <Button variant="outline" size="xs">
                      <Cross1Icon class="mr-2 size-4" />
                      <span>Cancel</span>
                    </Button>
                  </DialogClose>
                  <Button
                    variant="default"
                    size="xs"
                    :disabled="copySection.state.value === CommandState.IN_PROGRESS"
                    @click="copySectionToOrganization"
                  >
                    <CopyIcon class="mr-2 size-4" />
                    <span>Copy</span>
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </template>
          <CustomButton
            v-if="
              route.params.sectionTemplateId !== 'new' &&
              route.name !== 'Section Access Controls' &&
              !isReadOnly
            "
            :disabled="duplicateSectionLoading"
            buttonType="admin-secondary"
            :startIcon="DocumentDuplicateIcon"
            @click="duplicateSection"
          />
          <CustomButton
            v-if="
              route.params.sectionTemplateId !== 'new' &&
              authStore.isAtLeastStaffUser &&
              route.name !== 'Section Access Controls' &&
              !isReadOnly
            "
            buttonType="admin-secondary"
            :startIcon="LockOpenIcon"
            @click="router.push({ name: 'Section Access Controls' })"
          />
          <CustomButton
            v-if="
              route.params.sectionTemplateId !== 'new' &&
              route.name !== 'Section Access Controls' &&
              !isReadOnly
            "
            buttonType="admin-secondary"
            :startIcon="TrashIcon"
            @click="deleteSectionModalStatus = true"
          />
        </div>
      </div>
    </div>

    <div v-if="sectionLoading" class="flex w-full items-center justify-center">
      <AppLoadingSpinner class="py-20" :loading="sectionLoading" />
    </div>
    <RouterView v-else class="px-5" />
  </div>

  <!-- Delete modal -->
  <CustomDeleteModal
    title="Delete Section"
    message="Are you sure you want to delete this section? This cannot be undone."
    :modalStatus="deleteSectionModalStatus"
    :loading="deleteSectionLoading"
    @confirm="confirmDeleteSection"
    @cancel="deleteSectionModalStatus = false"
  />
</template>
