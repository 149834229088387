<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import { Breadcrumb, TopLine, BackButton } from '@/components/modern/page-navigation'
import useCombineQueries from '@/composables/api/useCombineQueries'
import { injectUseGetMyCohorts } from '@/composables/api/queries/useGetMyCohorts'
import { injectUseGetOrganizationCohorts } from '@/composables/api/queries/useGetOrganizationCohorts'
import { injectUseGetCohortStudentsProgress } from '@/composables/api/queries/useGetCohortStudentsProgress'
import { injectUseGetCohortAssignmentInsights } from '@/composables/api/queries/useGetCohortAssignmentInsights'
import useGetOrSearchOrganisationAssignments from '@/composables/api/queries/useGetOrSearchOrganisationAssignments'
import useAssignToCohort from '@/composables/api/mutations/useAssignToCohort'
import { DataTable, DataTableServerPagination } from '@/components/modern/ui/data-table'
import { useColumns } from '@/components/modern/tables/cohort-assigns'
import SearchFilter from '@/components/modern/ui/SearchFilter.vue'

definePage({
  name: 'Modern Cohorts - Assign Activity',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const route = useRoute('Modern Cohorts - Assign Activity')
const cohortId = computed(() => route.params.cohortId)

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

const assignToCohort = useAssignToCohort({ notificationStore })

const { track, refetch } = useCombineQueries()
track(injectUseGetMyCohorts({ notificationStore }))
track(injectUseGetOrganizationCohorts({ notificationStore, authStore }))
track(injectUseGetCohortStudentsProgress({ cohortId, notificationStore }))
track(injectUseGetCohortAssignmentInsights({ cohortId, notificationStore }))

const { query, activities, isLoading, pagination } = useGetOrSearchOrganisationAssignments({
  authStore,
  notificationStore
})
const columns = useColumns({ cohortId, assignToCohort, refetch })
</script>

<template>
  <Breadcrumb :route name="Assign Activity" />
  <TopLine>
    <template #left>
      <BackButton
        :to="{ name: 'Modern Cohorts - Cohort Activities List', params: { cohortId } }"
        name="cohort"
      />
      <SearchFilter v-model="query" placeholder="Search by name or description" class="min-w-72" />
    </template>
  </TopLine>
  <DataTable :data="activities" :columns :isLoading />
  <DataTableServerPagination v-show="!query" v-bind="pagination" />
</template>
