<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import { Breadcrumb, TopLine, BackButton } from '@/components/modern/page-navigation'
import { MultiTable } from '@/components/modern/ui/data-table'
import { useColumns, useGroups } from '@/components/modern/tables/cohort-student-activities'
// import { Button } from '@/components/modern/ui/button'
import type { StudentAssignmentInstanceProgress } from '@/open-api/generated'
import useCombineQueries from '@/composables/api/useCombineQueries'
import { injectUseGetCohortStudents } from '@/composables/api/queries/useGetCohortStudents'
import { injectUseGetMyCohorts } from '@/composables/api/queries/useGetMyCohorts'
import { injectUseGetOrganizationCohorts } from '@/composables/api/queries/useGetOrganizationCohorts'
import { injectUseGetCohortStudentsProgress } from '@/composables/api/queries/useGetCohortStudentsProgress'
import { injectUseGetCohortAssignmentInsights } from '@/composables/api/queries/useGetCohortAssignmentInsights'
import useGetCohortStudentActivitiesProgress from '@/composables/api/queries/useGetCohortStudentActivitiesProgress'
import useResetLearnerProgress from '@/composables/api/mutations/useResetLearnerProgress'
import createIsoTimes from '@/utils/iso-times'

definePage({
  name: 'Modern Cohorts - Student Progress',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const route = useRoute('Modern Cohorts - Student Progress')
const cohortId = computed(() => route.params.cohortId)
const userId = computed(() => route.params.userId)

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

const { students } = injectUseGetCohortStudents({ cohortId, notificationStore })
const studentEmail = computed(() => students.value.find((s) => s.id === userId.value)?.email)

const { track, isLoading, refetch } = useCombineQueries()
const { activities } = track(
  useGetCohortStudentActivitiesProgress({
    cohortId,
    userId,
    notificationStore
  })
)
track(injectUseGetMyCohorts({ notificationStore }))
track(injectUseGetOrganizationCohorts({ notificationStore, authStore }))
track(injectUseGetCohortStudentsProgress({ cohortId, notificationStore }))
track(injectUseGetCohortAssignmentInsights({ cohortId, notificationStore }))

const assignmentInstanceId = ref<string>('')
const { execute, reset } = useResetLearnerProgress({
  assignmentInstanceId,
  userId,
  notificationStore
})
const requestResetLearnerProgress = (a: StudentAssignmentInstanceProgress) =>
  (assignmentInstanceId.value = a.assignment_instance_id)
const confirmResetLearnerProgress = () => {
  return execute().finally(reset).finally(refetch)
}

const isoTimes = createIsoTimes()

const columns = useColumns({
  cohortId,
  userId,
  requestResetLearnerProgress,
  confirmResetLearnerProgress,
  isoTimes
})

const groups = useGroups()
</script>

<template>
  <Breadcrumb :route :name="studentEmail" />
  <TopLine>
    <template #left>
      <BackButton
        :to="{ name: 'Modern Cohorts - Cohort Learners List', params: { cohortId } }"
        name="cohort"
      />
    </template>
    <template v-if="false" #right>
      <!-- <Button variant="default" size="xs" @click="exportCSV">
        <DownloadIcon class="mr-2 size-4" />
        <span>Export</span>
      </Button> -->
    </template>
  </TopLine>
  <MultiTable :data="activities" :columns :groups :isLoading />
</template>
