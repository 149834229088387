<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import useCombineQueries from '@/composables/api/useCombineQueries'
import { injectUseGetMyCohorts } from '@/composables/api/queries/useGetMyCohorts'
import { injectUseGetOrganizationCohorts } from '@/composables/api/queries/useGetOrganizationCohorts'
import { injectUseGetCohortStudents } from '@/composables/api/queries/useGetCohortStudents'
import { injectUseGetCohortStudentsProgress } from '@/composables/api/queries/useGetCohortStudentsProgress'
import { injectUseGetCohortAssignmentInsights } from '@/composables/api/queries/useGetCohortAssignmentInsights'
import type { CohortStudentProgress } from '@/open-api/generated'
import useRemoveStudentFromCohort from '@/composables/api/mutations/useRemoveStudentFromCohort'
import { DataTable } from '@/components/modern/ui/data-table'
import { useColumns } from '@/components/modern/tables/cohort-students'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import CohortTabBar from '@/components/modern/CohortTabBar.vue'
import { Button } from '@/components/modern/ui/button'
import { PlusIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern Cohorts - Cohort Learners List',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const route = useRoute('Modern Cohorts - Cohort Learners List')
const cohortId = computed(() => route.params.cohortId)

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

const { track, isLoading, refetch } = useCombineQueries()

const { students } = injectUseGetCohortStudentsProgress({
  cohortId,
  notificationStore
})
track(injectUseGetMyCohorts({ notificationStore }))
track(injectUseGetOrganizationCohorts({ notificationStore, authStore }))
track(injectUseGetCohortStudents({ cohortId, notificationStore }))
track(injectUseGetCohortStudentsProgress({ cohortId, notificationStore }))
track(injectUseGetCohortAssignmentInsights({ cohortId, notificationStore }))

const userId = ref<string | null>(null)
const { execute } = useRemoveStudentFromCohort({ userId, cohortId, notificationStore })
const requestRemoveStudent = (student: CohortStudentProgress) => (userId.value = student.user_id)
const confirmRemoveStudent = async () => execute().finally(refetch)

const columns = useColumns({
  cohortId,
  requestRemoveStudent,
  confirmRemoveStudent
})
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton
        v-if="authStore.isAtLeastInstitutionAdminUser"
        :to="{ name: 'Modern Cohorts - Cohort Administration' }"
        name="cohort administration"
      />
      <BackButton v-else :to="{ name: 'Modern Cohorts - My Cohorts List' }" name="all cohorts" />
    </template>
    <template #center>
      <CohortTabBar :active="'learners'" :cohortId />
    </template>
    <template v-if="authStore.isAtLeastInstitutionAdminUser" #right>
      <Button variant="default" size="xs" asChild>
        <RouterLink :to="{ name: 'Modern Cohorts - Add Learners to Cohort', params: { cohortId } }">
          <PlusIcon class="mr-2 size-4" />
          Add Learners
        </RouterLink>
      </Button>
    </template>
  </TopLine>
  <DataTable :data="students" :columns :isLoading />
</template>
