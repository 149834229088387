<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import { TopLine } from '@/components/modern/page-navigation'
import { DataTable, DataTableServerPagination } from '@/components/modern/ui/data-table'
import SearchFilter from '@/components/modern/ui/SearchFilter.vue'
import { useColumns } from '@/components/modern/tables/organization-voices'
import { Button } from '@/components/modern/ui/button'
import { PlusIcon } from '@radix-icons/vue'
import { injectUseListVoices } from '@/composables/api/queries/useListVoices'
import usePreviewVoice from '@/composables/api/usePreviewVoice'

definePage({
  name: 'Voices List',
  meta: {
    permissionLevel: 'SuperAdmin',
    isModern: true,
    requiresAuthoring: true
  }
})

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

const { query, voices, isLoading, pagination } = injectUseListVoices({
  authStore,
  notificationStore
})

const { previewVoice } = usePreviewVoice()

const columns = useColumns({ previewVoice })
</script>

<template>
  <TopLine>
    <template #left>
      <span> All Voices </span>
      <SearchFilter v-model="query" placeholder="Search by name, description" class="min-w-72" />
    </template>
    <template #right>
      <Button v-if="authStore.isSuperAdminUser" variant="default" size="xs" asChild>
        <RouterLink :to="{ name: 'New Voice' }">
          <PlusIcon class="mr-2 size-4" />
          <span>Add Voice</span>
        </RouterLink>
      </Button>
    </template>
  </TopLine>
  <DataTable :data="voices" :columns :isLoading />
  <DataTableServerPagination v-bind="pagination" />
</template>
