<script setup lang="ts">
import { provide } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import useListVoices, { useListVoicesKey } from '@/composables/api/queries/useListVoices'
import { Breadcrumb } from '@/components/modern/page-navigation'

definePage({
  name: 'Voices List Provider',
  redirect: 'Voices List',
  meta: {
    permissionLevel: 'SuperAdmin',
    isModern: true,
    requiresAuthoring: true
  }
})

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

const listVoices = useListVoices({ authStore, notificationStore })
provide(useListVoicesKey, listVoices)
</script>

<template>
  <Breadcrumb :route="{ name: 'Voices List' }" name="Voices" />
  <RouterView />
</template>
