<script setup lang="ts">
import { computed, provide } from 'vue'
import { useRoute } from 'vue-router'
import { Breadcrumb } from '@/components/modern/page-navigation'
import { useNotificationStore } from '@/stores/notifications'
import useGetVoice, { useGetVoiceKey } from '@/composables/api/queries/useGetVoice'

definePage({
  name: 'Voice Provider',
  redirect: 'Edit Voice',
  meta: {
    permissionLevel: 'SuperAdmin',
    isModern: true
  }
})

const route = useRoute('Voice Provider')
const voiceId = computed(() => route.params.voiceId)

const notificationStore = useNotificationStore()
const getVoice = useGetVoice({ voiceId, notificationStore })
provide(useGetVoiceKey, getVoice)
const { voice } = getVoice
</script>

<template>
  <Breadcrumb :route="{ name: 'Edit Voice' }" :name="voice?.name" />
  <RouterView />
</template>
