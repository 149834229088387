<script setup lang="ts">
import { ref, watch } from 'vue'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@/components/modern/ui/collapsible'
import { Button } from '@/components/modern/ui/button'
import NavItem, { type NavItemRoute } from './NavItem.vue'
import { TriangleRightIcon, TriangleDownIcon } from '@radix-icons/vue'

const props = defineProps<{
  label: string
  defaultOpen: boolean
  deprecated?: boolean
  routes: NavItemRoute[]
}>()

const localStorageKey = `collapsible-nav:${props.label}`
const localStorageIsOpen: string | null = localStorage.getItem(localStorageKey)

const internalIsOpen = ref<boolean>(
  localStorageIsOpen ? (JSON.parse(localStorageIsOpen) as boolean) : props.defaultOpen
)

watch(internalIsOpen, () => {
  localStorage.setItem(localStorageKey, JSON.stringify(internalIsOpen.value))
})
</script>

<template>
  <Collapsible v-model:open="internalIsOpen" as="nav" class="w-full space-y-[1px]">
    <Button variant="ghost" size="xs" class="justify-start p-1 hover:bg-border" asChild>
      <CollapsibleTrigger class="flex w-full items-center gap-1">
        <h4 class="truncate text-[0.625rem] uppercase">
          {{ props.label }}
        </h4>
        <TriangleRightIcon v-if="!internalIsOpen" />
        <TriangleDownIcon v-else />
        <span class="sr-only">Toggle Nav</span>
      </CollapsibleTrigger>
    </Button>
    <CollapsibleContent class="space-y-[1px]">
      <NavItem v-for="route in props.routes" :key="route.label" :route />
    </CollapsibleContent>
  </Collapsible>
</template>
