<script setup lang="ts">
import { ref, computed } from 'vue'
import CustomList from '@/components/CustomList.vue'
import CustomModal from '@/components/utils/CustomModal.vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomInput from '@/components/utils/CustomInput.vue'
import InputFile from '@/components/input/InputFile.vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { useContentStore } from '@/stores/content'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import { useAuthStore } from '@/stores/auth'
import useUploadImageEndpoint from '@/composables/api/useUploadImageEndpoint'

import Api from '@/open-api'
import type {
  CharacterInvestigationInput,
  CharacterInvestigationSummary,
  CharacterOutput,
  OrganizationIdAndName,
  UpdateCharacterInput
} from '@/open-api/generated'
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { SUPPORTED_IMAGE_AND_AUDIO_MIMETYPES } from '@/constants/supported-formats'
import { isMimetype } from '@/utils/mimetypes'
import { Breadcrumb } from '@/components/modern/page-navigation'

definePage({
  name: 'Character Investigations',
  meta: {
    permissionLevel: 'Educator',
    requiresAuthoring: true
  }
})

// ==================================================
// Init
// ==================================================
const route = useRoute('Character Investigations')
const contentStore = useContentStore()
const notificationStore = useNotificationStore()
const authStore = useAuthStore()
const isReadOnly = computed(() => {
  return (
    route.params.characterId !== 'new' &&
    !authStore.isAtLeastStaffUser &&
    !(
      authStore.organizationId &&
      authStore.organizationId ===
        (contentStore.editingCharacter as CharacterOutput)?.owning_organization_id
    )
  )
})

// ==================================================
// List Actions
// ==================================================
const selectedInvestigationIndex = ref(0)
const modalStatus = ref(false)
const modalType = ref('')

const onView = (data: { id: string; index: number }) => {
  modalType.value = 'edit'
  selectedInvestigationIndex.value = data.index
  const currentEditingInvestigation = contentStore?.editingCharacter?.investigations?.[data.index]
  modalFile.value = currentEditingInvestigation?.file_url || ''
  modalMimeType.value =
    currentEditingInvestigation && isMimetype(currentEditingInvestigation.mime_type)
      ? currentEditingInvestigation.mime_type
      : ''
  public_label.value = currentEditingInvestigation?.public_label || ''
  modalStatus.value = true
}

const onDelete = (data: { id: string; index: number }) => {
  modalType.value = 'delete'
  selectedInvestigationIndex.value = data.index
  modalStatus.value = true
}

const onCreate = () => {
  modalType.value = 'create'
  modalStatus.value = true
}

// ==================================================
// Modal Actions
// ==================================================
const public_label = ref('')

const {
  src: modalFile,
  mime: modalMimeType,
  loading: modalFileLoading,
  uploadFile,
  deleteFile
} = useUploadImageEndpoint({ notificationStore })

const saveLoading = ref(false)
const deleteLoading = ref(false)

const modalActionDisabled = computed(() => {
  if (modalType.value === 'edit') {
    const currentEditingInvestigation =
      contentStore.editingCharacter?.investigations?.[selectedInvestigationIndex.value]

    return (
      !public_label.value.trim() ||
      !modalFile.value ||
      (modalFile.value === currentEditingInvestigation?.file_url &&
        public_label.value === currentEditingInvestigation?.public_label)
    )
  } else {
    return !public_label.value.trim() || !modalFile.value
  }
})

const createInvestigation = async () => {
  if (route.params.characterId === 'new') {
    const newInvestigation: CharacterInvestigationInput = {
      public_label: public_label.value,
      file_url: modalFile.value,
      mime_type: modalMimeType.value
    }
    const newInvestigations = [
      ...(contentStore.editingCharacter?.investigations || []),
      newInvestigation
    ] as CharacterInvestigationSummary[]

    contentStore.updateEditingCharacter({
      investigations: newInvestigations
    })
    modalStatus.value = false
  } else {
    saveLoading.value = true

    const { editingCharacter } = contentStore

    if (!editingCharacter) {
      return
    }
    const updateCharacter = contentStore.editingCharacter as UpdateCharacterInput | CharacterOutput

    const update: UpdateCharacterInput = {
      ...updateCharacter,
      actions: updateCharacter.actions.map((action) => {
        return {
          action_text: action.action_text,
          public_label: action.public_label
        }
      }),
      allowlist: authStore.isAtLeastStaffUser
        ? updateCharacter.allowlist?.map((listItem) => {
            if (typeof listItem === 'string') {
              return listItem
            }
            return listItem?.organization_id
          })
        : null
    }

    update.investigations?.push({
      public_label: public_label.value,
      file_url: modalFile.value,
      mime_type: modalMimeType.value
    })

    Api.Content.updateCharacterEndpoint(update as UpdateCharacterInput)
      .then((res) => {
        contentStore.setEditingCharacter(res)
        notificationStore.addNotification({
          subtitle: 'Character Investigation successfully saved',
          status: NotificationStatus.SUCCESS
        })
      })
      .catch((err: any) => {
        notificationStore.addNotification({
          subtitle: err?.body?.message,
          status: NotificationStatus.DANGER
        })
      })
      .finally(() => {
        saveLoading.value = false
        modalStatus.value = false
      })
  }
}

const saveInvestigation = () => {
  const { editingCharacter } = contentStore

  if (!editingCharacter) {
    return
  }

  const update = { ...editingCharacter } as UpdateCharacterInput | CharacterOutput

  if (update.investigations) {
    update.investigations[selectedInvestigationIndex.value] = {
      public_label: public_label.value,
      file_url: modalFile.value,
      mime_type: modalMimeType.value
    }
    if (authStore.isAtLeastStaffUser) {
      if (update?.allowlist?.length) {
        update.allowlist = (update.allowlist as OrganizationIdAndName[]).map(
          (listItem) => listItem?.organization_id
        )
      }
    } else {
      update.allowlist = null
    }
  } else {
    return
  }

  if (route.params.characterId === 'new') {
    contentStore.setEditingCharacter(update)
    modalStatus.value = false
  } else {
    saveLoading.value = true

    Api.Content.updateCharacterEndpoint(update as UpdateCharacterInput)
      .then(() => {
        contentStore.setEditingCharacter(update as UpdateCharacterInput)
        notificationStore.addNotification({
          subtitle: 'Character Investigation successfully saved',
          status: NotificationStatus.SUCCESS
        })
      })
      .catch((err: any) => {
        notificationStore.addNotification({
          subtitle: err?.body?.message,
          status: NotificationStatus.DANGER
        })
      })
      .finally(() => {
        saveLoading.value = false
        modalStatus.value = false
      })
  }
}

const deleteInvestigation = () => {
  const { editingCharacter } = contentStore

  if (!editingCharacter) {
    return
  }
  const updateCharacter = contentStore.editingCharacter as UpdateCharacterInput | CharacterOutput

  const update = { ...updateCharacter } as UpdateCharacterInput

  if (route.params.characterId === 'new') {
    update.investigations?.splice(selectedInvestigationIndex.value, 1)
    contentStore.setEditingCharacter(update)
  } else {
    deleteLoading.value = true

    update.investigations?.splice(selectedInvestigationIndex.value, 1)

    update.allowlist = authStore.isAtLeastStaffUser
      ? updateCharacter.allowlist?.map((listItem) => {
          if (typeof listItem === 'string') {
            return listItem
          }
          return listItem?.organization_id
        })
      : null

    Api.Content.updateCharacterEndpoint(update as UpdateCharacterInput)
      .then(() => {
        contentStore.setEditingCharacter(update)
        notificationStore.addNotification({
          subtitle: 'Character Investigation successfully deleted',
          status: NotificationStatus.SUCCESS
        })
      })
      .catch((err: any) => {
        notificationStore.addNotification({
          subtitle: err?.body?.message,
          status: NotificationStatus.DANGER
        })
      })
      .finally(() => {
        deleteLoading.value = false
        modalStatus.value = false
      })
  }
}

watch(modalStatus, (newVal) => {
  if (!newVal) {
    setTimeout(() => {
      modalType.value = ''
      selectedInvestigationIndex.value = 0
      public_label.value = ''
      modalFile.value = ''
      modalMimeType.value = ''
    }, 350)
  }
})
</script>

<template>
  <Breadcrumb :route name="Investigations" />
  <CustomList
    class="px-5"
    viewType="cell"
    :listData="contentStore?.editingCharacter?.investigations || []"
    :hasHeader="false"
    canDelete
    :readOnly="isReadOnly"
    @onCreate="onCreate"
    @onDelete="onDelete"
    @onView="onView"
  />
  <CustomModal v-model="modalStatus">
    <div v-if="modalType === 'delete'" class="flex flex-col">
      <h3 class="mb-3 text-xl">Delete Investigation</h3>
      <p class="text-sc-grey-800">
        Are you sure you want to delete this investigation? This action cannot be undone.
      </p>
      <div class="mt-5 flex gap-3 self-end">
        <CustomButton buttonType="admin-secondary" @click="() => (modalStatus = false)">
          Cancel
        </CustomButton>
        <CustomButton :loading="deleteLoading" @click="deleteInvestigation">
          Yes, delete
        </CustomButton>
      </div>
    </div>
    <div v-else class="flex flex-col">
      <div class="mb-5 flex items-center justify-between">
        <h3 class="select-none text-lg">
          {{ isReadOnly ? 'View' : modalType === 'create' ? 'Create' : 'Edit' }} Investigation
        </h3>
        <div v-if="isReadOnly" class="h-7 w-7 cursor-pointer p-1" @click="modalStatus = false">
          <XMarkIcon class="h-5 w-5" />
        </div>
      </div>

      <CustomInput v-model="public_label" label="Label" :readOnly="isReadOnly" />
      <InputFile
        label="Upload File"
        required
        :uploadFile
        :acceptsFormats="SUPPORTED_IMAGE_AND_AUDIO_MIMETYPES"
        :src="modalFile"
        :mime="modalMimeType"
        :loading="modalFileLoading"
        :deleteFile
      />

      <div v-if="!isReadOnly" class="mt-5 flex gap-3 self-end">
        <CustomButton buttonType="admin-secondary" @click="() => (modalStatus = false)">
          Cancel
        </CustomButton>
        <CustomButton
          :loading="saveLoading"
          :disabled="modalActionDisabled"
          @click="modalType === 'edit' ? saveInvestigation() : createInvestigation()"
        >
          {{ modalType === 'edit' ? 'Save' : 'Create' }}
        </CustomButton>
      </div>
    </div>
  </CustomModal>
</template>
