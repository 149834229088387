<script setup lang="ts">
import { computed, provide } from 'vue'
import { useRoute } from 'vue-router'
import { useNotificationStore } from '@/stores/notifications'
import useGetCohortV2, { useGetCohortV2Key } from '@/composables/api/queries/useGetCohortV2'
import useGetCohortStudents, {
  useGetCohortStudentsKey
} from '@/composables/api/queries/useGetCohortStudents'
import useGetCohortStudentsProgress, {
  useGetCohortStudentsProgressKey
} from '@/composables/api/queries/useGetCohortStudentsProgress'
import useGetCohortAssignmentInsights, {
  useGetCohortAssignmentInsightsKey
} from '@/composables/api/queries/useGetCohortAssignmentInsights'

definePage({
  name: 'Cohort Provider',
  redirect: 'Modern Cohorts - Cohort Activities List',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const route = useRoute('Cohort Provider')
const cohortId = computed(() => route.params.cohortId)

const notificationStore = useNotificationStore()

const getCohort = useGetCohortV2({ cohortId, notificationStore })
provide(useGetCohortV2Key, getCohort)

const getCohortStudents = useGetCohortStudents({ cohortId, notificationStore })
provide(useGetCohortStudentsKey, getCohortStudents)

const getCohortStudentsProgress = useGetCohortStudentsProgress({ cohortId, notificationStore })
provide(useGetCohortStudentsProgressKey, getCohortStudentsProgress)

const getCohortAssignmentInsights = useGetCohortAssignmentInsights({ cohortId, notificationStore })
provide(useGetCohortAssignmentInsightsKey, getCohortAssignmentInsights)
</script>

<template>
  <RouterView />
</template>
