<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useNotificationStore } from '@/stores/notifications'
import useSearchOrganizations from '@/composables/api/queries/useSearchOrganizations'
import { CommandState } from '@/types/api'
import type { OrganizationIdAndName } from '@/open-api/generated'
import { DataTable } from '@/components/modern/ui/data-table'
import { useColumns } from '@/components/modern/tables/access-list'
import { Breadcrumb, TopLine, BackButton } from '@/components/modern/page-navigation'
import { SearchSelect, organizationConfig } from '@/components/modern/ui/search-select'
import { Label } from '@/components/modern/ui/label'
import { Button } from '@/components/modern/ui/button'
import { Separator } from '@/components/modern/ui/separator'
import { CheckboxIcon } from '@radix-icons/vue'
import useUpdateVoiceAccess from '@/composables/api/mutations/useUpdateVoiceAccess'

definePage({
  name: 'Voice Access List',
  meta: {
    permissionLevel: 'SuperAdmin',
    isModern: true
  }
})

const route = useRoute('Voice Access List')
const voiceId = computed(() => route.params.voiceId)
const notificationStore = useNotificationStore()

const { accessList, accessListLoading, ...updateAccessList } = useUpdateVoiceAccess({
  voiceId,
  notificationStore
})

const updatedAccessList = ref<OrganizationIdAndName[]>([])
watch(accessList, () => (updatedAccessList.value = accessList.value ?? []), { immediate: true })

const removeFromAccessList = (org: OrganizationIdAndName) =>
  (updatedAccessList.value = updatedAccessList.value.filter(
    (o) => o.organization_id !== org.organization_id
  ))
const addToAccessList = (org: OrganizationIdAndName) => {
  if (updatedAccessList.value.every((o) => o.organization_id !== org.organization_id)) {
    // Don't add duplicates
    updatedAccessList.value = [...updatedAccessList.value, org]
  }
}

const submitAccessList = () => {
  const newAcessList = updatedAccessList.value.map((org) => org.organization_id)
  updateAccessList.execute(newAcessList)
}

const {
  query,
  organizations: accessSearchOrganizations,
  isLoading: accessSearchLoading
} = useSearchOrganizations({
  notificationStore
})

const columns = useColumns({ removeFromAccessList })

// TODO: sync global content / sync to other regions?
</script>

<template>
  <Breadcrumb :route name="Access Control" />
  <TopLine>
    <template #left>
      <BackButton :to="{ name: 'Edit Voice', params: { voiceId } }" name="voice" />
    </template>
  </TopLine>
  <div class="mx-4 mb-2 mt-8 w-auto max-w-4xl lg:mx-6">
    <Label for="organization-access">Organizations with access</Label>
  </div>
  <Separator />
  <DataTable
    :data="updatedAccessList"
    :columns
    :isLoading="accessListLoading"
    empty="No organizations have access to this voice."
  />
  <div class="mx-4 mt-4 w-auto max-w-4xl space-y-4 lg:mx-6">
    <SearchSelect
      id="organization-access"
      v-bind="organizationConfig"
      v-model:query="query"
      :data="accessSearchOrganizations"
      :isLoading="accessSearchLoading"
      placeholderSelect="Select an organization to add..."
      clearOnSelect
      @select="addToAccessList"
    />

    <Button
      variant="default"
      size="xs"
      class="flex"
      :disabled="updateAccessList.state.value === CommandState.IN_PROGRESS"
      @click="submitAccessList"
    >
      <CheckboxIcon class="mr-2 size-4" />
      <span>Save changes</span>
    </Button>
  </div>
</template>
