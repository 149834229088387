<script setup lang="ts">
import { provide } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import useGetMyCohorts, { useGetMyCohortsKey } from '@/composables/api/queries/useGetMyCohorts'
import useGetOrganizationCohorts, {
  useGetOrganizationCohortsKey
} from '@/composables/api/queries/useGetOrganizationCohorts'
import { Breadcrumb } from '@/components/modern/page-navigation'

definePage({
  name: 'All Cohorts Provider',
  redirect: 'Modern Cohorts - My Cohorts List',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

const myCohorts = useGetMyCohorts({ notificationStore })
provide(useGetMyCohortsKey, myCohorts)

const orgCohorts = useGetOrganizationCohorts(
  { notificationStore, authStore },
  { includeArchived: authStore.isAtLeastInstitutionAdminUser }
)
provide(useGetOrganizationCohortsKey, orgCohorts)
</script>

<template>
  <Breadcrumb
    v-if="authStore.isAtLeastInstitutionAdminUser"
    :route="{ name: 'Modern Cohorts - Cohort Administration' }"
    name="Cohorts"
  />
  <Breadcrumb v-else :route="{ name: 'Modern Cohorts - My Cohorts List' }" name="Cohorts" />
  <RouterView />
</template>
