import Api from '@/open-api'
import type {
  CancelablePromise,
  GetMyCohortsCohort,
  GetMyCohortsOutput
} from '@/open-api/generated'
import type { NotificationStore } from '@/types/api'
import { type Ref, ref, onBeforeMount, type InjectionKey, inject } from 'vue'

const useGetMyCohorts = ({
  notificationStore
}: {
  notificationStore: NotificationStore
}): { isLoading: Ref<boolean>; cohorts: Ref<GetMyCohortsCohort[]>; refetch: () => void } => {
  let req: CancelablePromise<GetMyCohortsOutput> | null = null
  const isLoading = ref(false)
  const cohorts: Ref<GetMyCohortsCohort[]> = ref([])

  const refetch = () => {
    if (req && isLoading.value) {
      req.cancel()
    }
    isLoading.value = true

    req = Api.V2.Cohorts.getMyCohortsEndpointV2()
    req
      .then((res) => {
        cohorts.value = res.cohorts
      })
      .catch((err) => {
        if (err?.name === 'CancelError') {
          return
        }
        notificationStore.addDANGER(err?.body?.message || 'Error getting my cohorts')
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  onBeforeMount(refetch)

  return { isLoading, cohorts, refetch }
}

export default useGetMyCohorts

export const useGetMyCohortsKey: InjectionKey<ReturnType<typeof useGetMyCohorts>> =
  Symbol('useGetMyCohorts')

export const injectUseGetMyCohorts: typeof useGetMyCohorts = (params) =>
  inject(useGetMyCohortsKey, () => useGetMyCohorts(params), true)
