<script setup lang="ts">
import {
  type PrivateOrganizationData,
  LoginInstructionModeApiEnum,
  type LoginInstructionModeApi
} from '@/open-api/generated'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import { ref, watch } from 'vue'
import CustomModal from '@/components/utils/CustomModal.vue'
import CustomInput from '@/components/utils/CustomInput.vue'
import InputFile from '@/components/input/InputFile.vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomToggle from '@/components/utils/CustomToggle.vue'
import useUploadImageEndpoint from '@/composables/api/useUploadImageEndpoint'
import Api from '@/open-api'
import { useRouter } from 'vue-router'
import { SUPPORTED_IMAGE_MIMETYPES } from '@/constants/supported-formats'
import { useAuthStore } from '@/stores/auth'
import { useSuperAdminStore } from '@/stores/superadmin'

definePage({
  name: 'Management Organization',
  meta: {
    permissionLevel: 'SuperAdmin'
  }
})

// ==================================================
// Init
// ==================================================
const authStore = useAuthStore()
const superAdminStore = useSuperAdminStore()
const notificationStore = useNotificationStore()
const router = useRouter()

// ==================================================
// Organization Details
// ==================================================
const orgLoading = ref(false)
const selectedOrg = ref<PrivateOrganizationData | undefined>()

async function fetchOrgById() {
  orgLoading.value = true
  Api.Management.getOrganizationPrivateEndpoint(authStore.organizationId!)
    .then((res) => {
      selectedOrg.value = res
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      orgLoading.value = false
    })
}

watch(selectedOrg, () => {
  orgName.value = selectedOrg.value?.name || ''
  orgLogo.value = selectedOrg.value?.logo_url || ''
  orgAccentColor.value = selectedOrg.value?.accent_color || '#FC894B'
  orgPoweredBy.value = selectedOrg.value?.flag_powered_by_enabled || false
  orgAuthFlag.value = selectedOrg.value?.flag_authoring_enabled || false
  orgLoginInstructionMode.value = selectedOrg.value?.login_instruction_mode
  orgFlagLegacyFeatures.value = selectedOrg.value?.flag_legacy_features_enabled || false
  orgFlagModernFeatures.value = selectedOrg.value?.flag_modern_features_enabled || false
})
watch(
  () => authStore.organizationId,
  () => fetchOrgById()
)

fetchOrgById()

// ==================================================
// Update org name
// ==================================================
const {
  src: orgLogo,
  mime: orgLogoMime,
  loading: orgLogoLoading,
  uploadFile,
  deleteFile
} = useUploadImageEndpoint({ notificationStore })

const orgName = ref('')
const orgAccentColor = ref('#FC894B')
const orgPoweredBy = ref(false)
const orgAuthFlag = ref(false)
const orgUpdateLoading = ref(false)
const orgLoginInstructionMode = ref<LoginInstructionModeApi | undefined>(undefined)
const orgFlagLegacyFeatures = ref(false)
const orgFlagModernFeatures = ref(false)

const updateOrg = async () => {
  if (!authStore.organizationId) {
    return
  }

  orgUpdateLoading.value = true
  Api.Management.updateOrganizationEndpoint({
    organization_id: authStore.organizationId,
    name: orgName.value,
    accent_color: orgAccentColor.value,
    flag_powered_by_enabled: orgPoweredBy.value,
    logo_url: orgLogo.value || null,
    flag_authoring_enabled: orgAuthFlag.value,
    login_instruction_mode: orgLoginInstructionMode.value!,
    flag_legacy_features_enabled: orgFlagLegacyFeatures.value,
    flag_modern_features_enabled: orgFlagModernFeatures.value
  })
    .then(() => {
      notificationStore.addNotification({
        title: 'Success 🎉',
        subtitle: 'Organization has been updated',
        status: NotificationStatus.SUCCESS
      })
      fetchOrgById()
      router.push({ name: 'Management Organization', query: { updateName: orgName.value || '' } })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      orgUpdateLoading.value = false
    })
}

// ==================================================
// Create org
// ==================================================
const modalStatus = ref(false)
const newOrgName = ref('')
const createOrgLoading = ref(false)

watch(modalStatus, () => {
  if (!modalStatus.value) {
    newOrgName.value = ''
  }
})

async function onCreateOrgClick() {
  if (newOrgName.value && newOrgName.value.length >= 3) {
    createOrgLoading.value = true
    Api.Management.createOrganizationEndpoint({
      name: newOrgName.value,
      flag_authoring_enabled: false,
      flag_powered_by_enabled: false,
      flag_legacy_features_enabled: false,
      flag_modern_features_enabled: true
    })
      .then((res) => {
        superAdminStore.setSyntheticOrganizationId(res.organization_id)
        selectedOrg.value = res
      })
      .catch((err: any) => {
        notificationStore.addNotification({
          subtitle: err?.body?.message,
          status: NotificationStatus.DANGER
        })
      })
      .finally(() => {
        createOrgLoading.value = false
        modalStatus.value = false
      })
  }
}
</script>

<template>
  <div class="flex justify-end px-5">
    <CustomButton @click="modalStatus = !modalStatus"> Create Organization </CustomButton>
  </div>
  <h2 class="mx-5 mt-5 text-xl">Details</h2>

  <div class="grid max-w-screen-md grid-cols-1 gap-3 px-5">
    <CustomInput v-model="orgName" inputType="text" label="Organization Name" />
    <CustomInput
      v-model="orgLoginInstructionMode"
      inputType="select"
      placeholder="Please select..."
      label="Login Instruction Mode"
      :options="[
        { name: 'Passwordless', value: LoginInstructionModeApiEnum.PASSWORDLESS },
        { name: 'SSO', value: LoginInstructionModeApiEnum.SSO },
        { name: 'Password', value: LoginInstructionModeApiEnum.PASSWORD }
      ]"
    />
  </div>

  <div class="grid max-w-screen-md gap-5 px-5">
    <div class="flex items-center gap-5">
      <CustomToggle v-model="orgAuthFlag" />

      <div class="flex flex-col">Enable Authoring features for this organization?</div>
    </div>

    <div class="flex items-center gap-5">
      <CustomToggle v-model="orgPoweredBy" />

      <div class="flex flex-col">Enable custom logos and accent colors</div>
    </div>

    <div class="flex items-center gap-5">
      <CustomToggle v-model="orgFlagLegacyFeatures" />

      <div class="flex flex-col">Enable Legacy Features</div>
    </div>

    <div class="flex items-center gap-5">
      <CustomToggle v-model="orgFlagModernFeatures" />

      <div class="flex flex-col">Enable Modern Features</div>
    </div>

    <template v-if="orgPoweredBy">
      <CustomInput
        v-model="orgAccentColor"
        class="!mb-0 w-fit"
        inputType="color"
        label="Accent Color"
      />
      <InputFile
        label="Upload Logo"
        required
        :uploadFile
        :acceptsFormats="SUPPORTED_IMAGE_MIMETYPES"
        :src="orgLogo"
        :mime="orgLogoMime"
        :loading="orgLogoLoading"
        :deleteFile
      />
    </template>
  </div>
  <div class="grid max-w-screen-md grid-cols-1 gap-3 px-5 md:grid-cols-2">
    <CustomButton
      buttonType="admin-primary"
      :disabled="orgUpdateLoading"
      :loading="orgUpdateLoading"
      @click="updateOrg"
    >
      Update
    </CustomButton>
  </div>

  <CustomModal v-model="modalStatus">
    <div class="flex flex-col">
      <h3 class="mb-3 text-xl">Create Organization</h3>
      <CustomInput v-model="newOrgName" class="!my-0" inputType="text" label="Organization Name" />
      <div class="mt-5 flex gap-3 self-end">
        <CustomButton buttonType="admin-secondary" @click="() => (modalStatus = false)">
          Cancel
        </CustomButton>
        <CustomButton
          :loading="createOrgLoading"
          :disabled="createOrgLoading || !newOrgName.trim()"
          @click="onCreateOrgClick"
        >
          Create
        </CustomButton>
      </div>
    </div>
  </CustomModal>
</template>
