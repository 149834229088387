<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useNotificationStore } from '@/stores/notifications'
import useFirstSecondIds from '@/composables/useFirstSecondIds'
import { injectUseGetAssignmentInstanceStudentInsight } from '@/composables/api/queries/useGetAssignmentInstanceStudentInsight'
import useGetTaskAttempt from '@/composables/api/queries/useGetTaskAttempt'
import useCombineQueries from '@/composables/api/useCombineQueries'
import useTaskAttemptState from '@/composables/useTaskAttemptState'
import useScrollToTopOnChange from '@/composables/useScrollToTopOnChange'
import { Breadcrumb, TopLine, BackButton, ForwardButton } from '@/components/modern/page-navigation'
import { ReviewTask, ObserveConversationTask } from '@/components/modern/task-attempts'
import { LoadingCover } from '@/components/modern/ui/loading'

definePage({
  name: 'Modern Educator - Review Task Attempt',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

// Extract information from the route
const route = useRoute('Modern Educator - Review Task Attempt')
const cohortId = computed(() => route.params.cohortId)
const firstId = computed(() => route.params.firstId)
const secondId = computed(() => route.params.secondId)
const taskAttemptId = computed(() => route.params.taskAttemptId)
const { userId, assignmentInstanceId } = useFirstSecondIds({
  firstId,
  secondId
})

// Fetch data from server
const notificationStore = useNotificationStore()
const { track, isLoading } = useCombineQueries()
const { tasks } = track(
  injectUseGetAssignmentInstanceStudentInsight({
    userId,
    assignmentInstanceId,
    notificationStore
  })
)
const { taskAttempt } = track(useGetTaskAttempt({ taskAttemptId, notificationStore }))

// Work backwards from attempt to find the task
const taskId = computed(() => taskAttempt.value?.task_id ?? null)
const task = computed(() => tasks.value?.find((t) => t.task_id === taskId.value) ?? null)

const {
  completed,
  inRubric,
  showRubric,
  canShowRubric,
  hideRubric,
  canHideRubric,
  taskAttemptNumber
} = useTaskAttemptState({ task, taskAttempt })

const taskAttemptCount = computed((): string | undefined =>
  taskAttemptNumber.value ? `Attempt #${taskAttemptNumber.value}` : undefined
)

// Scroll to top on appearance of page navigation
useScrollToTopOnChange(inRubric)
</script>

<template>
  <Breadcrumb :route :name="taskAttemptCount" />
  <TopLine>
    <template #left>
      <BackButton v-if="canHideRubric" name="transcript" @click="hideRubric" />
      <BackButton
        v-else
        :to="{
          name: 'Modern Educator - Student Attempts on Task',
          params: { cohortId, firstId, secondId }
        }"
        name="learner's attempts"
      />
    </template>
    <template v-if="!isLoading" #right>
      <ForwardButton v-if="canShowRubric" text="Continue to feedback" @click="showRubric" />
      <ForwardButton
        v-if="canHideRubric"
        :to="{
          name: 'Modern Educator - Student Attempts on Task',
          params: { cohortId, firstId, secondId }
        }"
        text="Return to learner's attempts"
      />
    </template>
  </TopLine>
  <LoadingCover :isLoading centerScreen>
    <ReviewTask
      v-if="completed && task && taskAttempt"
      :taskAttemptId
      :taskPayload="task.payload"
      :attemptPayload="taskAttempt.payload"
      :inRubric
    />
    <ObserveConversationTask
      v-else-if="taskAttempt && 'conversation_id' in taskAttempt.payload"
      :conversationId="taskAttempt.payload.conversation_id"
    />
  </LoadingCover>
</template>
