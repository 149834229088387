<script setup lang="ts">
import type { OrganizationSummary } from '@/open-api/generated'
import { ArrowLeftOnRectangleIcon } from '@heroicons/vue/24/solid'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import { useAuthStore } from '@/stores/auth'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomInput from '@/components/utils/CustomInput.vue'
import { useAuth0 } from '@auth0/auth0-vue'
import { ref, onMounted } from 'vue'
import AppLogo from '@/components/AppLogo.vue'
import { AUTH0_AUDIENCE } from '@/constants/environment'
import { EMPTY_STRING_SEARCH_ALL } from '@/constants/server'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import Api from '@/open-api'
import { useRouter, useRoute } from 'vue-router'

definePage({
  name: 'Login',
  meta: {
    permissionLevel: 'ANY'
  }
})
// ==================================================
// Init
// ==================================================
const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
const router = useRouter()
const route = useRoute('Login')
const notificationStore = useNotificationStore()
const authStore = useAuthStore()
const orgLoading = ref(false)

onMounted(async () => {
  if (isAuthenticated) {
    if (authStore.flagModernEnabled) {
      router.push({ name: 'Modern - My Activities' })
    } else {
      router.push({ name: 'MyAssignments' })
    }
  }

  // Login directly via Organization ID
  if (route.params?.organizationId && typeof route.params.organizationId === 'string') {
    orgLoading.value = true
    Api.Management.getOrganizationPublicEndpoint(route.params.organizationId)
      .then((res) => {
        login(res.auth0_org_id)
      })
      .catch(() => {
        notificationStore.addNotification({
          subtitle: 'Unrecognized organization',
          status: NotificationStatus.DANGER
        })
        orgLoading.value = false
      })
  }

  // Login directly via Auth0 Organization ID
  if (route.query.organisation) {
    login(route.query.organisation as string)
  }

  // Geo error notification
  if (authStore.geoError) {
    notificationStore.addNotification({
      subtitle: "Sorry, but you can't log in from your current location.",
      status: NotificationStatus.DANGER,
      timeoutVal: 10000
    })
    authStore.setGeoError(false)
  }
})

// ==================================================
// Search Organization
// ==================================================
const items = ref<{ id: string; label: string }[]>([])
const organizations = ref<OrganizationSummary[]>([])
const orgId = ref<string | undefined>('')

async function fetchOrganizations(name?: string) {
  if (name === '') {
    return
  }
  orgLoading.value = true
  Api.Management.listAllOrganizationsEndpoint(name || EMPTY_STRING_SEARCH_ALL)
    .then((res) => {
      items.value = res.organizations.map((org) => {
        return {
          label: org.name,
          id: org.organization_id
        }
      })
      organizations.value = res.organizations
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      orgLoading.value = false
    })
}

// ==================================================
// Login
// ==================================================
function login(auth0_org_id?: string) {
  let auth0OrgId = auth0_org_id
  if (!auth0OrgId) {
    const foundOrg = organizations.value.find((org) => org.organization_id === orgId.value)
    if (foundOrg?.auth0_org_id) {
      auth0OrgId = foundOrg.auth0_org_id
    }
  }

  if (auth0OrgId) {
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.origin + '/authorize',
        audience: AUTH0_AUDIENCE,
        scope: 'openid profile email offline_access',
        organization: auth0OrgId
      }
    })
  }

  orgLoading.value = false
}
</script>

<template>
  <div class="flex min-h-screen items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
    <div v-if="isLoading && isAuthenticated">
      <AppLoadingSpinner loading />
    </div>
    <div v-else class="w-full max-w-sm space-y-8">
      <div class="mx-auto mb-14 w-full">
        <AppLogo class="mx-auto" :logoHeight="90" />
      </div>
      <form id="login-form" class="mt-8 space-y-6" @submit.prevent="login()">
        <CustomInput
          v-model="orgId"
          inputType="select-search"
          placeholder="Select your organization"
          label="Organization"
          :loading="orgLoading"
          :options="
            organizations.map((org) => {
              return {
                name: org.name,
                value: org.organization_id
              }
            })
          "
          @search="fetchOrganizations"
        />

        <CustomButton
          class="w-full"
          :disabled="!orgId"
          :loading="orgLoading"
          :startIcon="ArrowLeftOnRectangleIcon"
          @click="login()"
        >
          Login
        </CustomButton>
      </form>
    </div>
  </div>
</template>
