<script setup lang="ts">
import { useAssignmentStore } from '@/stores/assignment'
import { ChevronLeftIcon } from '@heroicons/vue/24/outline'
import { computed, ref } from 'vue'
import { onBeforeMount } from 'vue'
import { TaskTypeApiEnum, type TaskTypeApi, type MyTask } from '@/open-api/generated'
import type {
  MyConversationTaskMeta,
  MyFeedbackTaskMeta,
  MyReflectionTaskMeta
} from '@/types/subtypes'
import { reactive } from 'vue'
import { initMyVRConversationTask } from '@/vr/composables/useVrSimulation'
import { useNotificationStore } from '@/stores/notifications'
import { NotificationStatus } from '@/types/notification'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomTypeformModal from '@/components/utils/CustomTypeformModal.vue'
import dayjs from 'dayjs'
import { useUtilsStore } from '@/stores/utils'
import HeaderList, { type HeaderField } from '@/components/my-assignments/HeaderList.vue'
import CustomDeleteModal from '@/components/utils/CustomDeleteModal.vue'
import { RichTextReader } from '@/components/rich-text'
import Api from '@/open-api'
import { useAuthStore } from '@/stores/auth'
import { useRouter, useRoute } from 'vue-router'
import { INFINITE_ATTEMPTS } from '@/constants/forms'

definePage({
  name: 'MyAssignments Task',
  meta: {
    permissionLevel: 'Student'
  }
})

// ==================================================
// Init
// ==================================================
const router = useRouter()
const route = useRoute('MyAssignments Task')
const assignmentStore = useAssignmentStore()
const notificationStore = useNotificationStore()
const utilsStore = useUtilsStore()
const authStore = useAuthStore()

const formatDate = (val: string) => {
  return dayjs(val).format('D MMM YYYY, hh:mma')
}

const isPreview = computed(() => {
  return route.query.preview === 'preview' && authStore.isAtLeastEducatorUser
})

// ==================================================
// task logic
// ==================================================

const currentTask = computed((): MyTask | undefined => {
  if (isPreview.value) {
    const task = assignmentStore.previewTasks.find((task) => task.task_id === route.params.taskId)
    return {
      task_id: task?.task_id,
      task_type: task?.task_type,
      name: task?.name,
      mandatory: task?.mandatory,
      description: task?.description,
      meta: task?.meta
    } as MyTask
  }
  if (!assignmentStore?.currentAssignment?.tasks) {
    return undefined
  }
  return assignmentStore.currentAssignment?.tasks.find(
    (task) => task.task_id === route.params.taskId
  )
})

const canStartTask = (task: MyTask) => {
  if (isPreview.value) {
    return true
  }
  if (assignmentStore?.currentAssignment?.due_date) {
    if (task.task_type === TaskTypeApiEnum.CONVERSATION_TASK) {
      const { number_of_attempts, max_attempts } = (task.meta as MyConversationTaskMeta)
        .ConversationTask
      return number_of_attempts < max_attempts
    } else if (task.task_type === TaskTypeApiEnum.FEEDBACK_TASK) {
      if ((task.meta as MyFeedbackTaskMeta).FeedbackTask.retryable) {
        return true
      }
      return (task.meta as MyFeedbackTaskMeta).FeedbackTask.number_of_attempts === 0
    } else if (task.task_type === TaskTypeApiEnum.PRE_LEARNING_TASK) {
      return true
    } else if (task.task_type === TaskTypeApiEnum.REFLECTION_TASK) {
      const { number_of_attempts, max_attempts } = (task.meta as MyReflectionTaskMeta)
        .ReflectionTask
      return number_of_attempts < max_attempts
    }
  }

  return false
}

const typeform = reactive({
  typeformModalIsOpen: false,
  typeformUrl: ''
})

const startTaskLoading = ref(false)

const lateSubmission = reactive({
  modalStatus: false,
  taskType: undefined as TaskTypeApi | undefined
})

const startTaskSubmission = (taskType: TaskTypeApi) => {
  let assignment_id = isPreview.value
    ? route.params.assignmentId
    : assignmentStore.currentAssignment?.assignment_id
  let currentTaskId = currentTask.value?.task_id
  if (!assignment_id || !currentTaskId) {
    notificationStore.addDANGER('Sorry! Assignment ID or Task ID not found')
    return
  }

  // Late submission
  if (
    dayjs(assignmentStore?.currentAssignment?.due_date).valueOf() < dayjs().valueOf() &&
    !isPreview.value
  ) {
    lateSubmission.taskType = taskType
    lateSubmission.modalStatus = true
    return
  }

  switch (taskType) {
    case TaskTypeApiEnum.CONVERSATION_TASK:
      isPreview.value ? startConversationPreview() : startConversationTask()
      break
    case TaskTypeApiEnum.FEEDBACK_TASK:
      startFeedbackTask()
      break
    case TaskTypeApiEnum.PRE_LEARNING_TASK:
      utilsStore.setMyAssignmentId(assignment_id)
      router.push({
        name: 'MyAssignments Prelearning Task',
        params: {
          assignmentId: assignment_id,
          taskId: currentTaskId
        },
        query: isPreview.value ? { preview: 'preview' } : {}
      })
      break
    case TaskTypeApiEnum.REFLECTION_TASK:
      utilsStore.setMyAssignmentId(assignment_id)
      router.push({
        name: 'MyAssignments Reflection Task',
        params: {
          assignmentId: assignment_id,
          taskId: currentTaskId
        },
        query: isPreview.value ? { preview: 'preview' } : {}
      })
      break
  }
}

const confirmLateSubmission = () => {
  if (!assignmentStore.currentAssignment?.assignment_id || !currentTask.value?.task_id) {
    notificationStore.addDANGER('Sorry! Assignment ID or Task ID not found.')
    return
  }
  lateSubmission.modalStatus = false
  const assignmentId = assignmentStore.currentAssignment?.assignment_id
  const taskId = currentTask.value?.task_id
  if (lateSubmission.taskType === TaskTypeApiEnum.CONVERSATION_TASK) {
    startConversationTask()
  } else if (lateSubmission.taskType === TaskTypeApiEnum.FEEDBACK_TASK) {
    startFeedbackTask()
  } else if (lateSubmission.taskType === TaskTypeApiEnum.PRE_LEARNING_TASK) {
    utilsStore.setMyAssignmentId(assignmentId)
    router.push({
      name: 'MyAssignments Prelearning Task',
      params: { assignmentId, taskId }
    })
  } else if (lateSubmission.taskType === TaskTypeApiEnum.REFLECTION_TASK) {
    utilsStore.setMyAssignmentId(assignmentId)
    router.push({
      name: 'MyAssignments Reflection Task',
      params: { assignmentId, taskId }
    })
  }
}

const isVrTask = (task: MyTask): boolean => {
  if (TaskTypeApiEnum.CONVERSATION_TASK in task.meta) {
    const { scene_id } = (task.meta as MyConversationTaskMeta).ConversationTask
    if (scene_id) {
      return true
    }
  }
  return false
}

// the result you get back from "useRouter" is somehow tied to a 'this' context
// which seems to mean it will break and be undefined after any async boundary
// You have to trace backwards until you find the first async boundary and create
// the result of useRouter *then* & pass it forwards to preserve the context binding.

const startConversationTask = async () => {
  if (currentTask.value && currentTask.value.task_type === TaskTypeApiEnum.CONVERSATION_TASK) {
    try {
      // Start conversation task attempt
      startTaskLoading.value = true
      utilsStore.setMyAssignmentId(assignmentStore.currentAssignment?.assignment_id)

      // If a conversation task has scene_id, it's a VR task
      const { scene_id } = (currentTask.value.meta as MyConversationTaskMeta).ConversationTask
      if (scene_id) {
        const navigateToScene = () =>
          router.push({ name: 'VR Scene', params: { sceneId: scene_id } })
        initMyVRConversationTask(
          scene_id,
          startTaskLoading,
          currentTask.value.task_id,
          navigateToScene
        )
      } else {
        // it's not a VR task
        const res = await Api.MyAssignments.startMyConversationTaskEndpoint({
          task_id: currentTask.value.task_id
        })

        const { conversation_id, conversation_task_attempt_id } = res

        router.push({
          name: 'MyAssignments Conversation',
          params: { taskAttemptId: conversation_task_attempt_id, conversationId: conversation_id }
        })
        startTaskLoading.value = false
      }
    } catch (err: any) {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
      startTaskLoading.value = false
    }
  }
}

const startConversationPreview = () => {
  startTaskLoading.value = true

  Api.Conversation.startConversationEndpoint({
    character_id: route.query.characterId as string,
    rubric_id: route.query.rubricId as string
  })
    .then((res) => {
      router.push({
        name: 'Cohort Conversation',
        params: { conversationId: res.conversation_id }
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      startTaskLoading.value = false
    })
}

const startFeedbackTask = () => {
  if (currentTask.value && currentTask.value.task_type === TaskTypeApiEnum.FEEDBACK_TASK) {
    const { typeform_link } = (currentTask.value.meta as MyFeedbackTaskMeta).FeedbackTask
    // Open typeform modal
    typeform.typeformUrl = typeform_link
    typeform.typeformModalIsOpen = true
  }
}

const endFeedbackTask = async () => {
  if (currentTask.value && currentTask.value.task_type === TaskTypeApiEnum.FEEDBACK_TASK) {
    try {
      // Start feedback task attempt
      startTaskLoading.value = true
      await Api.MyAssignments.startMyFeedbackTaskEndpoint({
        task_id: currentTask.value.task_id
      })

      // Close typeform modal
      typeform.typeformModalIsOpen = false

      // Add feedback task attempt
      ;(currentTask.value.meta as MyFeedbackTaskMeta).FeedbackTask.number_of_attempts += 1

      router.push({
        name: 'MyAssignments Details',
        params: { assignmentId: route.params.assignmentId }
      })
    } catch (err: any) {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    } finally {
      startTaskLoading.value = false
    }
  }
}

const endPreviewFeedbackTask = () => {
  typeform.typeformModalIsOpen = false
}

// ==================================================
// Header details
// ==================================================

/* eslint-disable vue/no-ref-object-reactivity-loss  -- this is legacy */
const assignmentDate = isPreview.value
  ? assignmentStore.previewAssignment?.due_date
  : assignmentStore.currentAssignment?.due_date

const headerFields = ref<HeaderField[]>([
  {
    title: 'DUE DATE',
    value: formatDate(assignmentDate || ''),
    mobileVisible: currentTask.value?.task_type !== TaskTypeApiEnum.CONVERSATION_TASK
  },
  {
    title: 'TASK TYPE',
    value: currentTask?.value?.task_type?.split(/(?=[A-Z])/).join(' ') || ''
  }
])
/* eslint-enable vue/no-ref-object-reactivity-loss */

onBeforeMount(() => {
  if (currentTask.value?.task_type === TaskTypeApiEnum.CONVERSATION_TASK && !isPreview.value) {
    headerFields.value.push({
      title: 'ATTEMPTS',
      value:
        (currentTask?.value?.meta as MyConversationTaskMeta)?.ConversationTask?.max_attempts !==
        INFINITE_ATTEMPTS
          ? `${
              (currentTask?.value?.meta as MyConversationTaskMeta).ConversationTask.max_attempts -
              (currentTask?.value?.meta as MyConversationTaskMeta).ConversationTask
                .number_of_attempts
            }/${
              (currentTask?.value?.meta as MyConversationTaskMeta).ConversationTask.max_attempts
            } attempts remaining`
          : '∞ attempts remaining',
      mobileVisible: true
    })
  }

  if (currentTask.value?.task_type === TaskTypeApiEnum.REFLECTION_TASK && !isPreview.value) {
    headerFields.value.push({
      title: 'ATTEMPTS',
      value:
        (currentTask?.value?.meta as MyReflectionTaskMeta)?.ReflectionTask?.max_attempts !==
        INFINITE_ATTEMPTS
          ? `${
              (currentTask?.value?.meta as MyReflectionTaskMeta).ReflectionTask.max_attempts -
              (currentTask?.value?.meta as MyReflectionTaskMeta).ReflectionTask.number_of_attempts
            }/${
              (currentTask?.value?.meta as MyReflectionTaskMeta).ReflectionTask.max_attempts
            } attempts remaining`
          : '∞ attempts remaining',
      mobileVisible: true
    })
  }
})
</script>

<template>
  <div class="sticky -top-3 z-50 flex flex-col gap-5 bg-white pt-3">
    <div
      v-if="!isPreview"
      class="flex cursor-pointer items-center gap-2 text-sc-grey-700"
      @click="
        router.push({
          name: 'MyAssignments Details',
          params: { assignmentId: route.params.assignmentId }
        })
      "
    >
      <ChevronLeftIcon class="h-4 w-4" />
      <h4 class="font-normal text-sc-grey-700">Back to Assignment</h4>
    </div>

    <div class="flex justify-between">
      <div class="flex flex-col">
        <small class="text-sc-grey-700">{{ isPreview ? 'Preview' : 'Task' }}</small>
        <h2 class="text-xl">{{ currentTask?.name }}</h2>
      </div>
    </div>

    <HeaderList :fields="headerFields" />
  </div>
  <h3 v-if="currentTask?.description" class="my-4 text-lg font-medium">Description</h3>

  <img
    v-if="currentTask?.task_type === TaskTypeApiEnum.CONVERSATION_TASK"
    class="my-4 h-[120px] w-[120px] rounded-full object-cover"
    :src="(currentTask.meta as MyConversationTaskMeta).ConversationTask.character_avatar_url"
    :alt="`Charactar avatar for task: ${currentTask?.name}`"
  />

  <RichTextReader :content="currentTask?.description || ''" />

  <div
    v-if="currentTask?.task_type"
    class="flex w-full flex-col gap-5 bg-white pb-10 md:flex-row md:pb-5"
  >
    <CustomButton
      v-if="canStartTask(currentTask)"
      class="w-full md:w-fit"
      :loading="startTaskLoading"
      @click="startTaskSubmission(currentTask.task_type)"
    >
      {{ `Start Task${isVrTask(currentTask) ? ' (VR)' : ''}` }}
    </CustomButton>
    <CustomButton
      v-if="
        currentTask?.task_type === TaskTypeApiEnum.CONVERSATION_TASK &&
        (currentTask.meta as MyConversationTaskMeta).ConversationTask.number_of_attempts &&
        !isPreview
      "
      class="w-full md:w-fit"
      buttonType="admin-secondary"
      :loading="startTaskLoading"
      @click="router.push({ name: 'MyAssignments Task History' })"
    >
      View attempts
    </CustomButton>
  </div>

  <!---------------------------- Typeform ---------------------------->
  <CustomTypeformModal
    :modalStatus="typeform.typeformModalIsOpen"
    :typeformUrl="typeform.typeformUrl"
    class="[&_.dialog-panel]:h-full [&_.dialog-panel]:w-full"
    @onClose="typeform.typeformModalIsOpen = false"
    @onSubmit="isPreview ? endPreviewFeedbackTask() : endFeedbackTask()"
  />

  <!--------------------------- Overdue modal --------------------------->
  <CustomDeleteModal
    :modalStatus="lateSubmission.modalStatus"
    deleteButtonText="Yes, I'm sure"
    title="Late submission"
    message="It's past the assignment's due date, attempting this task now would mark the assignment as late. Are you sure you want to continue?"
    @confirm="confirmLateSubmission"
    @cancel="lateSubmission.modalStatus = false"
  />
</template>
