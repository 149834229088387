<script setup lang="ts">
import AppBrainIcon from '@/components/AppBrainIcon.vue'
import {
  DOWNLOAD_CHROME,
  DOWNLOAD_EDGE,
  DOWNLOAD_FIREFOX,
  DOWNLOAD_SAFARI
} from '@/constants/external-urls'

definePage({
  name: 'Unsupported Browser',
  meta: {
    permissionLevel: 'ANY'
  }
})

const browsers = [
  { name: 'chrome', link: DOWNLOAD_CHROME },
  { name: 'firefox', link: DOWNLOAD_FIREFOX },
  { name: 'safari', link: DOWNLOAD_SAFARI },
  { name: 'edge', link: DOWNLOAD_EDGE }
]
</script>

<template>
  <div class="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-white">
    <div class="py-16">
      <div class="flex flex-col items-center gap-5 text-center">
        <AppBrainIcon class="h-64" />
        <h1 class="text-2xl font-medium">Browser not supported.</h1>
        <h2 class="mb-0 text-center text-lg font-normal text-sc-grey-700">
          Your current browser is not supported. Please make sure
          <br />
          your browser is fully updated and you are using one of our
          <br />
          supported browsers listed below.
        </h2>
        <div class="mt-5 flex gap-5">
          <a v-for="browser in browsers" :key="browser.name" :href="browser.link" target="_blank">
            <img
              :src="`/assets/browser-icons/${browser.name}.svg`"
              :alt="`Browser logo for ${browser.name}`"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
