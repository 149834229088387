<script setup lang="ts">
import { ref, reactive } from 'vue'
import CustomList from '@/components/CustomList.vue'
import { VR_SCENES_MASTER } from '@/vr/vr-constants'
import { initVRConversation } from '@/vr/composables/useVrSimulation'
import { useRouter } from 'vue-router'

definePage({
  name: 'VR',
  meta: {
    permissionLevel: 'SuperAdmin'
  }
})

const initLoading = ref(false)
const vrPagination = reactive({ items_per_page: 15, page: 1, total: 0 })
const vrList = reactive(VR_SCENES_MASTER)
const router = useRouter()

const onView = (data: { id: string; index: number }) => {
  const navigateToScene = () => router.push({ name: 'VR Scene', params: { sceneId: data.id } })
  initVRConversation(data.id, initLoading, navigateToScene)
}
</script>

<template>
  <CustomList
    title="VR Simulations"
    :viewType="'Cell'"
    :listData="vrList"
    :hasHeader="false"
    :cellAdd="false"
    :hasListOptions="false"
    :loading="initLoading"
    hasImg
    :canDelete="false"
    :hasSearch="false"
    :pagination="vrPagination"
    :hasFilter="false"
    @onView="onView"
  />
</template>
