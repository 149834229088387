<script setup lang="ts">
import { ref, computed, reactive, onBeforeMount, watch } from 'vue'
import CustomList from '@/components/CustomList.vue'
import CustomModal from '@/components/utils/CustomModal.vue'
import CustomDeleteModal from '@/components/utils/CustomDeleteModal.vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomCollapse from '@/components/utils/CustomCollapse.vue'
import { UserPlusIcon, UserMinusIcon, ArrowDownOnSquareIcon } from '@heroicons/vue/24/outline'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import { useAssignmentStore } from '@/stores/assignment'
import Api from '@/open-api'
import {
  CohortStudentExportFilterApiEnum,
  type CohortStudentExportFilterApi,
  type StudentSummary
} from '@/open-api/generated'
import CustomPopover, { type PopoverOption } from '@/components/utils/CustomPopover.vue'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { EMPTY_STRING_SEARCH_ALL } from '@/constants/server'
definePage({
  name: 'Cohort Learners',
  meta: {
    permissionLevel: 'Educator'
  }
})
dayjs.extend(utc)
dayjs.extend(timezone)

// ==================================================
// Init
// ==================================================
const assignmentStore = useAssignmentStore()
const notificationStore = useNotificationStore()
const initLoading = ref(false)
const saveStudentsLoading = ref(false)
const allStudentList = ref<StudentSummary[]>([])
const allStudentPagination = reactive({ items_per_page: 9, page: 1, total: 1 })
const selectedStudentList = ref<StudentSummary[]>([])
const selectedStudentPagination = reactive({ items_per_page: 9, page: 1, total: 1 })
const deleteStudentModalStatus = ref(false)
const deleteStudent = ref<StudentSummary | undefined>(undefined)
const resetClickOnceCounts = ref(0) // For re-enable click.once when error occurs

// ==================================================
// Table Management
// ==================================================
const studentHeaders = [
  {
    name: 'Learner email',
    value: 'email'
  },
  {
    name: 'Actions',
    value: 'custom'
  }
]
const addStudentModalIsOpen = ref(false)
const studentPagination = reactive({ items_per_page: 15, page: 1, total: 1 })
const listView = ref('table')
const searchString = ref(EMPTY_STRING_SEARCH_ALL)

onBeforeMount(() => {
  searchStudentList()
})

const searchStudentList = (search = EMPTY_STRING_SEARCH_ALL) => {
  if (search === '') {
    search = EMPTY_STRING_SEARCH_ALL
  }
  searchString.value = search
  initLoading.value = true

  Api.Assignment.listCohortStudentsEndpoint(
    assignmentStore.editingCohort?.cohort_id,
    search,
    studentPagination.items_per_page,
    studentPagination.page
  )
    .then((res) => {
      studentPagination.total = res.pagination.total_pages || 1
      assignmentStore.editingCohort.students = res.students
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      initLoading.value = false
    })
}

const changePage = (page: number) => {
  studentPagination.page = page
  searchStudentList(searchString.value)
}

const addCohortStudent = () => {
  addStudentModalIsOpen.value = true

  // Clear array
  allStudentList.value = []
  selectedStudentList.value = []

  // Reset
  allStudentsCollapseOpen.value = true
  allStudentPagination.page = 1
  allStudentPagination.total = 1
  selectedStudentPagination.page = 1
  selectedStudentPagination.total = 1

  // Preload all students
  searchAllStudentsList()
}

const removeCohortStudent = (student: StudentSummary) => {
  deleteStudent.value = student
  deleteStudentModalStatus.value = true
}

const confirmDeleteStudent = () => {
  if (deleteStudent.value) {
    deleteStudentModalStatus.value = false
    initLoading.value = true

    Api.Assignment.removeStudentFromCohortEndpoint({
      cohort_id: assignmentStore.editingCohort.cohort_id,
      user_id: deleteStudent.value.user_id
    })
      .then(() => {
        // Remove last item on last page, move to previous page
        if (assignmentStore.editingCohort.students.length === 1 && studentPagination.page > 1) {
          studentPagination.page -= 1
        }

        // Reload student
        searchStudentList(searchString.value)
      })
      .catch((err: any) => {
        notificationStore.addNotification({
          subtitle: err?.body?.message,
          status: NotificationStatus.DANGER
        })
        initLoading.value = false
      })
  }
}

// ==================================================
// Add Students Model
// ==================================================
const addStudentHeaders = [
  {
    name: 'Learner email',
    value: 'email'
  },
  {
    name: 'Actions',
    value: 'custom'
  }
]
const allStudentsCollapseOpen = ref(true)
const allStudentsLoading = ref(false)
const allStudentSearchString = ref(EMPTY_STRING_SEARCH_ALL)
const addAllStudentsToCohortLoading = ref(false)

const collapseChange = () => {
  allStudentsCollapseOpen.value = !allStudentsCollapseOpen.value
}

const searchAllStudentsList = async (search = EMPTY_STRING_SEARCH_ALL) => {
  if (search === '') {
    search = EMPTY_STRING_SEARCH_ALL
  }
  allStudentsLoading.value = true

  allStudentSearchString.value = search
  Api.Assignment.listStudentsEndpoint(
    assignmentStore.editingCohort.cohort_id,
    assignmentStore.editingCohort.organization_id,
    search,
    allStudentPagination.items_per_page,
    allStudentPagination.page
  )
    .then((res) => {
      allStudentList.value = res.students
      allStudentPagination.total = res.pagination.total_pages || 1
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      allStudentsLoading.value = false
    })
}

const changeAllStudentPage = (page: number) => {
  allStudentPagination.page = page
  searchAllStudentsList(allStudentSearchString.value)
}

const addAllStudentsToCohort = async () => {
  addAllStudentsToCohortLoading.value = true

  try {
    // Load first 500 students
    let items_per_page = 500
    let currentPage = 1

    const res = await Api.Assignment.listStudentsEndpoint(
      assignmentStore.editingCohort.cohort_id,
      assignmentStore.editingCohort.organization_id,
      EMPTY_STRING_SEARCH_ALL,
      items_per_page,
      currentPage
    )

    selectedStudentList.value = res.students

    let totalPages = res.pagination.total_pages || 1

    // Load the rest students
    while (totalPages > currentPage) {
      currentPage += 1

      const response = await Api.Assignment.listStudentsEndpoint(
        assignmentStore.editingCohort.cohort_id,
        assignmentStore.editingCohort.organization_id,
        EMPTY_STRING_SEARCH_ALL,
        items_per_page,
        currentPage
      )

      selectedStudentList.value = selectedStudentList.value.concat(response.students)
    }
  } catch (err: any) {
    notificationStore.addNotification({
      subtitle: err?.body?.message,
      status: NotificationStatus.DANGER
    })
  } finally {
    addAllStudentsToCohortLoading.value = false
  }
}

/* ------------------------ Select learners ------------------------ */
const isStudentSelected = (student: StudentSummary) => {
  return selectedStudentList.value.find((s) => s.user_id === student.user_id)
}

const addSelectedStudent = (student: StudentSummary) => {
  selectedStudentList.value.push(student)
}

const removeSelectedStudent = (student: StudentSummary) => {
  const isLastItemOnLastPage =
    currentSelectedStudentList.value.length === 1 &&
    selectedStudentPagination.page > 1 &&
    selectedStudentPagination.page === selectedStudentPagination.total

  const index = selectedStudentList.value.findIndex((s) => s.user_id === student.user_id)
  selectedStudentList.value.splice(index, 1)

  if (isLastItemOnLastPage) {
    selectedStudentPagination.page -= 1
  }
}

// Display selected student list for current page
const currentSelectedStudentList = computed(() => {
  const start = (selectedStudentPagination.page - 1) * selectedStudentPagination.items_per_page
  const end = start + selectedStudentPagination.items_per_page
  return selectedStudentList.value.slice(start, end)
})

const changeSelectedStudentPage = (page: number) => {
  selectedStudentPagination.page = page
}

watch(
  selectedStudentList,
  (val) => {
    const total = Math.ceil(val.length / selectedStudentPagination.items_per_page)
    selectedStudentPagination.total = total > 0 ? total : 1
  },
  { deep: true }
)

/* ------------------------ Save & cancel ------------------------ */
const saveStudents = () => {
  if (selectedStudentList.value.length) {
    saveStudentsLoading.value = true

    Api.Assignment.addStudentsToCohortEndpoint({
      cohort_id: assignmentStore.editingCohort.cohort_id,
      students: selectedStudentList.value
    })
      .then(() => {
        // Reload student
        searchStudentList(searchString.value)
      })
      .catch((err: any) => {
        notificationStore.addNotification({
          subtitle: err?.body?.message,
          status: NotificationStatus.DANGER
        })
        resetClickOnceCounts.value += 1
      })
      .finally(() => {
        saveStudentsLoading.value = false
        addStudentModalIsOpen.value = false
      })
  } else {
    addStudentModalIsOpen.value = false
  }
}

const cancelSaveStudents = () => {
  addStudentModalIsOpen.value = false

  // Clear array
  allStudentList.value = []
  selectedStudentList.value = []
}

// ==================================================
// Export CSV
// ==================================================
const exportCSVLoading = ref(false)

// Number of processing items loaded each time for csv
const numberOfProcessingItems = 500

enum ExportCSVStatus {
  Success = 'success',
  Error = 'error'
}

const loadStudentsForExport = async (page: number, exportFilter: CohortStudentExportFilterApi) => {
  try {
    const res = await Api.Assignment.getCohortStudentsForCsvExportEndpoint(
      assignmentStore.editingCohort.cohort_id,
      exportFilter,
      numberOfProcessingItems,
      page
    )

    return { status: ExportCSVStatus.Success, data: res }
  } catch (err: any) {
    notificationStore.addNotification({
      title: 'CSV Export Error',
      subtitle: 'Error occurred during exporting CSV, please try again.',
      status: NotificationStatus.DANGER
    })

    exportCSVLoading.value = false
    return { status: ExportCSVStatus.Error, data: undefined }
  }
}

const downloadCSV = (name: string, data: string) => {
  const container = document.createElement('a')
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(blob)
  container.href = url
  container.setAttribute('download', `${name}.csv`)
  container.click()
}

const exportCSVAction = async (csvName: string, exportFilter: CohortStudentExportFilterApi) => {
  exportCSVLoading.value = true

  let exportFailed = false
  let csvData = 'Learner email'
  let currentPage = 1

  const result = await loadStudentsForExport(currentPage, exportFilter)

  if (result.status === ExportCSVStatus.Success && result.data) {
    const { data } = result
    const { total_pages } = data.pagination

    data.students.forEach((student) => {
      csvData += `,\r\n${student.email}`
    })

    while (total_pages > currentPage) {
      currentPage += 1
      const response = await loadStudentsForExport(currentPage, exportFilter)

      if (response.status === ExportCSVStatus.Success) {
        response.data?.students.forEach((student) => {
          csvData += `\n${student.email}`
        })
      } else {
        exportFailed = true
        break
      }
    }
  }

  exportCSVLoading.value = false

  if (exportFailed == false) {
    // Download csv
    downloadCSV(`${csvName}_${dayjs().tz(dayjs.tz.guess()).format('YYYYMMDDHHmmss')}`, csvData)
  }
}

const exportCSVOptions = ref<PopoverOption[]>([
  {
    name: 'All learners in cohort',
    action: () => exportCSVAction('all_learners', CohortStudentExportFilterApiEnum.ALL_STUDENTS)
  },
  {
    name: 'All with incomplete assignments',
    action: () =>
      exportCSVAction(
        'all_learners_with_incomplete_assignments',
        CohortStudentExportFilterApiEnum.STUDENTS_WITH_INCOMPLETE_ASSIGNMENT
      )
  },
  {
    name: 'All with overdue assignments',
    action: () =>
      exportCSVAction(
        'all_learners_with_overdue_assignments',
        CohortStudentExportFilterApiEnum.STUDENTS_WITH_OVERDUE_ASSIGNMENT
      )
  }
])
</script>

<template>
  <div class="h-full">
    <CustomList
      title="Learners"
      class="[&_.list-item-row]:cursor-default [&_.list-title]:text-xl"
      createButton="Add Learners"
      createButtonClass="admin-secondary"
      :createButtonStartIcon="UserPlusIcon"
      :pagination="studentPagination"
      :hasListOptions="false"
      :viewType="listView"
      :listHeaders="studentHeaders"
      :listData="assignmentStore.editingCohort.students"
      hasHeader
      :cellAdd="false"
      :loading="initLoading"
      @onListViewChange="(view: string) => (listView = view)"
      @onSearch="(search: string) => searchStudentList(search)"
      @onCreate="addCohortStudent"
      @onChangePage="(page: number) => changePage(page)"
    >
      <template #custom="{ data }: { data: any }">
        <div
          :key="`remove_student_${resetClickOnceCounts}`"
          class="flex w-full cursor-pointer select-none items-center gap-1.5"
          @click="removeCohortStudent(data)"
        >
          <UserMinusIcon class="h-5 text-sc-grey-400" />
          <div class="text-base">Remove from cohort</div>
        </div>
      </template>

      <template #headerItem>
        <CustomPopover
          class="[&_.popover-modal]:w-72 [&_.popover-position]:mt-2"
          buttonType="admin-secondary"
          :startIcon="ArrowDownOnSquareIcon"
          :popoverOptions="exportCSVOptions"
          :loading="exportCSVLoading"
        >
          Export .CSV
        </CustomPopover>
      </template>
    </CustomList>
    <CustomModal
      :modelValue="addStudentModalIsOpen"
      class="[&_.dialog-panel]:min-h-[90vh] [&_.dialog-panel]:w-[60vw]"
    >
      <div class="flex h-full flex-1 flex-col overflow-y-hidden">
        <div class="relative flex flex-none basis-auto flex-col">
          <h2 class="text-lg">Add Learners</h2>
        </div>
        <div class="flex h-full flex-1 flex-col overflow-y-auto">
          <!------------------------ All learners ------------------------>
          <CustomCollapse :isOpen="allStudentsCollapseOpen" @change="collapseChange">
            <template #title>
              <h3 class="flex h-[50px] min-h-[50px] items-center text-base">
                All learners
                <span class="pl-1 text-base text-sc-grey-400">({{ allStudentList.length }})</span>
              </h3>
            </template>
            <CustomList
              title="Learners"
              createButton="Add All Learners to Cohort"
              :class="[
                '[&_.list-header]:text-base [&_.list-option]:hidden [&_.list-search]:max-w-full [&_.list-title]:hidden',
                '[&_.list-header-0]:pl-3 [&_.list-header-1]:justify-end [&_.list-header-1]:pr-3 [&_.list-item-0]:px-3 [&_.list-item-1]:pr-3',
                '[&_.list-item-item]:py-2 [&_.list-item-row]:cursor-default'
              ]"
              viewType="table"
              :pagination="allStudentPagination"
              :listHeaders="addStudentHeaders"
              :listData="allStudentList"
              hasHeader
              :cellAdd="false"
              :loading="allStudentsLoading"
              :createButtonLoading="addAllStudentsToCohortLoading"
              @onListViewChange="(view: string) => (listView = view)"
              @onSearch="(search: string) => searchAllStudentsList(search)"
              @onCreate="addAllStudentsToCohort"
              @onChangePage="(page: number) => changeAllStudentPage(page)"
            >
              <template #custom="{ data }: { data: any }">
                <div
                  v-if="isStudentSelected(data)"
                  class="flex w-full cursor-pointer select-none items-center justify-end gap-1"
                  @click.once="removeSelectedStudent(data)"
                >
                  <UserMinusIcon class="h-5 text-sc-grey-400" />
                  <div class="text-base">Remove from list</div>
                </div>
                <div
                  v-else
                  class="flex w-full cursor-pointer select-none justify-end gap-1"
                  @click.once="addSelectedStudent(data)"
                >
                  <UserPlusIcon class="h-5 text-sc-grey-400" />
                  <div class="text-base">Add to cohort</div>
                </div>
              </template>
            </CustomList>
          </CustomCollapse>
          <!------------------------ Selected learners ------------------------>
          <CustomCollapse
            :isOpen="!allStudentsCollapseOpen"
            :class="{ 'mt-auto': allStudentsCollapseOpen }"
            @change="collapseChange"
          >
            <template #title>
              <h3 class="flex h-[50px] min-h-[50px] items-center text-base">
                Selected learners
                <span class="pl-1 text-base text-sc-grey-400"
                  >({{ selectedStudentList.length }})</span
                >
              </h3>
            </template>
            <CustomList
              title="Learners"
              :class="[
                '[&_.list-header]:text-base',
                '[&_.list-header-0]:pl-3 [&_.list-header-1]:justify-end [&_.list-header-1]:pr-3 [&_.list-item-0]:px-3 [&_.list-item-1]:pr-3',
                '[&_.list-item-item]:py-2 [&_.list-item-row]:cursor-default'
              ]"
              viewType="table"
              :pagination="selectedStudentPagination"
              :listHeaders="addStudentHeaders"
              :listData="currentSelectedStudentList"
              :hasHeader="false"
              :cellAdd="false"
              :loading="initLoading"
              @onListViewChange="(view: string) => (listView = view)"
              @onSearch="(search: string) => searchStudentList(search)"
              @onChangePage="(page: number) => changeSelectedStudentPage(page)"
            >
              <template #custom="{ data }: { data: any }">
                <div
                  :key="`remove_selected2_${resetClickOnceCounts}`"
                  class="flex w-full cursor-pointer select-none items-center justify-end gap-1"
                  @click.once="removeSelectedStudent(data)"
                >
                  <UserMinusIcon class="h-5 text-sc-grey-400" />
                  <div class="text-base">Remove from list</div>
                </div>
              </template>
              <template #empty-meta>
                <div>Add learners fom the “All learners” table above</div>
              </template>
            </CustomList>
          </CustomCollapse>
        </div>
      </div>
      <div class="flex flex-none justify-end gap-3">
        <CustomButton buttonType="admin-secondary" @click.once="cancelSaveStudents">
          Cancel
        </CustomButton>
        <CustomButton
          :key="`save_students_${resetClickOnceCounts}`"
          buttonType="admin-primary"
          :loading="saveStudentsLoading"
          @click.once="saveStudents"
        >
          Done
        </CustomButton>
      </div>
    </CustomModal>

    <!-- Delete modals -->
    <CustomDeleteModal
      :modalStatus="deleteStudentModalStatus"
      title="Delete Learner"
      message="Are you sure you want to delete this learner? This cannot be undone."
      @confirm="confirmDeleteStudent"
      @cancel="deleteStudentModalStatus = false"
    />
  </div>
</template>
