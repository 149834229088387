<script setup lang="ts">
import { ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import { TopLine } from '@/components/modern/page-navigation'
import { DataTable, DataTableServerPagination } from '@/components/modern/ui/data-table'
import {
  useColumns,
  type CharacterSummaryApi
} from '@/components/modern/tables/organization-characters'
import SearchFilter from '@/components/modern/ui/SearchFilter.vue'
import { Button } from '@/components/modern/ui/button'
import { PlusIcon } from '@radix-icons/vue'
import { injectUseListCharacters } from '@/composables/api/queries/useListCharacters'
import useDeleteCharacter from '@/composables/api/mutations/useDeleteCharacter'
import { CommandState } from '@/types/api'

definePage({
  name: 'Characters List',
  meta: {
    permissionLevel: 'Educator',
    requiresAuthoring: true,
    isModern: true
  }
})

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

const { query, characters, isLoading, pagination, refetch } = injectUseListCharacters({
  authStore,
  notificationStore
})

const characterId = ref<string>('')
const { state, execute, reset } = useDeleteCharacter({ characterId, notificationStore })
const requestDeleteCharacter = ({ character_id }: CharacterSummaryApi) => {
  characterId.value = character_id
}
const confirmDeleteCharacter = async (): Promise<boolean> =>
  execute()
    .then(() => state.value === CommandState.SUCCESS)
    .finally(reset)
    .finally(refetch)

const columns = useColumns({ requestDeleteCharacter, confirmDeleteCharacter })
</script>

<template>
  <TopLine>
    <template #left>
      <span> All Characters </span>
      <SearchFilter
        v-model="query"
        placeholder="Search by name, label, description"
        class="min-w-72"
      />
    </template>
    <template #right>
      <Button v-if="authStore.flagAuthoringEnabled" variant="default" size="xs" asChild>
        <RouterLink
          :to="{
            name: 'Character Config',
            params: { characterId: 'new' },
            query: { mode: 'create' }
          }"
        >
          <PlusIcon class="mr-2 size-4" />
          <span>Add Character</span>
        </RouterLink>
      </Button>
    </template>
  </TopLine>

  <DataTable ref="table" :data="characters" :columns :isLoading />
  <DataTableServerPagination v-bind="pagination" />
</template>
