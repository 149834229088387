<script setup lang="ts">
import { ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import { TopLine } from '@/components/modern/page-navigation'
import { DataTable, DataTableServerPagination } from '@/components/modern/ui/data-table'
import { useColumns } from '@/components/modern/tables/organization-rubrics'
import type { RubricSummary } from '@/open-api/generated'
import SearchFilter from '@/components/modern/ui/SearchFilter.vue'
import { Button } from '@/components/modern/ui/button'
import { PlusIcon } from '@radix-icons/vue'
import { injectUseListRubrics } from '@/composables/api/queries/useListRubrics'
import useDeleteRubric from '@/composables/api/mutations/useDeleteRubric'
import { CommandState } from '@/types/api'

definePage({
  name: 'Rubrics List',
  meta: {
    permissionLevel: 'Educator',
    requiresAuthoring: true,
    isModern: true
  }
})

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

const { query, rubrics, isLoading, pagination, refetch } = injectUseListRubrics({
  authStore,
  notificationStore
})

const rubricId = ref<string>('')
const { state, execute, reset } = useDeleteRubric({ rubricId, notificationStore })
const requestDeleteRubric = ({ rubric_id }: RubricSummary) => {
  rubricId.value = rubric_id
}
const confirmDeleteRubric = async (): Promise<boolean> =>
  execute()
    .then(() => state.value === CommandState.SUCCESS)
    .finally(reset)
    .finally(refetch)

const columns = useColumns({ requestDeleteRubric, confirmDeleteRubric })
</script>

<template>
  <TopLine>
    <template #left>
      <span> All Rubrics </span>
      <SearchFilter v-model="query" placeholder="Search by label or description" class="min-w-72" />
    </template>
    <template #right>
      <Button v-if="authStore.flagAuthoringEnabled" variant="default" size="xs" asChild>
        <RouterLink
          :to="{
            name: 'Rubric View',
            params: { rubricId: 'new' },
            query: { mode: 'create' }
          }"
        >
          <PlusIcon class="mr-2 size-4" />
          <span>Add Rubric</span>
        </RouterLink>
      </Button>
    </template>
  </TopLine>

  <DataTable :data="rubrics" :columns :isLoading />
  <DataTableServerPagination v-bind="pagination" />
</template>
