<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useNotificationStore } from '@/stores/notifications'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import { MultiTable } from '@/components/modern/ui/data-table'
import { useColumns, useGroups } from '@/components/modern/tables/cohort-activity-students'
import { Button } from '@/components/modern/ui/button'
import { PlayIcon, DownloadIcon } from '@radix-icons/vue'
import { type AssignmentInstanceStudentProgress } from '@/open-api/generated'
import useCombineQueries from '@/composables/api/useCombineQueries'
import { injectUseGetCohortV2 } from '@/composables/api/queries/useGetCohortV2'
import { injectUseGetCohortStudentsProgress } from '@/composables/api/queries/useGetCohortStudentsProgress'
import { injectUseGetCohortAssignmentInsights } from '@/composables/api/queries/useGetCohortAssignmentInsights'
import { injectUseGetAssignmentInstanceStudentInsights } from '@/composables/api/queries/useGetAssignmentInstanceStudentInsights'
import useResetLearnerProgress from '@/composables/api/mutations/useResetLearnerProgress'
// import useExemptStudentFromActivity from '@/composables/api/mutations/useExemptStudentFromActivity'
import { exportInsights, extractInsights } from '@/utils/export'
import { findUserId } from '@/utils/preview-mock-instance'

definePage({
  name: 'Modern Cohorts - Activity Instance',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const route = useRoute('Modern Cohorts - Activity Instance')
const cohortId = computed(() => route.params.cohortId)
const assignmentInstanceId = computed(() => route.params.assignmentInstanceId)

const notificationStore = useNotificationStore()

const { track, isLoading, refetch } = useCombineQueries()
const { cohort } = track(injectUseGetCohortV2({ cohortId, notificationStore }))
const { activityInstances } = track(
  injectUseGetCohortAssignmentInsights({ cohortId, notificationStore })
)
const { insights } = track(
  injectUseGetAssignmentInstanceStudentInsights({ assignmentInstanceId, notificationStore })
)
track(injectUseGetCohortStudentsProgress({ cohortId, notificationStore }))

const instance = computed(
  () =>
    activityInstances.value.find((a) => a.assignment_instance_id === assignmentInstanceId.value) ??
    null
)

// You can only preview a cohort's assignment instance if it has at least one student in it.
const notifyNeedStudents = () => {
  notificationStore.addWARNING('You must add at least one learner to a cohort to view the preview.')
}
const hasStudents = computed(() => findUserId(insights))
const tasksTotal = computed(() => insights.value?.tasks_total ?? 0)

const exportCSV = async () => {
  if (cohort.value && instance.value && insights.value) {
    try {
      exportInsights(extractInsights(cohort.value, instance.value, insights.value))
    } catch (err: any) {
      notificationStore.addDANGER(err?.body?.message || 'Error exporting CSV')
    }
  }
}

const userId = ref<string>('')
const resetLearnerProgress = useResetLearnerProgress({
  assignmentInstanceId,
  userId,
  notificationStore
})
const requestResetLearnerProgress = ({ user }: AssignmentInstanceStudentProgress) =>
  (userId.value = user.id)
const confirmResetLearnerProgress = () => {
  const { execute, reset } = resetLearnerProgress
  return execute().finally(reset).finally(refetch)
}

const data = computed(() => insights.value?.status.flatMap((status) => status.payload) ?? [])
const columns = useColumns({
  cohortId,
  assignmentInstanceId,
  tasksTotal,
  requestResetLearnerProgress,
  confirmResetLearnerProgress
})
const groups = useGroups(insights)
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton
        :to="{ name: 'Modern Cohorts - Cohort Activities List', params: { cohortId } }"
        name="cohort"
      />
    </template>
    <template v-if="!isLoading" #right>
      <Button v-if="hasStudents" variant="outline" size="xs" asChild>
        <RouterLink
          :to="{
            name: 'Modern Cohorts - Preview Activity Instance',
            params: { cohortId, assignmentInstanceId }
          }"
        >
          <PlayIcon class="mr-2 size-4" />
          Preview Activity
        </RouterLink>
      </Button>
      <Button v-else variant="outline" size="xs" @click="notifyNeedStudents">
        <PlayIcon class="mr-2 size-4" />
        Preview Activity
      </Button>
      <Button variant="default" size="xs" :disabled="isLoading" @click="exportCSV">
        <DownloadIcon class="mr-2 size-4" />
        <span>Export</span>
      </Button>
    </template>
  </TopLine>
  <MultiTable :data :columns :groups :isLoading />
</template>
