<script setup lang="ts">
import { computed, provide } from 'vue'
import { useRoute } from 'vue-router'
import { Breadcrumb } from '@/components/modern/page-navigation'
import { useNotificationStore } from '@/stores/notifications'
import useGetMyAssignmentInstance, {
  useGetMyAssignmentInstanceKey
} from '@/composables/api/queries/useGetMyAssignmentInstance'

definePage({
  name: 'Learner Activity Provider',
  redirect: 'Modern - Learner Activity View',
  meta: {
    permissionLevel: 'Student',
    isModern: true
  }
})

const route = useRoute('Learner Activity Provider')
const activityId = computed(() => route.params.activityId)

const notificationStore = useNotificationStore()
const getMyAssignmentInstance = useGetMyAssignmentInstance({
  assignmentInstanceId: activityId,
  notificationStore
})

provide(useGetMyAssignmentInstanceKey, getMyAssignmentInstance)

const { assignmentInstance } = getMyAssignmentInstance
</script>

<template>
  <Breadcrumb
    :route="{ name: 'Modern - Learner Activity View', params: { activityId } }"
    :name="assignmentInstance?.name"
  />
  <RouterView />
</template>
