<script setup lang="ts">
import { provide } from 'vue'
import { useNotificationStore } from '@/stores/notifications'
import useGetMyAssignmentInstances, {
  useGetMyAssignmentInstancesKey
} from '@/composables/api/queries/useGetMyAssignmentInstances'
import { Breadcrumb } from '@/components/modern/page-navigation'

definePage({
  name: 'My Activities Provider',
  redirect: 'Modern - My Activities',
  meta: {
    permissionLevel: 'Student',
    isModern: true
  }
})

const notificationStore = useNotificationStore()

const getMyAssignmentInstances = useGetMyAssignmentInstances({ notificationStore })
provide(useGetMyAssignmentInstancesKey, getMyAssignmentInstances)
</script>

<template>
  <Breadcrumb :route="{ name: 'Modern - My Activities' }" name="My Activities" />
  <RouterView />
</template>
