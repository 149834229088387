<script setup lang="ts">
import type {
  OrganizationIdAndName,
  SectionTemplateOutput,
  SectionTemplateUpdate
} from '@/open-api/generated'
import { ref, reactive, computed, onBeforeMount } from 'vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import Api from '@/open-api'
import DraggableTemplate from '@/components/utils/DraggableTemplate.vue'
import { useAuthStore } from '@/stores/auth'
import { useContentStore } from '@/stores/content'
import { useNotificationStore } from '@/stores/notifications'
import CustomInput from '@/components/utils/CustomInput.vue'
import { NotificationStatus } from '@/types/notification'
import { PlusIcon } from '@heroicons/vue/24/outline'
import { deepCopy } from '@/lib/utils'

import { EMPTY_STRING_SEARCH_ALL } from '@/constants/server'
import { IS_PRODUCTION } from '@/constants/environment'
import useSyncSectionTemplateToRegions from '@/composables/api/mutations/useSyncSectionTemplateToRegions'
import { CommandState } from '@/types/api'
import { Breadcrumb } from '@/components/modern/page-navigation'

definePage({
  name: 'Section Access Controls',
  meta: {
    permissionLevel: 'InstitutionAdmin',
    requiresAuthoring: true
  }
})

// ==================================================
// Init
// ==================================================
const authStore = useAuthStore()
const notificationStore = useNotificationStore()
const contentStore = useContentStore()
const tableDraggingId = ref('')

// ==================================================
// Organizations
// ==================================================
const currentEditingSection: Partial<SectionTemplateOutput> = reactive({})
const sectionTemplateId = computed<string>(() => currentEditingSection.section_template_id ?? '')

onBeforeMount(() => {
  Object.assign(currentEditingSection, deepCopy(contentStore.editingSection))
})

const cantSave = computed(() => {
  const storeJSON = JSON.stringify(contentStore.editingSection.allowlist)
  const currentJSON = JSON.stringify(currentEditingSection.allowlist)

  return storeJSON === currentJSON
})

const saveLoading = ref(false)

const changeOrganization = ($event: any) => {
  if ($event.added && currentEditingSection.allowlist) {
    currentEditingSection.allowlist.push($event.added.element)
  }
}

const saveOrganizations = () => {
  saveLoading.value = true

  const allowListUpdate: string[] =
    currentEditingSection.allowlist?.map((org) => org.organization_id) || []

  const update = { ...contentStore.editingSection }

  update.allowlist = allowListUpdate
  ;(update as SectionTemplateUpdate).owning_organization_id =
    currentEditingSection.owning_organization_id

  Api.Content.updateSectionTemplateEndpoint(update as SectionTemplateUpdate)
    .then((res) => {
      contentStore.setEditingSection(res)
      Object.assign(currentEditingSection, res)

      notificationStore.addNotification({
        subtitle: 'Section organizations access successfully saved',
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      saveLoading.value = false
    })
}

const deleteOrganization = (index: number) => {
  if (!currentEditingSection.allowlist) {
    return
  }
  currentEditingSection.allowlist.splice(index, 1)
}

// ==================================================
// Organizations Library
// ==================================================
const organizationShown = ref(false)
const organizationLibraryLoading = ref(false)
let organizationsLibrary = ref<OrganizationIdAndName[]>([])

onBeforeMount(() => {
  setOrganizationsList()
})

const setOrganizationsList = async (search = EMPTY_STRING_SEARCH_ALL) => {
  if (search === '') {
    search = EMPTY_STRING_SEARCH_ALL
  }
  organizationLibraryLoading.value = true
  try {
    const orgs = await Api.Management.listAllOrganizationsEndpoint(search)
    organizationsLibrary.value = orgs.organizations
  } catch (err: any) {
    notificationStore.addNotification({
      subtitle: err?.body?.message,
      status: NotificationStatus.DANGER
    })
  } finally {
    organizationLibraryLoading.value = false
  }
}

const shownLibraryOrganizations = computed(() => {
  const uniqueValues = new Set()
  const result: any[] = []

  if (!currentEditingSection?.allowlist?.length) {
    return organizationsLibrary.value
  }

  const combinedArray = [...organizationsLibrary.value, ...currentEditingSection.allowlist]

  combinedArray.forEach((item) => {
    if (!uniqueValues.has(item?.organization_id)) {
      uniqueValues.add(item.organization_id)
      result.push(item)
    } else {
      result.forEach((existingItem, index) => {
        if (existingItem?.organization_id === item.organization_id) {
          result.splice(index, 1)
        }
      })
    }
  })

  return result
})

// ==================================================
// Global content.
// ==================================================
const syncSectionTemplateToRegions = useSyncSectionTemplateToRegions({
  sectionTemplateId,
  notificationStore,
  authStore
})
</script>

<template>
  <Breadcrumb :route="{ name: 'Section Access Controls' }" name="Access Control" />
  <div class="flex h-full flex-col gap-10 gap-y-5">
    <div class="flex flex-col gap-5">
      <h2 class="text-xl">Access control</h2>

      <div v-if="authStore.isAtLeastStaffUser" class="flex flex-col gap-5">
        <CustomInput
          v-model="currentEditingSection.owning_organization_id"
          class="!mb-0 !w-[300px]"
          inputType="select"
          placeholder="Select an organization"
          label="Owner Organization"
          :options="
            organizationsLibrary.map((org) => {
              return {
                name: org.name,
                value: org.organization_id
              }
            })
          "
        />
        <CustomButton
          buttonType="admin-primary"
          :loading="saveLoading"
          :disabled="saveLoading"
          @click="saveOrganizations"
        >
          Save Changes
        </CustomButton>
      </div>

      <h3 class="text-lg">Organizations</h3>
      <p v-if="!currentEditingSection?.allowlist?.length" class="text-sc-grey-600">
        You have no organizations added to this section
      </p>
      <DraggableTemplate
        class="w-[400px]"
        uniqueId="selected_organizations"
        :libraryView="false"
        :otherTableDragging="tableDraggingId"
        canDelete
        :draggableItems="currentEditingSection.allowlist || []"
        @dragging="(draggingId) => (tableDraggingId = draggingId)"
        @onDelete="deleteOrganization"
        @changeDraggableItems="changeOrganization"
      />
      <div class="flex gap-3">
        <CustomButton
          buttonType="admin-secondary"
          :startIcon="PlusIcon"
          @click="organizationShown = true"
        >
          Add Organization
        </CustomButton>
        <CustomButton
          buttonType="admin-primary"
          :loading="saveLoading"
          :disabled="cantSave"
          @click="saveOrganizations"
        >
          Save Changes
        </CustomButton>
      </div>
    </div>
    <div v-if="organizationShown" class="w-[400px]">
      <DraggableTemplate
        title="Organizations"
        uniqueId="organizations_library"
        :otherTableDragging="tableDraggingId"
        :loading="organizationLibraryLoading"
        :canAdd="false"
        :draggableItems="shownLibraryOrganizations"
        @onSearch="(search: string) => setOrganizationsList(search)"
        @onClose="organizationShown = false"
        @dragging="(draggingId) => (tableDraggingId = draggingId)"
      />
    </div>

    <div class="flex flex-col gap-5">
      <hr />

      <div v-if="authStore.isSuperAdminUser && IS_PRODUCTION" class="flex flex-col gap-5">
        <h2 class="text-xl">Global Content</h2>
        <p>
          <strong>Note:</strong> this feature is available in production and for SuperAdmin users
          only.
        </p>

        <CustomButton
          :loading="syncSectionTemplateToRegions.state.value === CommandState.IN_PROGRESS"
          @click="syncSectionTemplateToRegions.execute"
          >Synchronize to other regions</CustomButton
        >
      </div>
    </div>
  </div>
</template>
