<script setup lang="ts">
import ConversationTaskForm from '@/components/assignment-task-forms/ConversationTaskForm.vue'
import FeedbackTaskForm from '@/components/assignment-task-forms/FeedbackTaskForm.vue'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import { useAssignmentStore } from '@/stores/assignment'
import { TaskTypeApiEnum, ReflectionTaskResponseTypeApiEnum, type Task } from '@/open-api/generated'
import type {
  ConversationTaskMeta,
  FeedbackTaskMeta,
  MyPreLearningTaskMeta,
  UpdateReflectionTaskMeta
} from '@/types/subtypes'
import { computed, defineAsyncComponent, reactive, ref } from 'vue'
import type { SelectOption } from '@/components/utils/CustomInput.vue'
import Api from '@/open-api'
import { useNotificationStore } from '@/stores/notifications'
import { NotificationStatus } from '@/types/notification'
import { onBeforeMount } from 'vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import { sanitizeHtml } from '@/utils'
import PreLearningTaskForm from '@/components/assignment-task-forms/PreLearningTaskForm.vue'
import ReflectionTaskForm from '@/components/assignment-task-forms/ReflectionTaskForm.vue'
import { PlusIcon } from '@heroicons/vue/24/outline'
import { nextTick } from 'vue'
import { deepCopy } from '@/lib/utils'
import { onBeforeRouteLeave } from 'vue-router'

// VR-related imports
import { isVrTaskPredicate } from '@/utils/vr'
import { EMPTY_STRING_SEARCH_ALL } from '@/constants/server'
import {
  focusLatestRichTextInput,
  focusLatestCustomInputText
} from '@/components/rich-text/focus-latest-rich-text-input'
definePage({
  name: 'Cohort Assignment Task Details',
  meta: {
    permissionLevel: 'Educator'
  }
})

const VRTaskForm = defineAsyncComponent({
  loader: () => import('@/vr/components/VRTaskForm.vue'),
  loadingComponent: AppLoadingSpinner
})

const assignmentStore = useAssignmentStore()
const notificationStore = useNotificationStore()

// VR Task
// isVrTaskPredicate is a VR-free safe way to check a task for VR features
const isVrTask = computed(() => isVrTaskPredicate(assignmentStore.currentCohortTask))

// ==================================================
// Task
// ==================================================

onBeforeRouteLeave((to, from, next) => {
  if (!canSaveTask.value) {
    const answer = window.confirm(
      'Warning: You have unsaved changes. \nNavigating away from this page will delete any unsaved changes.'
    )
    if (answer) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
})

const task = reactive({
  currentEditing: deepCopy(assignmentStore.currentCohortTask) as Task,
  saveLoading: false
})
const initLoading = ref(false)

onBeforeMount(async () => {
  if (
    assignmentStore.currentCohortTask?.task_type === TaskTypeApiEnum.CONVERSATION_TASK &&
    !isVrTask.value
  ) {
    initLoading.value = true
    await searchRubrics()
    await searchCharacters()
    initLoading.value = false
  }
})

const saveTask = () => {
  task.saveLoading = true

  task.currentEditing.description = sanitizeHtml(task.currentEditing.description)

  Api.Assignment.updateTaskEndpoint(task.currentEditing)
    .then((res) => {
      task.currentEditing = {
        task_id: task.currentEditing.task_id,
        ...res.task
      }

      assignmentStore.setCurrentCohortTask(deepCopy(task.currentEditing))
      notificationStore.addNotification({
        subtitle: 'Task successfully saved',
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      task.saveLoading = false
    })
}

// ==================================================
// Rubrics
// ==================================================
const rubric = reactive({
  options: [] as SelectOption[],
  search: false,
  searchLoading: false
})

const searchRubrics = async (searchString = EMPTY_STRING_SEARCH_ALL) => {
  if (searchString === '') {
    searchString = EMPTY_STRING_SEARCH_ALL
  }
  rubric.searchLoading = true
  Api.Content.listRubricsEndpoint(searchString, 15, 1)
    .then(async (res) => {
      rubric.options = res.rubrics.map((c) => {
        return {
          name: c.internal_label,
          value: c.rubric_id
        }
      })

      const taskRubric = (assignmentStore.currentCohortTask?.meta as ConversationTaskMeta)
        .ConversationTask.rubric_id

      if (taskRubric) {
        const foundCurrentRubric = rubric.options.find((option) => option.value === taskRubric)
        if (!foundCurrentRubric) {
          const retrievedRubric = await Api.Content.getRubricEndpoint(taskRubric)

          if (retrievedRubric) {
            rubric.options.push({
              name: retrievedRubric.internal_label,
              value: retrievedRubric.rubric_id
            })
          }
        }
      }
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      rubric.searchLoading = false
    })
}

// ==================================================
// Characters
// ==================================================
const character = reactive({
  options: [] as SelectOption[],
  search: false,
  searchLoading: false
})

const searchCharacters = (searchString = EMPTY_STRING_SEARCH_ALL) => {
  if (searchString === '') {
    searchString = EMPTY_STRING_SEARCH_ALL
  }
  character.searchLoading = true
  Api.Content.listCharactersEndpoint(searchString, 15, 1)
    .then(async (res) => {
      character.options = res.characters.map((c) => {
        return {
          name: c.internal_label,
          value: c.character_id
        }
      })

      const taskCharacter = (assignmentStore.currentCohortTask?.meta as ConversationTaskMeta)
        .ConversationTask.character_id

      if (taskCharacter) {
        const foundCurrentCharacter = character.options.find(
          (option) => option.value === taskCharacter
        )
        if (!foundCurrentCharacter) {
          const retrievedCharacter = await Api.Content.getCharacterEndpoint(taskCharacter)

          if (retrievedCharacter) {
            character.options.push({
              name: retrievedCharacter.internal_label,
              value: retrievedCharacter.character_id
            })
          }
        }
      }
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      character.searchLoading = false
    })
}

// task validation
const canSaveTask = computed(() => {
  const JSONStore = JSON.stringify(assignmentStore.currentCohortTask)
  const JSONComponent = JSON.stringify(task.currentEditing)

  return validateTasks([task.currentEditing as Task]).length || JSONStore === JSONComponent
})

const validateTypeformLink = (link: string): boolean =>
  /^(https?):\/\/.+\.typeform\.com\/to\/([0-9a-zA-Z]*)([$?].+)?/.test(link)

const validateTasks = (tasks: Task[]): { index: number; fieldName: string; error: string }[] => {
  if (!tasks.length) {
    return []
  }
  let taskNames: string[] = []
  return tasks.reduce(
    (acc, item, index) => {
      if (!item.name.trim()) {
        acc.push({ index, fieldName: 'name', error: 'Missing value' })
      } else {
        // Validate duplicate task name
        if (taskNames.includes(item.name)) {
          acc.push({ index, fieldName: 'name', error: 'Duplicate task name' })
        } else {
          taskNames.push(item.name)
        }
      }
      if (item.task_type !== TaskTypeApiEnum.PRE_LEARNING_TASK && !item.description.trim()) {
        acc.push({ index, fieldName: 'description', error: 'Missing value' })
      }
      if (item.mandatory === undefined) {
        acc.push({ index, fieldName: 'mandatory', error: 'Missing value' })
      }

      if (item.task_type === TaskTypeApiEnum.CONVERSATION_TASK) {
        const { max_attempts, character_id, rubric_id, scene_id } = (
          item.meta as ConversationTaskMeta
        ).ConversationTask
        if (!max_attempts) {
          acc.push({ index, fieldName: 'max_attempts', error: 'Missing value' })
        } else if (max_attempts <= 0) {
          acc.push({ index, fieldName: 'max_attempts', error: 'Invalid value' })
        }

        if (!character_id) {
          acc.push({ index, fieldName: 'character_id', error: 'Missing value' })
        }
        if (!rubric_id) {
          acc.push({ index, fieldName: 'rubric_id', error: 'Missing value' })
        }

        if (isVrTask.value && !scene_id) {
          acc.push({ index, fieldName: 'scene_id', error: 'Missing value' })
        }
      } else if (item.task_type === TaskTypeApiEnum.FEEDBACK_TASK) {
        const { retryable, typeform_link } = (item.meta as FeedbackTaskMeta).FeedbackTask
        if (retryable == undefined) {
          acc.push({ index, fieldName: 'retryable', error: 'Missing value' })
        }

        if (!typeform_link) {
          acc.push({ index, fieldName: 'typeform_link', error: 'Missing value' })
        } else if (validateTypeformLink(typeform_link) === false) {
          acc.push({ index, fieldName: 'typeform_link', error: 'Invalid value' })
        }
      } else if (item.task_type === TaskTypeApiEnum.PRE_LEARNING_TASK) {
        const emptySection = (item.meta as MyPreLearningTaskMeta).PreLearningTask.sections.filter(
          (section) => section.trim() === ''
        )

        if (emptySection.length) {
          acc.push({ index, fieldName: 'Section', error: 'Missing value' })
        }
      } else if (item.task_type === TaskTypeApiEnum.REFLECTION_TASK) {
        const emptyQuestion = (
          item.meta as UpdateReflectionTaskMeta
        ).ReflectionTask.questions.filter(
          (q) => q.question.trim() === '' || q.response_type === undefined
        )
        if (emptyQuestion.length) {
          acc.push({ index, fieldName: 'Question', error: 'Missing value' })
        }
      }

      return acc
    },
    [] as { index: number; fieldName: string; error: string }[]
  )
}

// ==================================================
// prelearning task
// ==================================================

const addSection = () => {
  ;(task.currentEditing.meta as MyPreLearningTaskMeta).PreLearningTask.sections.push('')

  const scrollingElement = document.getElementById('main-container')
  if (scrollingElement) {
    nextTick(() => {
      scrollingElement.scrollTop = scrollingElement.scrollHeight
    })
  }
  focusLatestRichTextInput()
}

// ==================================================
// Reflection task
// ==================================================

const addQuestion = () => {
  ;(task.currentEditing.meta as UpdateReflectionTaskMeta).ReflectionTask.questions.push({
    question: '',
    response_type: ReflectionTaskResponseTypeApiEnum.LONG_TEXT,
    position: (task.currentEditing.meta as UpdateReflectionTaskMeta).ReflectionTask.questions.length
  })

  const scrollingElement = document.getElementById('main-container')
  if (scrollingElement) {
    nextTick(() => {
      scrollingElement.scrollTop = scrollingElement.scrollHeight
    })
  }
  focusLatestCustomInputText()
}
</script>

<template>
  <div class="flex flex-col gap-5">
    <template v-if="!initLoading">
      <div class="w-[80%]">
        <VRTaskForm v-if="isVrTask" :task="task.currentEditing" />
        <ConversationTaskForm
          v-else-if="
            assignmentStore.currentCohortTask?.task_type === TaskTypeApiEnum.CONVERSATION_TASK
          "
          :task="task.currentEditing"
          :rubricOptions="rubric.options"
          :characterOptions="character.options"
          :characterSearchLoading="character.searchLoading"
          :rubricSearchLoading="rubric.searchLoading"
          @rubricSearch="searchRubrics"
          @characterSearch="searchCharacters"
        />
        <FeedbackTaskForm
          v-else-if="assignmentStore.currentCohortTask?.task_type === TaskTypeApiEnum.FEEDBACK_TASK"
          :task="task.currentEditing"
        />
        <PreLearningTaskForm
          v-else-if="
            assignmentStore.currentCohortTask?.task_type === TaskTypeApiEnum.PRE_LEARNING_TASK
          "
          :task="task.currentEditing"
        />
        <ReflectionTaskForm
          v-else-if="
            assignmentStore.currentCohortTask?.task_type === TaskTypeApiEnum.REFLECTION_TASK
          "
          :task="task.currentEditing"
          :showTemplate="false"
        />
      </div>
      <div class="flex w-full gap-5 bg-white pb-3 pt-5">
        <CustomButton
          v-if="assignmentStore.currentCohortTask?.task_type === TaskTypeApiEnum.PRE_LEARNING_TASK"
          :disabled="task.saveLoading"
          buttonType="admin-secondary"
          :startIcon="PlusIcon"
          @click="addSection"
        >
          Add Section
        </CustomButton>
        <CustomButton
          v-if="assignmentStore.currentCohortTask?.task_type === TaskTypeApiEnum.REFLECTION_TASK"
          :disabled="task.saveLoading"
          buttonType="admin-secondary"
          :startIcon="PlusIcon"
          @click="addQuestion"
        >
          Add Question
        </CustomButton>
        <CustomButton :disabled="!!canSaveTask" @click="saveTask"> Save Changes </CustomButton>
      </div>
    </template>
    <AppLoadingSpinner v-else class="py-20" loading />
  </div>
</template>
