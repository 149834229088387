<script setup lang="ts">
import { onBeforeMount, ref, computed } from 'vue'
import { ArrowDownIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import Api from '@/open-api'
import { useAssignmentStore } from '@/stores/assignment'
import CustomTabs, { type TabOption } from '@/components/utils/CustomTabs.vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'

definePage({
  name: 'Cohort',
  meta: {
    permissionLevel: 'Educator'
  }
})

// ==================================================
// Init
// ==================================================
const route = useRoute('Cohort')
const router = useRouter()
const assignmentStore = useAssignmentStore()
const notificationStore = useNotificationStore()
const cohortLoading = ref(false)
const saveLoading = ref(false)

onBeforeMount(async () => {
  if (route.params.cohortId === 'new') {
    /* ----------- New cohort ----------- */
    assignmentStore.updateEditingCohort({ cohort_id: 'new' })
  } else {
    /* ----------- Edit existing cohort ----------- */
    await loadCohortDetails()
  }
})

const loadCohortDetails = async () => {
  cohortLoading.value = true

  try {
    const cohortResponse = await Api.Assignment.getCohortEndpoint(route.params.cohortId as string)
    if (!cohortResponse) {
      router.push({ name: 'Cohorts' })
    }
    assignmentStore.updateEditingCohort(cohortResponse)
  } catch (err: any) {
    notificationStore.addNotification({
      subtitle: err?.body?.message,
      status: NotificationStatus.DANGER
    })
  } finally {
    cohortLoading.value = false
  }
}

onBeforeRouteLeave((to, from, next) => {
  if (
    route.params.cohortId === 'new' &&
    !(to.name as string).includes('Cohort') &&
    cohortComplete.value
  ) {
    const answer = window.confirm(
      'Warning: You have unsaved changes. \nNavigating away from this page will delete any unsaved changes.'
    )
    if (answer) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
})

// ==================================================
// Cohort Nav
// ==================================================
const tabOptions = computed((): TabOption[] => {
  return [
    {
      displayName: 'Assignments',
      routeName: 'Cohort Assignments'
    },
    {
      displayName: 'Learners',
      routeName: 'Cohort Learners'
    },
    {
      displayName: 'Details',
      routeName: 'Cohort Details'
    }
  ]
})

// ==================================================
//  Create Cohort
// ==================================================
const cohortComplete = computed(() => {
  const { name, description, is_default, visible } = assignmentStore.editingCohort

  return !name.trim() || !description.trim() || is_default === undefined || visible === undefined
})

const createCohort = async () => {
  saveLoading.value = true

  await Api.Assignment.createCohortEndpoint(assignmentStore.editingCohort)
    .then(async (res) => {
      // Load cohort after push
      router
        .push({
          name: 'Cohort Assignments',
          params: { cohortId: res.cohort_id }
        })
        .then(async () => {
          await loadCohortDetails()
        })

      notificationStore.addNotification({
        subtitle: 'Cohort successfully created',
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      saveLoading.value = false
    })
}

const deferRouterViewToAssignments = computed(() =>
  route.matched.map((r) => r.path).includes('/cohort/:cohortId/assignment')
)
</script>

<template>
  <RouterView v-if="deferRouterViewToAssignments" />
  <template v-else>
    <div :class="['flex flex-col gap-y-5']">
      <div class="sticky -top-3 z-50 flex flex-col bg-white pt-3">
        <div
          class="flex cursor-pointer items-center gap-2 px-5 text-sc-grey-700"
          @click="router.push({ name: 'Cohorts' })"
        >
          <ChevronLeftIcon class="h-4 w-4" />
          <h4 class="font-normal text-sc-grey-700">Back to Cohorts</h4>
        </div>
        <div
          :class="['mt-5 flex justify-between px-5', { 'mb-5': route.params.cohortId !== 'new' }]"
        >
          <h1 class="whitespace-nowrap text-2.5xl">
            {{
              route.params?.cohortId === 'new'
                ? 'Create Cohort'
                : `${assignmentStore.editingCohort.name}`
            }}
          </h1>

          <div class="flex gap-3">
            <template v-if="route.params.cohortId === 'new'">
              <CustomButton
                :disabled="cohortComplete"
                :loading="saveLoading"
                buttonType="admin-primary"
                :startIcon="ArrowDownIcon"
                @click="createCohort"
              >
                Create Cohort
              </CustomButton>
            </template>
          </div>
        </div>
        <CustomTabs v-if="route.params.cohortId !== 'new' && !cohortLoading" :tabOptions />
      </div>

      <div v-if="cohortLoading" class="flex h-full w-full items-center justify-center">
        <AppLoadingSpinner class="py-20" :loading="cohortLoading" />
      </div>
      <RouterView v-else class="px-5" />
    </div>
  </template>
</template>
