<script setup lang="ts">
import { computed } from 'vue'
import { useNotificationStore } from '@/stores/notifications'
import TheStatusNotification from '@/components/AppNotification.vue'
import type { Notification } from '@/types/notification'

// ==================================================
// Init
// ==================================================
const notificationStore = useNotificationStore()
const animationDuration = 200
const statusNotifications = computed((): Notification[] => notificationStore.notifications)
</script>

<template>
  <Teleport to="body">
    <div
      aria-live="assertive"
      class="pointer-events-none fixed inset-0 z-[100] flex items-start p-6 lg:items-end"
    >
      <div class="mt-20 flex w-full flex-col items-center space-y-4 lg:mt-10 lg:items-end">
        <TransitionGroup
          :enterActiveClass="'transform ease-out duration-' + animationDuration + '-group'"
          enterFromClass="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterToClass="translate-y-0 opacity-100 sm:translate-x-0"
          :leaveActiveClass="'transition ease-in duration-' + animationDuration"
          leaveFromClass="opacity-100"
          leaveToClass="opacity-0"
        >
          <TheStatusNotification
            v-for="(notification, key) in statusNotifications"
            :key
            :notification
          />
        </TransitionGroup>
      </div>
    </div>
  </Teleport>
</template>
