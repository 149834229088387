<script setup lang="ts">
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import useCombineQueries from '@/composables/api/useCombineQueries'
import { injectUseGetVoice } from '@/composables/api/queries/useGetVoice'
import { injectUseListVoices } from '@/composables/api/queries/useListVoices'
import useUpdateVoice from '@/composables/api/mutations/useUpdateVoice'
import { schema, type Schema } from '@/composables/api/mutations/schema/useUpdateVoice'
import usePreviewVoice from '@/composables/api/usePreviewVoice'
import useSyncVoiceToRegions from '@/composables/api/mutations/useSyncVoiceToRegions'
import { CommandState } from '@/types/api'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { AutoForm } from '@/components/modern/ui/auto-form'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import { Button } from '@/components/modern/ui/button'
import { Separator } from '@/components/modern/ui/separator'
import { PlusIcon, CopyIcon } from '@radix-icons/vue'
import { EyeOpenIcon, PlayIcon } from '@radix-icons/vue'
import { IS_PRODUCTION } from '@/constants/environment'

definePage({
  name: 'Edit Voice',
  meta: {
    permissionLevel: 'SuperAdmin',
    isModern: true
  }
})

const route = useRoute('Edit Voice')
const voiceId = computed(() => route.params.voiceId)

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

const { track, refetch } = useCombineQueries()
const { voice } = track(injectUseGetVoice({ voiceId, notificationStore }))
track(injectUseListVoices({ notificationStore, authStore }))

const { state, execute, reset } = useUpdateVoice({
  voiceId,
  notificationStore
})

const { previewVoice } = usePreviewVoice()

const form = useForm({ validationSchema: toTypedSchema(schema) })

const submit = form.handleSubmit(async (values: Schema) =>
  execute(values).then(refetch).finally(reset)
)

watch(voice, () => {
  form.resetForm({
    values: {
      name: voice.value?.name,
      description: voice.value?.description,
      is_default: voice.value?.is_default
    }
  })
})

// Global Content.
const syncVoiceToRegions = useSyncVoiceToRegions({
  voiceId,
  notificationStore,
  authStore
})
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton :to="{ name: 'Voices List' }" name="all voices" />
    </template>
    <template #right>
      <Button
        v-if="authStore.isSuperAdminUser && IS_PRODUCTION"
        :disabled="syncVoiceToRegions.state.value === CommandState.IN_PROGRESS"
        variant="outline"
        size="xs"
        @click="syncVoiceToRegions.execute"
      >
        <CopyIcon class="mr-2 size-4" />
        <span>Sync to Regions</span>
      </Button>
      <Button variant="outline" size="xs" asChild>
        <RouterLink
          :to="{
            name: 'Voice Access List',
            params: { voiceId }
          }"
        >
          <EyeOpenIcon class="mr-2 size-4" />
          <span>Access Control</span>
        </RouterLink>
      </Button>
      <Button variant="outline" size="xs" @click="previewVoice(voiceId)">
        <PlayIcon class="mr-2 size-4" />
        <span>Preview Voice</span>
      </Button>
    </template>
  </TopLine>
  <div class="mx-4 w-full max-w-xl self-center lg:mx-6">
    <AutoForm :schema :form class="mt-4">
      <Button
        variant="default"
        size="xs"
        :disabled="state === CommandState.IN_PROGRESS"
        @click="submit"
      >
        <PlusIcon class="mr-2 size-4" />
        <span>Create</span>
      </Button>
    </AutoForm>
    <Separator class="my-4" label="Read-Only" />
    <p class="text-sm">
      <span class="font-medium">Identifier: </span>{{ voice?.voice_identifier }}
    </p>
    <p class="text-sm"><span class="font-medium">Provider: </span>{{ voice?.voice_provider }}</p>
  </div>
</template>
