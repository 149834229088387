<script setup lang="ts">
import { ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import { TopLine } from '@/components/modern/page-navigation'
import type { OrganizationAssignment } from '@/open-api/generated'
import { injectUseGetOrSearchOrganisationAssignments } from '@/composables/api/queries/useGetOrSearchOrganisationAssignments'
import useDeleteAssignmentV2 from '@/composables/api/mutations/useDeleteAssignmentV2'
import useAssignToCohort from '@/composables/api/mutations/useAssignToCohort'
import { CommandState } from '@/types/api'
import { DataTable, DataTableServerPagination } from '@/components/modern/ui/data-table'
import { useColumns } from '@/components/modern/tables/organization-activities'
import SearchFilter from '@/components/modern/ui/SearchFilter.vue'
import { Button } from '@/components/modern/ui/button'
import { PlusIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern Activities - All Activities List',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

const { query, activities, isLoading, refetch, pagination } =
  injectUseGetOrSearchOrganisationAssignments({ authStore, notificationStore })

const activityId = ref<string>('')
const useDelete = useDeleteAssignmentV2({ activityId, notificationStore })
const requestDeleteAssignment = ({ assignment_id }: OrganizationAssignment) => {
  activityId.value = assignment_id
}
const confirmDeleteAssignment = async (): Promise<boolean> => {
  await useDelete.execute()
  if (useDelete.state.value === CommandState.SUCCESS) {
    refetch()
    useDelete.reset()
    return true
  } else {
    useDelete.reset()
    return false
  }
}

const assignToCohort = useAssignToCohort({ notificationStore })

const columns = useColumns({
  requestDeleteAssignment,
  confirmDeleteAssignment,
  assignToCohort
})
</script>

<template>
  <TopLine>
    <template #left>
      <span> All Activities </span>
      <SearchFilter v-model="query" placeholder="Search by name or description" class="min-w-72" />
    </template>
    <template #right>
      <Button v-if="authStore.flagAuthoringEnabled" variant="default" size="xs" asChild>
        <RouterLink :to="{ name: 'Modern Activities - New Activity' }">
          <PlusIcon class="mr-2 size-4" />
          Author Activity
        </RouterLink>
      </Button>
    </template>
  </TopLine>
  <DataTable :data="activities" :columns :isLoading />
  <DataTableServerPagination v-show="!query" v-bind="pagination" />
</template>
