<script setup lang="ts">
import { ref, computed } from 'vue'
import CustomList from '@/components/CustomList.vue'
import CustomModal from '@/components/utils/CustomModal.vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomInput from '@/components/utils/CustomInput.vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { useContentStore } from '@/stores/content'
import { useNotificationStore } from '@/stores/notifications'
import { useAuthStore } from '@/stores/auth'
import type {
  CharacterActionInput,
  CharacterActionSummary,
  CharacterOutput,
  UpdateCharacterInput
} from '@/open-api/generated'
import Api from '@/open-api'
import { NotificationStatus } from '@/types/notification'
import { useRoute } from 'vue-router'
import { Breadcrumb } from '@/components/modern/page-navigation'

definePage({
  name: 'Character Actions',
  meta: {
    permissionLevel: 'Educator',
    requiresAuthoring: true
  }
})

const route = useRoute('Character Actions')
const contentStore = useContentStore()
const notificationStore = useNotificationStore()
const authStore = useAuthStore()
const isReadOnly = computed(() => {
  return (
    route.params.characterId !== 'new' &&
    !authStore.isAtLeastStaffUser &&
    !(
      authStore.organizationId &&
      authStore.organizationId ===
        (contentStore.editingCharacter as CharacterOutput)?.owning_organization_id
    )
  )
})

// ==================================================
// List Actions
// ==================================================

const modalType = ref('')
const selectedActionIndex = ref(0)

const onCreate = () => {
  modalType.value = 'create'
  modalStatus.value = true
}

const onDelete = (deleteInfo: { id: string; index: number }) => {
  modalType.value = 'delete'
  selectedActionIndex.value = deleteInfo.index
  modalStatus.value = true
}

const onView = (data: { id: string; index: number }) => {
  modalType.value = 'edit'
  selectedActionIndex.value = data.index
  const currentEditingAction = contentStore?.editingCharacter?.actions?.[data.index]
  action_text.value = currentEditingAction?.action_text || ''
  public_label.value = currentEditingAction?.public_label || ''
  modalStatus.value = true
}

// ==================================================
// Modal Actions
// ==================================================
const modalStatus = ref(false)
const public_label = ref('')
const action_text = ref('')

const saveLoading = ref(false)
const deleteLoading = ref(false)

const modalActionDisabled = computed(() => {
  if (modalType.value === 'edit') {
    const currentEditingInvestigation =
      contentStore?.editingCharacter?.actions?.[selectedActionIndex.value]

    return (
      !public_label.value.trim() ||
      !action_text.value.trim() ||
      (action_text.value === currentEditingInvestigation?.action_text &&
        public_label.value === currentEditingInvestigation?.public_label)
    )
  } else {
    return !public_label.value.trim() || !action_text.value.trim()
  }
})

const onModalClose = () => {
  setTimeout(() => {
    modalType.value = ''
    selectedActionIndex.value = 0
    public_label.value = ''
    action_text.value = ''
  }, 350)
}

const createAction = () => {
  if (route.params.characterId === 'new') {
    const newAction: CharacterActionInput = {
      public_label: public_label.value,
      action_text: action_text.value
    }
    const newActions = [
      ...(contentStore?.editingCharacter?.actions || []),
      newAction
    ] as CharacterActionSummary[]

    contentStore.updateEditingCharacter({
      actions: newActions
    })
    modalStatus.value = false
  } else {
    const { editingCharacter } = contentStore

    if (!editingCharacter) {
      return
    }

    saveLoading.value = true

    const updateCharacter = contentStore.editingCharacter as UpdateCharacterInput | CharacterOutput

    const update = { ...updateCharacter } as UpdateCharacterInput
    update.actions?.push({
      public_label: public_label.value,
      action_text: action_text.value
    })

    update.allowlist = authStore.isAtLeastStaffUser
      ? updateCharacter.allowlist?.map((listItem) => {
          if (typeof listItem === 'string') {
            return listItem
          }
          return listItem?.organization_id
        })
      : null

    Api.Content.updateCharacterEndpoint(update as UpdateCharacterInput)
      .then((res) => {
        contentStore.setEditingCharacter(res)
        notificationStore.addNotification({
          subtitle: 'Character Action successfully created',
          status: NotificationStatus.SUCCESS
        })
      })
      .catch((err: any) => {
        notificationStore.addNotification({
          subtitle: err?.body?.message,
          status: NotificationStatus.DANGER
        })
      })
      .finally(() => {
        modalStatus.value = false
        saveLoading.value = false
      })
  }
}

const saveAction = () => {
  const { editingCharacter } = contentStore

  if (!editingCharacter) {
    return
  }
  const updateCharacter = contentStore.editingCharacter as UpdateCharacterInput | CharacterOutput
  const allowlist = authStore.isAtLeastStaffUser
    ? updateCharacter.allowlist?.map((listItem) => {
        if (typeof listItem === 'string') {
          return listItem
        }
        return listItem?.organization_id
      })
    : null
  const update = {
    ...editingCharacter,
    allowlist
  }
  if (update.actions) {
    update.actions[selectedActionIndex.value] = {
      public_label: public_label.value,
      action_text: action_text.value
    }
    modalStatus.value = false
  } else {
    return
  }

  if (route.params.characterId === 'new') {
    contentStore.setEditingCharacter(update)
  } else {
    saveLoading.value = true

    Api.Content.updateCharacterEndpoint(update as UpdateCharacterInput)
      .then(() => {
        contentStore.setEditingCharacter(update)
        notificationStore.addNotification({
          subtitle: 'Character Action successfully saved',
          status: NotificationStatus.SUCCESS
        })
      })
      .catch((err: any) => {
        notificationStore.addNotification({
          subtitle: err?.body?.message,
          status: NotificationStatus.DANGER
        })
      })
      .finally(() => {
        modalStatus.value = false
        saveLoading.value = false
      })
  }
}

const deleteAction = () => {
  const { editingCharacter } = contentStore

  if (!editingCharacter) {
    return
  }
  const updateCharacter = contentStore.editingCharacter as UpdateCharacterInput | CharacterOutput

  const update = { ...updateCharacter } as UpdateCharacterInput

  if (route.params.characterId === 'new') {
    update.actions?.splice(selectedActionIndex.value, 1)
    contentStore.setEditingCharacter(update)
  } else {
    deleteLoading.value = true

    update.actions?.splice(selectedActionIndex.value, 1)

    update.allowlist = authStore.isAtLeastStaffUser
      ? updateCharacter.allowlist?.map((listItem) => {
          if (typeof listItem === 'string') {
            return listItem
          }
          return listItem?.organization_id
        })
      : null

    Api.Content.updateCharacterEndpoint(update as UpdateCharacterInput)
      .then(() => {
        contentStore.setEditingCharacter(update)
        notificationStore.addNotification({
          subtitle: 'Character Investigation successfully deleted',
          status: NotificationStatus.SUCCESS
        })
      })
      .catch((err: any) => {
        notificationStore.addNotification({
          subtitle: err?.body?.message,
          status: NotificationStatus.DANGER
        })
      })
      .finally(() => {
        deleteLoading.value = false
        modalStatus.value = false
      })
  }
}
</script>

<template>
  <Breadcrumb :route name="Actions" />
  <CustomList
    viewType="cell"
    class="px-5"
    :listData="contentStore?.editingCharacter?.actions || []"
    :hasHeader="false"
    :hasImg="false"
    canDelete
    :readOnly="isReadOnly"
    @onCreate="onCreate"
    @onDelete="onDelete"
    @onView="onView"
  />
  <CustomModal v-model="modalStatus" @onClose="onModalClose">
    <div v-if="modalType === 'delete'" class="flex flex-col">
      <h3 class="mb-3 text-xl">Delete Action</h3>
      <p class="text-sc-grey-800">
        Are you sure you want to delete this action? This cannot be undone.
      </p>
      <div class="mt-5 flex gap-3 self-end">
        <CustomButton buttonType="admin-secondary" @click="() => (modalStatus = false)">
          Cancel
        </CustomButton>
        <CustomButton @click="deleteAction"> Yes, delete </CustomButton>
      </div>
    </div>
    <div v-else class="flex flex-col">
      <div class="mb-5 flex items-center justify-between">
        <h3 class="select-none text-lg">
          {{ isReadOnly ? 'View' : modalType === 'create' ? 'Create' : 'Edit' }} Action
        </h3>
        <div v-if="isReadOnly" class="h-7 w-7 cursor-pointer p-1" @click="modalStatus = false">
          <XMarkIcon class="h-5 w-5" />
        </div>
      </div>

      <CustomInput v-model="public_label" label="Action Label" :readOnly="isReadOnly" />
      <CustomInput v-model="action_text" label="Action Prompt Text" :readOnly="isReadOnly" />

      <div v-if="!isReadOnly" class="mt-5 flex gap-3 self-end">
        <CustomButton buttonType="admin-secondary" @click="() => (modalStatus = false)">
          Cancel
        </CustomButton>
        <CustomButton
          :disabled="modalActionDisabled"
          @click="modalType === 'edit' ? saveAction() : createAction()"
        >
          {{ modalType === 'edit' ? 'Save' : 'Create' }}
        </CustomButton>
      </div>
    </div>
  </CustomModal>
</template>
