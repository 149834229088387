import { EMPTY_STRING_SEARCH_ALL } from '@/constants/server'
import type { OrganizationSummary } from '@/open-api/generated'
import type { NotificationStore } from '@/types/api'
import { NotificationStatus } from '@/types/notification'
import { ref } from 'vue'
import Api from '@/open-api'

const useAllOrganizations = ({ notificationStore }: { notificationStore: NotificationStore }) => {
  const organizations = ref<OrganizationSummary[]>([])
  const organizationsLoading = ref<boolean>(true)

  Api.Management.listAllOrganizationsEndpoint(EMPTY_STRING_SEARCH_ALL)
    .then((res) => {
      organizations.value = res.organizations
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      organizationsLoading.value = false
    })

  return { organizations, organizationsLoading }
}

export default useAllOrganizations
