<script setup lang="ts">
import { COUNTRY_ISO_WIKI } from '@/constants/external-urls'
import { DataTable } from '@/components/modern/ui/data-table'
import { TopLine } from '@/components/modern/page-navigation'
import { Button } from '@/components/modern/ui/button'
import CustomModal from '@/components/utils/CustomModal.vue'
import { PlusIcon } from '@radix-icons/vue'
import { useColumns } from '@/components/modern/tables/organization-countries'
import { ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import useGetOrganizationCountries from '@/composables/api/queries/useGetOrganizationCountries'
import useDeleteOrganizationCountry from '@/composables/api/mutations/useDeleteOrganizationCountry'
import { CommandState } from '@/types/api'
import Input from '@/components/modern/ui/input/Input.vue'
import useCreateOrganizationCountry from '@/composables/api/mutations/useCreateOrganizationCountry'

definePage({
  name: 'Management Organization Countries',
  meta: {
    permissionLevel: 'SuperAdmin',
    isModern: true
  }
})

const modalOpen = ref(false)
const closeModal = () => {
  countryName.value = ''
  modalOpen.value = false
}

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

const createOrganizationCountry = useCreateOrganizationCountry({ notificationStore, authStore })
const submitCountryName = () => {
  createOrganizationCountry(countryName.value).then(() => refetch())
  closeModal()
}

const countryName = ref<string>('')
const useDelete = useDeleteOrganizationCountry({ countryName, notificationStore, authStore })

const requestDeleteCountry = (country_name: string) => {
  countryName.value = country_name
}
const confirmDeleteCountry = async (): Promise<boolean> => {
  await useDelete.execute()
  countryName.value = ''

  if (useDelete.state.value === CommandState.SUCCESS) {
    refetch()
    useDelete.reset()
    return true
  } else {
    useDelete.reset()
    return false
  }
}

const { countryNames, isLoading, refetch } = useGetOrganizationCountries({
  authStore,
  notificationStore
})
const columns = useColumns({
  requestDeleteCountry,
  confirmDeleteCountry
})
</script>

<template>
  <TopLine>
    <template #left>
      <span> Countries </span>
    </template>
    <template #right>
      <Button variant="default" size="xs" asChild @click="modalOpen = true">
        <div>
          <PlusIcon class="mr-2 size-4" />
          Add Country
        </div>
      </Button>
    </template>
  </TopLine>

  <DataTable ref="table" :data="countryNames" :columns :isLoading />

  <CustomModal v-model="modalOpen" @onClose="closeModal">
    <div class="flex flex-col space-y-4">
      <div>
        <h3 class="mb-3 text-xl">Add Country</h3>
        <p>
          Please enter the country name in the
          <a
            :href="COUNTRY_ISO_WIKI"
            target="_blank"
            rel="noopener noreferrer"
            class="text-blue-600 underline"
            >ISO 3166-1</a
          >
          format.
        </p>
      </div>

      <div>
        <Input v-model="countryName" placeholder="Country Name" required />
      </div>
      <div class="mt-5 flex gap-3 self-end">
        <Button variant="default" size="default" @click="() => submitCountryName()"> Add </Button>
      </div>
    </div>
  </CustomModal>
</template>
