import type { AddCreditsInput } from '../models/AddCreditsInput';
import type { AddCreditsOutput } from '../models/AddCreditsOutput';
import type { i32 } from '../models/i32';
import type { ListOrganizationCreditsOutput } from '../models/ListOrganizationCreditsOutput';
import type { ListOrganizationDebitsOutput } from '../models/ListOrganizationDebitsOutput';
import type { VoidCreditsInput } from '../models/VoidCreditsInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';



export class BillingService {

	/**
	 * AddCredits
	 * Add credits to an organization.
	 * @param requestBody 
	 * @returns AddCreditsOutput Credits added successfully.
	 * @throws ApiError
	 */
	public static addCreditsEndpoint(
requestBody: AddCreditsInput,
): CancelablePromise<AddCreditsOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/billing/AddCredits',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to add credits.`,
			},
		});
	}

	/**
	 * ListOrganizationCredits
	 * Retrieve the list of an organization's credits.
	 * @param organizationId The ID of the organization.
	 * @param itemsPerPage Number of items per page.
	 * @param page Page number. Starts from 1.
	 * @returns ListOrganizationCreditsOutput Credits retrieved successfully.
	 * @throws ApiError
	 */
	public static listOrganizationCreditsEndpoint(
organizationId: string,
itemsPerPage: i32,
page: i32,
): CancelablePromise<ListOrganizationCreditsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/billing/ListOrganizationCredits',
			query: {
				organization_id: organizationId, items_per_page: itemsPerPage, page
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query billing.`,
			},
		});
	}

	/**
	 * ListOrganizationDebit
	 * Retrieve the debit history for an organization.
	 * @param organizationId The ID of the organization.
	 * @param itemsPerPage Number of items per page.
	 * @param page Page number. Starts from 1.
	 * @returns ListOrganizationDebitsOutput Debits retrieved successfully.
	 * @throws ApiError
	 */
	public static listOrganizationDebitsEndpoint(
organizationId: string,
itemsPerPage: i32,
page: i32,
): CancelablePromise<ListOrganizationDebitsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/billing/ListOrganizationDebits',
			query: {
				organization_id: organizationId, items_per_page: itemsPerPage, page
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query billing.`,
			},
		});
	}

	/**
	 * VoidCredits
	 * Void a credits batch.
	 * @param requestBody 
	 * @returns void Credits voided successfully.
	 * @throws ApiError
	 */
	public static voidCreditsEndpoint(
requestBody: VoidCreditsInput,
): CancelablePromise<void> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/billing/VoidCredits',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to void credits.`,
			},
		});
	}

}