import posthog from 'posthog-js'
import { POSTHOG_KEY, POSTHOG_HOST } from '@/constants/environment'

const $posthog = posthog.init(POSTHOG_KEY, {
  api_host: POSTHOG_HOST,
  // https://posthog.com/docs/session-replay/privacy
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: { password: true, email: true },
    maskTextFn: (text) => {
      const emailRegex = /(\S+)@(\S+\.\S+)/g

      return text.replace(emailRegex, (match, g1, g2) => {
        // Replace each email with asterisks - ben@posthog.com becomes ***@***********
        return '*'.repeat(g1.length) + '@' + '*'.repeat(g2.length)
      })
    }
  },
  // we capture pageviews in router's afterEach, this prevents
  // us from capturing a duplicate first pageload
  capture_pageview: false
})

export default $posthog
