<script setup lang="ts">
import { computed, provide } from 'vue'
import { useRoute } from 'vue-router'
import { Breadcrumb } from '@/components/modern/page-navigation'
import { useNotificationStore } from '@/stores/notifications'
import useGetAssignment, { useGetAssignmentKey } from '@/composables/api/queries/useGetAssignment'

definePage({
  name: 'Activity Provider',
  redirect: 'Modern Activities - Edit Activity',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const route = useRoute('Activity Provider')
const activityId = computed(() => route.params.activityId)

const notificationStore = useNotificationStore()
const getAssignment = useGetAssignment({ activityId, notificationStore })
provide(useGetAssignmentKey, getAssignment)
const { activity } = getAssignment
</script>

<template>
  <Breadcrumb
    :route="{ name: 'Modern Activities - Edit Activity', params: { activityId } }"
    :name="activity?.name"
  />
  <RouterView />
</template>
