<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { cn } from '@/lib/utils'
import OrganizationLabel from '@/components/modern/app-navigation-sidebar/OrganizationLabel.vue'
import ScheduledDowntimeWarning from '@/components/modern/ScheduledDowntimeWarning.vue'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<template>
  <OrganizationLabel class="hidden print:absolute print:flex" />
  <div
    id="breadcrumbs-container"
    :class="
      cn(
        'flex h-9 flex-row flex-nowrap items-center justify-center overflow-hidden pt-px',
        props.class
      )
    "
  >
    <!-- Breadcrumbs from ./Breadcrumb.vue will be Teleported here -->
  </div>
  <ScheduledDowntimeWarning />
</template>
