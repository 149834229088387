<script setup lang="ts">
import { ref, computed } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useUtilsStore } from '@/stores/utils'
import { useNotificationStore } from '@/stores/notifications'
import { injectUseGetMyAssignmentInstances } from '@/composables/api/queries/useGetMyAssignmentInstances'
import useGetMyLegacyCohorts from '@/composables/api/queries/useGetMyLegacyCohorts'
import useGetMyLegacyAssignments from '@/composables/api/queries/useGetMyLegacyAssignments'
import createIsoTimes from '@/utils/iso-times'
import { isOpenAt, isPastAt, ascending, descending } from '@/utils/status/activity-status'
import { ActivityStatusIcon } from '@/components/modern/icons'
import { DataTableGroup } from '@/components/modern/ui/data-table'
import { useColumns } from '@/components/modern/tables/my-activities'
import { Button } from '@/components/modern/ui/button'
import { QuestionMarkIcon } from '@radix-icons/vue'
import { TopLine } from '@/components/modern/page-navigation'
import { LoadingCover } from '@/components/modern/ui/loading'
import WalkthroughModal from '@/components/modern/WalkthroughModal.vue'
// TODO(LegacySupport)
import type { MyAssignmentSummary, MyAssignmentInstanceOutput } from '@/open-api/generated'
import Separator from '@/components/modern/ui/separator/Separator.vue'

definePage({
  name: 'Modern - My Activities',
  meta: {
    permissionLevel: 'Student',
    isModern: true
  }
})

const authStore = useAuthStore()
const utilsStore = useUtilsStore()
const notificationStore = useNotificationStore()

const getMyAssignmentInstances = injectUseGetMyAssignmentInstances({ notificationStore })

// TODO(LegacySupport): Delete all this

let getMyLegacyCohorts: ReturnType<typeof useGetMyLegacyCohorts> = {
  isLoading: ref(false),
  legacyCohorts: ref([]),
  refetch: () => {}
}
let getMyLegacyAssignments: ReturnType<typeof useGetMyLegacyAssignments> = {
  isLoading: ref(false),
  legacyAssignments: ref([]),
  refetch: () => {}
}

if (authStore.flagLegacyEnabled) {
  getMyLegacyCohorts = useGetMyLegacyCohorts({ notificationStore })
  const legacyCohortIds = computed<string[]>(() =>
    getMyLegacyCohorts.legacyCohorts.value.map((c) => c.cohort_id)
  )

  getMyLegacyAssignments = useGetMyLegacyAssignments({
    cohortIds: legacyCohortIds,
    notificationStore
  })
}

// TODO(LegacySupport):
// const { activityInstances, isLoading } = useGetMyAssignmentInstances({ notificationStore })
const activitiesAndAssignments = computed<Array<MyAssignmentInstanceOutput | MyAssignmentSummary>>(
  () => [
    ...getMyAssignmentInstances.activityInstances.value,
    ...getMyLegacyAssignments.legacyAssignments.value
  ]
)
const isLoading = computed(
  (): boolean =>
    getMyAssignmentInstances.isLoading.value ||
    getMyLegacyCohorts.isLoading.value ||
    getMyLegacyAssignments.isLoading.value
)

const isoTimes = createIsoTimes()
const columns = useColumns({ isoTimes })

// Pre-filter and sort assignments
const openData = computed(() =>
  activitiesAndAssignments.value.filter(isOpenAt(isoTimes)).sort(ascending)
)
const pastData = computed(() =>
  activitiesAndAssignments.value.filter(isPastAt(isoTimes)).sort(descending)
)
</script>

<template>
  <TopLine>
    <template #left>
      <span class="text-base"> My Activities </span>
    </template>
    <template #right>
      <WalkthroughModal>
        <Button variant="outline" :size="utilsStore.isMobile ? 'icon-xs' : 'xs'">
          <QuestionMarkIcon class="size-4 lg:mr-2" />
          <span v-if="!utilsStore.isMobile">View Walkthrough</span>
        </Button>
      </WalkthroughModal>
    </template>
  </TopLine>
  <LoadingCover :isLoading centerScreen>
    <DataTableGroup :data="openData" :columns :isLoading empty="No open activities.">
      <ActivityStatusIcon status="NotStarted" class="mr-1.5 size-4" />
      <p class="text-base">Open Activities</p>
    </DataTableGroup>

    <Separator />

    <DataTableGroup :data="pastData" :columns :isLoading empty="No past activities.">
      <ActivityStatusIcon status="Completed" class="mr-1.5 size-4" />
      <p class="text-base">Past Activities</p>
    </DataTableGroup>
  </LoadingCover>
</template>
