<script setup lang="ts">
import { computed, provide } from 'vue'
import { useRoute } from 'vue-router'
import { Breadcrumb } from '@/components/modern/page-navigation'
import { useNotificationStore } from '@/stores/notifications'
import useFirstSecondIds from '@/composables/useFirstSecondIds'
import { injectUseGetCohortV2 } from '@/composables/api/queries/useGetCohortV2'
import { injectUseGetCohortStudents } from '@/composables/api/queries/useGetCohortStudents'
import { injectUseGetCohortAssignmentInsights } from '@/composables/api/queries/useGetCohortAssignmentInsights'
import useGetAssignmentInstanceStudentInsight, {
  useGetAssignmentInstanceStudentInsightKey
} from '@/composables/api/queries/useGetAssignmentInstanceStudentInsight'

definePage({
  name: 'Student Attempts Provider',
  redirect: 'Modern Educator - Student Attempts on Task',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const route = useRoute('Student Attempts Provider')
const cohortId = computed(() => route.params.cohortId)
const firstId = computed(() => route.params.firstId)
const secondId = computed(() => route.params.secondId)
// We can arrive at this page from `cohort/learners/...`, or from `cohort/activities/...`
// Examine the id prefix to determine from whence we came, and to where we shall return.
const { userId, assignmentInstanceId, firstIdIsUserId } = useFirstSecondIds({
  firstId,
  secondId
})

const notificationStore = useNotificationStore()

const { cohort } = injectUseGetCohortV2({ cohortId, notificationStore })
const { students } = injectUseGetCohortStudents({ cohortId, notificationStore })
const { activityInstances } = injectUseGetCohortAssignmentInsights({ cohortId, notificationStore })

const student = computed(() => students.value.find((s) => s.id === userId.value))
const instance = computed(() =>
  activityInstances.value.find((a) => a.assignment_instance_id === assignmentInstanceId.value)
)

const getAssignmentInstanceStudentInsight = useGetAssignmentInstanceStudentInsight({
  assignmentInstanceId,
  userId,
  notificationStore
})
provide(useGetAssignmentInstanceStudentInsightKey, getAssignmentInstanceStudentInsight)
</script>

<template>
  <template v-if="firstIdIsUserId">
    <Breadcrumb
      :route="{ name: 'Modern Cohorts - Cohort Learners List', params: { cohortId } }"
      :name="cohort?.name"
    />
    <Breadcrumb
      :route="{ name: 'Modern Cohorts - Student Progress', params: { cohortId, userId } }"
      :name="student?.email"
    />
    <Breadcrumb
      :route="{
        name: 'Modern Educator - Student Attempts on Task',
        params: { cohortId, firstId, secondId }
      }"
      :name="instance?.name"
    />
  </template>

  <template v-else>
    <Breadcrumb
      :route="{ name: 'Modern Cohorts - Cohort Activities List', params: { cohortId } }"
      :name="cohort?.name"
    />
    <Breadcrumb
      :route="{
        name: 'Modern Cohorts - Activity Instance',
        params: { cohortId, assignmentInstanceId }
      }"
      :name="instance?.name"
    />
    <Breadcrumb
      :route="{
        name: 'Modern Educator - Student Attempts on Task',
        params: { cohortId, firstId, secondId }
      }"
      :name="student?.email"
    />
  </template>

  <RouterView />
</template>
