<script setup lang="ts">
import {
  SUPPORT_SUPERADMIN,
  SUPPORT_INSTITUTIONADMIN,
  SUPPORT_EDUCATOR,
  SUPPORT_LEARNER,
  SUPPORT_AUTHORING,
  PRIVACY_POLICY,
  CHANGELOG
} from '@/constants/external-urls'
import { computed, watch, ref } from 'vue'
import { Button } from '@/components/modern/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/modern/ui/dropdown-menu'
import { ExitIcon, ExternalLinkIcon } from '@radix-icons/vue'
import { useAuth0 } from '@auth0/auth0-vue'
import { useAuthStore } from '@/stores/auth'
import { useRoute } from 'vue-router'
import RoleSelect from '@/components/modern/app-navigation-sidebar/RoleSelect.vue'
import OrganizationSelect from '@/components/modern/app-navigation-sidebar/OrganizationSelect.vue'

const { logout } = useAuth0()
const authStore = useAuthStore()

const allowEmailNotifications = ref<'true' | 'false'>(
  authStore.notificationIncompleteAssignments ? 'true' : 'false'
)

watch(allowEmailNotifications, (newSetting) => {
  authStore.setNotificationIncompleteAssignments(newSetting === 'true')
})

const route = useRoute()

const isInAuthoring = computed(
  () => route.path.includes('/content/') || route.path.includes('/activities')
)

// TODO: Enable this to be set for BMJ
const supportLink = computed(() => {
  if (authStore.isSuperAdminUser) {
    return SUPPORT_SUPERADMIN
  } else if (
    authStore.flagAuthoringEnabled &&
    authStore.isAtLeastEducatorUser &&
    isInAuthoring.value
  ) {
    return SUPPORT_AUTHORING
  } else if (authStore.isAtLeastInstitutionAdminUser) {
    return SUPPORT_INSTITUTIONADMIN
  } else if (authStore.isAtLeastEducatorUser) {
    return SUPPORT_EDUCATOR
  } else {
    return SUPPORT_LEARNER
  }
})

// TODO: Enable this to be set for BMJ
// TODO: Add instructions / a template into mailto
// const supportMailTo = computed<string>(()=>authStore.isAtLeastEducatorUser
// ? 'mailto:support@simconverse.com'
// : 'mailto:student-support@simconverse.com'
// )
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button
        variant="outline"
        size="icon"
        class="flex items-center justify-center rounded-full text-center text-base"
      >
        <span>?</span>
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="start" side="top">
      <DropdownMenuItem asChild>
        <a :href="supportLink" target="_blank" rel="noopener noreferrer">
          <ExternalLinkIcon class="mr-2 size-4" />
          <span>Support</span>
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <a :href="PRIVACY_POLICY" target="_blank" rel="noopener noreferrer">
          <ExternalLinkIcon class="mr-2 size-4" />
          <span>Privacy Policy</span>
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <a :href="CHANGELOG" target="_blank" rel="noopener noreferrer">
          <ExternalLinkIcon class="mr-2 size-4" />
          <span>Changelog</span>
        </a>
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuLabel>Email Notifications</DropdownMenuLabel>
      <DropdownMenuRadioGroup v-model="allowEmailNotifications">
        <DropdownMenuRadioItem value="true">Allow</DropdownMenuRadioItem>
        <DropdownMenuRadioItem value="false">Deny</DropdownMenuRadioItem>
      </DropdownMenuRadioGroup>
      <template v-if="authStore.isTrueSuperAdmin">
        <DropdownMenuSeparator />
        <RoleSelect class="m-1" />
        <OrganizationSelect class="m-1" />
      </template>
      <DropdownMenuSeparator />
      <DropdownMenuItem @click="logout">
        <ExitIcon class="mr-2 size-4 shrink-0" />
        Log out
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
