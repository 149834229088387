<script setup lang="ts">
import { POSTHOG_DASHBOARD } from '@/constants/external-urls'
definePage({
  name: 'Management Feature Flags',
  meta: {
    permissionLevel: 'SuperAdmin'
  }
})
</script>

<template>
  <div class="flex flex-col gap-8">
    <div>
      <h2 class="mb-3 text-xl">Feature Flags</h2>
      <p>
        Disable or enable feature flags using the PostHog toolbar:
        <a :href="POSTHOG_DASHBOARD">open the PostHog dashboard</a> and click "Launch" on the
        appropriate url to launch a new window with the PostHog toolbar.
      </p>
    </div>
  </div>
</template>
