<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'
import { useSimulationStore } from '@/stores/simulation'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import Api from '@/open-api'
import { useNotificationStore } from '@/stores/notifications'
import { NotificationStatus } from '@/types/notification'
import { useRoute } from 'vue-router'

definePage({
  name: 'Organization History Wrapper',
  meta: {
    permissionLevel: 'Educator'
  }
})
// ==================================================
// Init
// ==================================================
const route = useRoute<
  'Organization History Wrapper' | 'Organization History Transcript' | 'Organization History Rubric'
>()
const simulationStore = useSimulationStore()
const notificationStore = useNotificationStore()

// ==================================================
// Set Character
// ==================================================
const characterLoading = ref(false)
onBeforeMount(async () => {
  characterLoading.value = true

  try {
    // just switch on route.params, this is fine
    if (route.params.conversationId !== 'vr-scene') {
      const character = await Api.Conversation.getConversationEndpoint(
        route.params.conversationId as string
      )

      // This is not required when entering history mode -
      if (!route.fullPath.includes('history')) {
        const initCharacterMessage = await Api.Conversation.getCharacterMessageEndpoint(
          route.params.conversationId as string,
          undefined
        )
        // ☠️ ☣️ ☣️ To cover when a page reload happens -- Very Important -- Do not remove ☣️ ☣️ ☠️
        if (initCharacterMessage.message?.transcript_entry_id) {
          simulationStore.setInitCharacterMessage(initCharacterMessage)
        } else {
          simulationStore.setInitCharacterMessage(null)
        }
      }

      simulationStore.setConversation(character)
    }
  } catch (err: any) {
    notificationStore.addNotification({
      subtitle: err?.body?.message,
      status: NotificationStatus.DANGER
    })
  } finally {
    characterLoading.value = false
  }
})
</script>

<template>
  <div v-if="characterLoading" class="flex h-full w-full items-center justify-center">
    <AppLoadingSpinner class="py-20" :loading="characterLoading" />
  </div>
  <RouterView v-else class="pl-0 pr-0 md:pl-0 md:pr-0" />
</template>
