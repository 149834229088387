<script setup lang="ts">
import { computed } from 'vue'
import Conversation from '@/components/conversation'
import { useNotificationStore } from '@/stores/notifications'
import Api from '@/open-api'
import { NotificationStatus } from '@/types/notification'
import { useRoute, useRouter } from 'vue-router'
import useConversation from '@/composables/api/useConversation'

definePage({
  name: 'Cohort Conversation',
  meta: {
    permissionLevel: 'Educator'
  }
})

const notificationStore = useNotificationStore()
const router = useRouter()
const route = useRoute('Cohort Conversation')

const conversationId = computed(() => route.params.conversationId)

const endConversation = () => {
  Api.Conversation.endConversationEndpoint({ conversation_id: conversationId.value })
    .then(() => {
      router.push({ name: 'Cohort Conversation Transcript' })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
}

const conversation = useConversation({ conversationId, notificationStore })
</script>

<template>
  <div class="flex w-full p-4 lg:p-6">
    <Conversation :conversation @endConversation="endConversation" />
  </div>
</template>
