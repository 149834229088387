<script setup lang="ts">
import { watch, reactive, onBeforeMount, ref, computed } from 'vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomInput from '@/components/utils/CustomInput.vue'
import CustomToggle from '@/components/utils/CustomToggle.vue'
import { useAssignmentStore, type CohortData } from '@/stores/assignment'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import { ArrowDownIcon } from '@heroicons/vue/24/outline'
import Api from '@/open-api'
import { nextTick } from 'vue'
import { onBeforeRouteLeave, useRoute } from 'vue-router'

definePage({
  name: 'Cohort Details',
  meta: {
    permissionLevel: 'Educator'
  }
})

// ==================================================
// Init
// ==================================================
const notificationStore = useNotificationStore()
const assignmentStore = useAssignmentStore()
const route = useRoute('Cohort Details')

onBeforeRouteLeave((to, from, next) => {
  if (route.params.cohortId !== 'new' && !canSave.value) {
    const answer = window.confirm(
      'Warning: You have unsaved changes. \nNavigating away from this page will delete any unsaved changes.'
    )
    if (answer) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
})

// ==================================================
// Cohort form
// ==================================================
const saveLoading = ref(false)
const currentEditingCohort = reactive<CohortData>(assignmentStore.newCohortData)

onBeforeMount(() => {
  nextTick(() => {
    Object.assign(currentEditingCohort, assignmentStore.editingCohort)
  })
})

watch(
  currentEditingCohort,
  (newVal) => {
    if (route.params.cohortId === 'new') {
      assignmentStore.updateEditingCohort(newVal)
    }
  },
  { deep: true }
)

// <Edit Cohort> - Save cohort details
const saveCohortDetails = () => {
  saveLoading.value = true
  Api.Assignment.updateCohortEndpoint(currentEditingCohort)
    .then(() => {
      assignmentStore.updateEditingCohort(currentEditingCohort)
      notificationStore.addNotification({
        subtitle: 'Cohort Details successfully saved',
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      saveLoading.value = false
    })
}

const canSave = computed(() => {
  const { name, description, is_default, visible } = currentEditingCohort

  const storeEditingJSON = JSON.stringify(assignmentStore.editingCohort)
  const componentEditingJSON = JSON.stringify(currentEditingCohort)

  return (
    !name.trim() ||
    !description.trim() ||
    is_default === undefined ||
    visible === undefined ||
    storeEditingJSON === componentEditingJSON
  )
})
</script>

<template>
  <div class="flex flex-col gap-y-5">
    <div class="flex w-[620px] flex-col">
      <h2 class="mb-5 text-lg">Details</h2>
      <CustomInput v-model="currentEditingCohort.name" field="name" label="Cohort Name" />
      <CustomInput
        v-model="currentEditingCohort.description"
        inputType="textarea"
        label="Description"
      />
      <CustomInput
        v-model="currentEditingCohort.visible"
        inputType="select"
        label="Visibility"
        :options="[
          {
            name: 'Visible to students',
            value: true
          },
          {
            name: 'Hidden from students',
            value: false
          }
        ]"
      />
      <div class="flex items-center gap-5">
        <CustomToggle v-model="currentEditingCohort.is_default" />
        <div class="flex flex-col">
          <p class="text-sc-grey-900">Add new accounts to this cohort?</p>
          <p class="text-sc-grey-800">
            If “no” all new accounts will need to be added to this cohort manually
          </p>
        </div>
      </div>

      <CustomButton
        v-if="route.params.cohortId !== 'new'"
        buttonType="admin-primary"
        :loading="saveLoading"
        :disabled="canSave"
        :startIcon="ArrowDownIcon"
        class="mb-3 mt-5"
        @click="saveCohortDetails"
      >
        Save Details
      </CustomButton>
    </div>
  </div>
</template>
