<script setup lang="ts">
import { watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import useCombineQueries from '@/composables/api/useCombineQueries'
import { injectUseGetCohortV2 } from '@/composables/api/queries/useGetCohortV2'
import { injectUseGetMyCohorts } from '@/composables/api/queries/useGetMyCohorts'
import { injectUseGetOrganizationCohorts } from '@/composables/api/queries/useGetOrganizationCohorts'
import useUpdateCohortV2 from '@/composables/api/mutations/useUpdateCohortV2'
import { schema, type Schema } from '@/composables/api/mutations/schema/useUpdateCohortV2'
import { CommandState } from '@/types/api'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { AutoForm, type Config } from '@/components/modern/ui/auto-form'
import {
  LABEL_COHORT_NAME,
  LABEL_COHORT_ISDEFAULT,
  DESCRIPTION_COHORT_NAME,
  DESCRIPTION_COHORT_ISDEFAULT
} from '@/constants/forms'
import { Breadcrumb, TopLine, BackButton } from '@/components/modern/page-navigation'
import { Button } from '@/components/modern/ui/button'
import { CheckboxIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern Cohorts - Cohort Details',
  meta: {
    permissionLevel: 'InstitutionAdmin',
    isModern: true
  }
})

const route = useRoute('Modern Cohorts - Cohort Details')
const cohortId = computed(() => route.params.cohortId)

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

// Actions setup
const { track, refetch } = useCombineQueries()
const { cohort } = track(injectUseGetCohortV2({ cohortId, notificationStore }))
track(injectUseGetMyCohorts({ notificationStore }))
track(injectUseGetOrganizationCohorts({ notificationStore, authStore }))
const { state, execute, reset } = useUpdateCohortV2({
  cohortId,
  notificationStore
})

// Form setup

const form = useForm({ validationSchema: toTypedSchema(schema) })

const fieldConfig: Config<Schema> = {
  name: {
    label: LABEL_COHORT_NAME,
    description: DESCRIPTION_COHORT_NAME
  },
  isDefault: {
    label: LABEL_COHORT_ISDEFAULT,
    description: DESCRIPTION_COHORT_ISDEFAULT,
    hideRequired: true
  }
}

// Fill in the form with the server values from GetCohort
watch(cohort, () => {
  form.resetForm({ values: mapCohortToFormValues(cohort) })
})

// Map the output of GetCohort to the input of UpdateCohort
const mapCohortToFormValues = (arg: typeof cohort): Schema | undefined => {
  if (!arg.value) {
    return undefined
  }
  return {
    name: arg.value.name,
    isDefault: arg.value.is_default
  }
}

const submit = form.handleSubmit((values) => execute(values).then(refetch).finally(reset))
</script>

<template>
  <Breadcrumb :route="{ name: 'Modern Cohorts - Cohort Activities List' }" :name="cohort?.name" />
  <Breadcrumb :route name="Details" />
  <TopLine>
    <template #left>
      <BackButton
        :to="{ name: 'Modern Cohorts - Cohort Administration', params: { cohortId } }"
        name="cohort administration"
      />
    </template>
  </TopLine>
  <div class="mt-4 flex w-full flex-row justify-center lg:mt-6">
    <div class="mx-4 mb-8 max-w-4xl flex-1 grow space-y-2 lg:mx-6">
      <p class="mb-4 text-xl font-medium">Details</p>
      <AutoForm :schema :form :fieldConfig>
        <Button
          variant="default"
          size="xs"
          :disabled="state === CommandState.IN_PROGRESS"
          @click="submit"
        >
          <CheckboxIcon class="mr-2 size-4" />
          <span>Save Changes</span>
        </Button>
      </AutoForm>
    </div>
  </div>
</template>
