<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useNotificationStore } from '@/stores/notifications'
import { mockInstanceFromActivity } from '@/utils/preview-mock-instance'
import { injectUseGetAssignment } from '@/composables/api/queries/useGetAssignment'
import usePreviewActivity from '@/composables/api/usePreviewActivity'
import useTaskAttemptState from '@/composables/useTaskAttemptState'
import useScrollToTopOnChange from '@/composables/useScrollToTopOnChange'
import Activity from '@/components/modern/activities-tasks-view'
import { ReviewTask, PerformTask } from '@/components/modern/task-attempts'
import { Breadcrumb, TopLine, BackButton, ForwardButton } from '@/components/modern/page-navigation'
import { LoadingCover } from '@/components/modern/ui/loading'
import { Button } from '@/components/modern/ui/button'
import { PlayIcon } from '@radix-icons/vue'
import { ArrowLeftStartOnRectangleIcon } from '@heroicons/vue/24/outline'

definePage({
  name: 'Modern Activities - Preview Activity',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const route = useRoute('Modern Activities - Preview Activity')
const activityId = computed(() => route.params.activityId)

const notificationStore = useNotificationStore()

const { activity: assignment, isLoading } = injectUseGetAssignment({
  activityId,
  notificationStore
})
const mockAssignmentInstance = computed(() =>
  assignment.value ? mockInstanceFromActivity(assignment.value) : null
)

const {
  selectTask,
  deselectTask,

  viewAttempt,
  stopViewingAttempt,

  activity,
  task,
  taskAttempt,
  attempts,
  allAttempts,

  startTaskPreview,
  submitting,
  handleCompleted,
  returnToPreviewingActivity,
  dismissAttempt,

  hasIncompleteAttempts,
  inAttempt
} = usePreviewActivity({ mockAssignmentInstance, notificationStore })

const { completed, inRubric, showRubric, canShowRubric, hideRubric, canHideRubric } =
  useTaskAttemptState({ task, taskAttempt })

useScrollToTopOnChange(() => task.value?.task_id)
</script>

<template>
  <Breadcrumb :route name="Preview" />
  <TopLine>
    <template #center>
      <Button variant="destructive" size="xs" class="w-min" asChild>
        <RouterLink :to="{ name: 'Modern Activities - Edit Activity', params: { activityId } }">
          <ArrowLeftStartOnRectangleIcon class="mr-2 size-4" />
          <span>Exit Preview</span>
        </RouterLink>
      </Button>
    </template>
    <template #left>
      <BackButton v-if="inAttempt && canHideRubric" name="transcript" @click="hideRubric" />
      <BackButton v-else-if="inAttempt" name="previewing task" @click="stopViewingAttempt" />
      <BackButton v-else-if="task" name="previewing activity" @click="deselectTask" />
    </template>
    <template v-if="!isLoading" #right>
      <ForwardButton
        v-if="inAttempt && canShowRubric"
        text="Continue to feedback"
        @click="showRubric"
      />
      <ForwardButton
        v-else-if="inAttempt && completed"
        text="Return to previewing activity"
        @click="returnToPreviewingActivity"
      />
      <template v-else-if="!inAttempt && task">
        <Button
          v-if="hasIncompleteAttempts"
          variant="default"
          size="xs"
          class="w-min"
          @click="startTaskPreview(task)"
        >
          <PlayIcon class="mr-2 size-4" />
          <span>Continue Task</span>
        </Button>
        <Button
          v-else-if="attempts.length && attempts.length < task.max_attempts"
          variant="default"
          size="xs"
          class="w-min"
          @click="startTaskPreview(task)"
        >
          <PlayIcon class="mr-2 size-4" />
          <span>Retry Task</span>
        </Button>
        <Button
          v-else-if="attempts.length < task.max_attempts"
          variant="default"
          size="xs"
          class="w-min"
          @click="startTaskPreview(task)"
        >
          <PlayIcon class="mr-2 size-4" />
          <span>Start Task</span>
        </Button>
      </template>
    </template>
  </TopLine>
  <LoadingCover :isLoading centerScreen>
    <ReviewTask
      v-if="inAttempt && completed && task && taskAttempt"
      :taskAttemptId="taskAttempt.task_attempt_id"
      :taskPayload="task.payload"
      :attemptPayload="taskAttempt.payload"
      :inRubric
    />
    <PerformTask
      v-else-if="inAttempt && task"
      :taskPayload="task.payload"
      :attemptPayload="taskAttempt?.payload ?? null"
      :handleCompleted
      :submitting
    />
    <Activity
      v-else-if="activity"
      :activity
      :task
      :attempts
      :previewAttempts="allAttempts"
      :startTask="startTaskPreview"
      @selectTask="selectTask"
      @viewAttempt="viewAttempt"
      @dismissAttempt="dismissAttempt"
    />
  </LoadingCover>
</template>
