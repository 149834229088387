import type { AssignToCohortInput } from '../models/AssignToCohortInput';
import type { CompleteTaskAttemptInput } from '../models/CompleteTaskAttemptInput';
import type { CopyActivityToOrganizationInput } from '../models/CopyActivityToOrganizationInput';
import type { DismissTaskAttemptInput } from '../models/DismissTaskAttemptInput';
import type { GetAssignmentAccessOutput } from '../models/GetAssignmentAccessOutput';
import type { GetAssignmentInstanceStudentInsightOutput } from '../models/GetAssignmentInstanceStudentInsightOutput';
import type { GetAssignmentInstanceStudentInsightsOutput } from '../models/GetAssignmentInstanceStudentInsightsOutput';
import type { GetAssignmentOutputV2 } from '../models/GetAssignmentOutputV2';
import type { GetCohortAssignmentInsightsOutput } from '../models/GetCohortAssignmentInsightsOutput';
import type { GetCohortStudentActivitiesProgressOutput } from '../models/GetCohortStudentActivitiesProgressOutput';
import type { GetCohortStudentsProgressOutput } from '../models/GetCohortStudentsProgressOutput';
import type { GetGradedWrittenTaskRubricOutput } from '../models/GetGradedWrittenTaskRubricOutput';
import type { GetMyAssignmentInstanceOutput } from '../models/GetMyAssignmentInstanceOutput';
import type { GetMyAssignmentInstancesOutput } from '../models/GetMyAssignmentInstancesOutput';
import type { GetOrganizationAssignmentsOutput } from '../models/GetOrganizationAssignmentsOutput';
import type { GetTaskAttemptOutput } from '../models/GetTaskAttemptOutput';
import type { i32 } from '../models/i32';
import type { ResetLearnerProgressInput } from '../models/ResetLearnerProgressInput';
import type { SearchOrganizationAssignmentsOutput } from '../models/SearchOrganizationAssignmentsOutput';
import type { StartConversationTaskInput } from '../models/StartConversationTaskInput';
import type { StartPrelearningTaskInputV2 } from '../models/StartPrelearningTaskInputV2';
import type { SubmitFeedbackTaskInput } from '../models/SubmitFeedbackTaskInput';
import type { SubmitGradedWrittenTaskInput } from '../models/SubmitGradedWrittenTaskInput';
import type { SubmitReflectionTaskInputV2 } from '../models/SubmitReflectionTaskInputV2';
import type { SyncAssignmentToRegionsInput } from '../models/SyncAssignmentToRegionsInput';
import type { UpdateAssignmentAccessInput } from '../models/UpdateAssignmentAccessInput';
import type { UpdateAssignmentInputV2 } from '../models/UpdateAssignmentInputV2';
import type { UpdateAssignmentTasksInput } from '../models/UpdateAssignmentTasksInput';
import type { UpdateDueDateInput } from '../models/UpdateDueDateInput';
import type { VoidAssignmentInstanceInput } from '../models/VoidAssignmentInstanceInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';



export class AssignmentV2Service {

	/**
	 * AssignToCohort
	 * Assign an assignment to a cohort, creating an assignment instance.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static assignToCohortEndpoint(
requestBody: AssignToCohortInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/AssignToCohort',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * CompleteTaskAttempt
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static completeTaskAttemptEndpoint(
requestBody: CompleteTaskAttemptInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/CompleteTaskAttempt',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * CopyActivityToOrganization
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static copyActivityToOrganizationEndpoint(
requestBody: CopyActivityToOrganizationInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/CopyActivityToOrganization',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * CreateActivityFromAssignment
	 * @param assignmentId The ID of the assignment to create.
	 * @param activityId The ID of the activity to create.
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static createActivityFromAssignmentEndpoint(
assignmentId: string,
activityId: string,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/CreateActivityFromAssignment',
			query: {
				assignment_id: assignmentId, activity_id: activityId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * CreateAssignment
	 * @param assignmentId The ID of the assignment to create.
	 * @param name The assignment name.
	 * @param description The assignment description.
	 * @param owningOrganizationId The ID of the assignment's owning organization.
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static createAssignmentEndpointV2(
assignmentId: string,
name: string,
description: string,
owningOrganizationId: string,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/CreateAssignment',
			query: {
				assignment_id: assignmentId, name, description, owning_organization_id: owningOrganizationId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * DeleteAssignment
	 * @param assignmentId The ID of the assignment to delete.
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static deleteAssignmentEndpointV2(
assignmentId: string,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/DeleteAssignment',
			query: {
				assignment_id: assignmentId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * DismissTaskAttempt
	 * Marks a task attempt as dismissed.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static dismissTaskAttemptEndpoint(
requestBody: DismissTaskAttemptInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/DismissTaskAttempt',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetAssignment
	 * @param assignmentId The ID of the assignment to retrieve.
	 * @returns GetAssignmentOutputV2 Success.
	 * @throws ApiError
	 */
	public static getAssignmentEndpointV2(
assignmentId: string,
): CancelablePromise<GetAssignmentOutputV2> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment_v2/GetAssignment',
			query: {
				assignment_id: assignmentId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetAssignmentAccess
	 * @param assignmentId The ID of the assignment.
	 * @returns GetAssignmentAccessOutput Success.
	 * @throws ApiError
	 */
	public static getAssignmentAccessEndpoint(
assignmentId: string,
): CancelablePromise<GetAssignmentAccessOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment_v2/GetAssignmentAccess',
			query: {
				assignment_id: assignmentId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetAssignmentInstanceStudentInsight
	 * @param assignmentInstanceId The ID of the assignment instance to get student insight for.
	 * @param userId The ID of the student to get insight for.
	 * @returns GetAssignmentInstanceStudentInsightOutput Success.
	 * @throws ApiError
	 */
	public static getAssignmentInstanceStudentInsightEndpoint(
assignmentInstanceId: string,
userId: string,
): CancelablePromise<GetAssignmentInstanceStudentInsightOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment_v2/GetAssignmentInstanceStudentInsight',
			query: {
				assignment_instance_id: assignmentInstanceId, user_id: userId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetAssignmentInstanceStudentInsights
	 * @param assignmentInstanceId The ID of the assignment instance to get student insights for.
	 * @returns GetAssignmentInstanceStudentInsightsOutput Success.
	 * @throws ApiError
	 */
	public static getAssignmentInstanceStudentInsightsEndpoint(
assignmentInstanceId: string,
): CancelablePromise<GetAssignmentInstanceStudentInsightsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment_v2/GetAssignmentInstanceStudentInsights',
			query: {
				assignment_instance_id: assignmentInstanceId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetCohortAssignmentInsights
	 * @param cohortId The ID of the cohort to get assignment insights for.
	 * @returns GetCohortAssignmentInsightsOutput Success.
	 * @throws ApiError
	 */
	public static getCohortAssignmentInsightsEndpoint(
cohortId: string,
): CancelablePromise<GetCohortAssignmentInsightsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment_v2/GetCohortAssignmentInsights',
			query: {
				cohort_id: cohortId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetCohortStudentActivitiesProgress
	 * @param cohortId The ID of the cohort to get student activity progress for.
	 * @param userId The ID of the student to get progress for.
	 * @returns GetCohortStudentActivitiesProgressOutput Success.
	 * @throws ApiError
	 */
	public static getCohortStudentActivitiesProgressEndpoint(
cohortId: string,
userId: string,
): CancelablePromise<GetCohortStudentActivitiesProgressOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment_v2/GetCohortStudentActivitiesProgress',
			query: {
				cohort_id: cohortId, user_id: userId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetCohortStudentsProgress
	 * @param cohortId The ID of the cohort to get students progress for.
	 * @returns GetCohortStudentsProgressOutput Success.
	 * @throws ApiError
	 */
	public static getCohortStudentsProgressEndpoint(
cohortId: string,
): CancelablePromise<GetCohortStudentsProgressOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment_v2/GetCohortStudentsProgress',
			query: {
				cohort_id: cohortId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetGradedWrittenTaskRubric
	 * @param taskAttemptId The ID of the task attempt to get the rubric of.
	 * @returns GetGradedWrittenTaskRubricOutput Success.
	 * @throws ApiError
	 */
	public static getGradedWrittenTaskRubricEndpoint(
taskAttemptId: string,
): CancelablePromise<GetGradedWrittenTaskRubricOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment_v2/GetGradedWrittenTaskRubric',
			query: {
				task_attempt_id: taskAttemptId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetMyAssignmentInstances
	 * @param assignmentInstanceId The ID of the assignment instance.
	 * @returns GetMyAssignmentInstanceOutput Success.
	 * @throws ApiError
	 */
	public static getMyAssignmentInstanceEndpoint(
assignmentInstanceId: string,
): CancelablePromise<GetMyAssignmentInstanceOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment_v2/GetMyAssignmentInstance',
			query: {
				assignment_instance_id: assignmentInstanceId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetMyAssignmentInstances
	 * @returns GetMyAssignmentInstancesOutput Success.
	 * @throws ApiError
	 */
	public static getMyAssignmentInstancesEndpoint(): CancelablePromise<GetMyAssignmentInstancesOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment_v2/GetMyAssignmentInstances',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetOrganizationAssignments
	 * @param organizationId The ID of the organization to retrieve assignments for.
	 * @param limit The maximum number of search results.
	 * @param cursor If `null`, returns the first page.
 * 
 * If non-null, returns the page corresponding to this cursor. See the
 * `next_page` field in the response.
	 * @returns GetOrganizationAssignmentsOutput Success.
	 * @throws ApiError
	 */
	public static getOrganizationAssignmentsEndpoint(
organizationId: string,
limit: i32,
cursor?: string | null,
): CancelablePromise<GetOrganizationAssignmentsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment_v2/GetOrganizationAssignments',
			query: {
				organization_id: organizationId, limit, cursor
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetTaskAttempt
	 * @param taskAttemptId The ID of the task attempt to get details for.
	 * @returns GetTaskAttemptOutput Success.
	 * @throws ApiError
	 */
	public static getTaskAttemptEndpoint(
taskAttemptId: string,
): CancelablePromise<GetTaskAttemptOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment_v2/GetTaskAttempt',
			query: {
				task_attempt_id: taskAttemptId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * ResetLearnerProgress
	 * Marks all attempts on all tasks by a learner on an activity as dismissed.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static resetLearnerProgressEndpoint(
requestBody: ResetLearnerProgressInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/ResetLearnerProgress',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * SearchOrganizationAssignments
	 * @param organizationId The ID of the organization to search assignments for.
	 * @param query The search query string.
	 * @returns SearchOrganizationAssignmentsOutput Success.
	 * @throws ApiError
	 */
	public static searchOrganizationAssignmentsEndpoint(
organizationId: string,
query: string,
): CancelablePromise<SearchOrganizationAssignmentsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment_v2/SearchOrganizationAssignments',
			query: {
				organization_id: organizationId, query
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * StartConversationTask
	 * Start a conversation task.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static startConversationTaskEndpoint(
requestBody: StartConversationTaskInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/StartConversationTask',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * StartPrelearningTask
	 * Start a prelearning task.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static startPrelearningTaskEndpointV2(
requestBody: StartPrelearningTaskInputV2,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/StartPrelearningTask',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * SubmitFeedbackTask
	 * Submit a feedback task.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static submitFeedbackTaskEndpoint(
requestBody: SubmitFeedbackTaskInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/SubmitFeedbackTask',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * SubmitGradedWrittenTask
	 * Submit a graded written task.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static submitGradedWrittenTaskEndpoint(
requestBody: SubmitGradedWrittenTaskInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/SubmitGradedWrittenTask',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * SubmitReflectionTask
	 * Submit a reflection task.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static submitReflectionTaskEndpoint(
requestBody: SubmitReflectionTaskInputV2,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/SubmitReflectionTask',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * SyncAssignmentToRegions
	 * Syncs an assignment to other regions.
	 * @param requestBody 
	 * @returns unknown SyncAssignmentToRegions.
	 * @throws ApiError
	 */
	public static syncAssignmentToRegionsEndpoint(
requestBody: SyncAssignmentToRegionsInput,
): CancelablePromise<unknown> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/SyncAssignmentToRegions',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * UpdateAssignment
	 * Updates an existing assignment.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static updateAssignmentEndpointV2(
requestBody: UpdateAssignmentInputV2,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/UpdateAssignment',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * UpdateAssignmentAccess
	 * Updates the access list of an assignment.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static updateAssignmentAccessEndpoint(
requestBody: UpdateAssignmentAccessInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/UpdateAssignmentAccess',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * UpdateAssignmentTasks
	 * Updates an existing assignment's task list.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static updateAssignmentTasksEndpoint(
requestBody: UpdateAssignmentTasksInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/UpdateAssignmentTasks',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * UpdateDueDate
	 * Update the due date of an assignment instance.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static updateDueDateEndpoint(
requestBody: UpdateDueDateInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/UpdateDueDate',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * VoidAssignmentInstance
	 * Mark an assignment instance as void.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static voidAssignmentInstanceEndpoint(
requestBody: VoidAssignmentInstanceInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment_v2/VoidAssignmentInstance',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

}