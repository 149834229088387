<script setup lang="ts">
import type { CreateRubricInput, GetRubricInput, RubricOutput } from '@/open-api/generated'
import {
  DocumentDuplicateIcon,
  LockOpenIcon,
  TrashIcon,
  PlayIcon,
  CodeBracketSquareIcon,
  ChevronLeftIcon
} from '@heroicons/vue/24/outline'
import { onBeforeMount, ref, computed, watch } from 'vue'
import { useContentStore } from '@/stores/content'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomDeleteModal from '@/components/utils/CustomDeleteModal.vue'
import CustomModal from '@/components/utils/CustomModal.vue'
import CustomInput from '@/components/utils/CustomInput.vue'
import Api from '@/open-api'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import { useAuthStore } from '@/stores/auth'
import dayjs from 'dayjs'
import { useRoute, useRouter } from 'vue-router'
import useSearchCharacters from '@/composables/api/queries/useSearchCharacters'
import useStartConversation from '@/composables/api/mutations/useStartConversation'
import { CommandState } from '@/types/api'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose
} from '@/components/modern/ui/dialog'
import { SearchSelect, organizationConfig } from '@/components/modern/ui/search-select'
import { Button } from '@/components/modern/ui/button'
import { Textarea } from '@/components/modern/ui/textarea'
import { CopyIcon, Cross1Icon } from '@radix-icons/vue'
import useAllOrganizations from '@/composables/api/useAllOrganizations'
import useScrollToTop from '@/composables/useScrollToTop'
import useCopyRubricToOrganization from '@/composables/api/mutations/useCopyRubricToOrganization'
import { Breadcrumb } from '@/components/modern/page-navigation'

definePage({
  name: 'Rubric',
  meta: {
    permissionLevel: 'Educator',
    requiresAuthoring: true
  }
})

// ==================================================
// Init
// ==================================================
const route = useRoute<'Rubric' | 'Rubric View' | 'Rubric Access Controls'>()
const router = useRouter()
const contentStore = useContentStore()
const notificationStore = useNotificationStore()
const authStore = useAuthStore()
const isReadOnly = computed(() => {
  return (
    route.params.rubricId !== 'new' &&
    !authStore.isAtLeastStaffUser &&
    !(
      authStore.organizationId &&
      authStore.organizationId ===
        (contentStore.editingRubric as RubricOutput)?.owning_organization_id
    )
  )
})

const rubricLoading = ref(false)
const saveLoading = ref(false)

const { state, execute, reset } = useStartConversation({ notificationStore })
const previewLoading = computed((): boolean => state.value === CommandState.IN_PROGRESS)

const deleteRubricModalStatus = ref(false)
const deleteRubricLoading = ref(false)

const copyModalOpen = ref<boolean>(false)
const targetOrganizationId = ref<string | undefined>(undefined)
const { organizations, organizationsLoading } = useAllOrganizations({ notificationStore })
const scrollToTop = useScrollToTop()
const rubricId = computed<string>(() => route.params.rubricId)
const copyRubric = useCopyRubricToOrganization({
  rubricId,
  targetOrganizationId,
  notificationStore
})

const copyRubricToOrganization = async () => {
  const { execute, reset } = copyRubric
  const newRubricId = await execute().finally(reset)

  if (newRubricId) {
    copyModalOpen.value = false
    const targetOrganization = organizations.value.find(
      (org) => org.organization_id === targetOrganizationId.value
    )
    if (targetOrganization) {
      notificationStore.addWARNING(`Now viewing copy belonging to ${targetOrganization.name}.`)
    }
    router.push({
      name: 'Rubric View',
      params: { rubricId: newRubricId }
    })
    scrollToTop()
  }
}

onBeforeMount(async () => {
  setCurrentRubric()
})

// ==================================================
// Rubric
// ==================================================
const rubricNotComplete = computed(() => {
  const { public_label, internal_description, internal_label, minimum_passing_score } =
    contentStore.editingRubric
  return (
    !public_label?.trim() ||
    !internal_description?.trim() ||
    !internal_label?.trim() ||
    !minimum_passing_score ||
    minimum_passing_score <= 0
  )
})

const setCurrentRubric = async () => {
  if (route.params.rubricId === 'new') {
    contentStore.setEditingRubric(contentStore.newRubric)
  } else if ((contentStore.editingRubric as RubricOutput)?.rubric_id !== route.params.rubricId) {
    rubricLoading.value = true
    try {
      const rubric = await Api.Content.getRubricEndpoint(route.params.rubricId as string)

      if (!rubric) {
        router.push({ name: 'Rubrics List' })
      }

      contentStore.setEditingRubric(rubric as GetRubricInput)
    } catch (err: any) {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    } finally {
      rubricLoading.value = false
    }
  }
}
watch(() => route.params.rubricId, setCurrentRubric)

// ==================================================
// Create Rubric
// ==================================================
const createRubric = () => {
  saveLoading.value = true

  Api.Content.createRubricEndpoint(contentStore.editingRubric as CreateRubricInput)
    .then((res) => {
      notificationStore.addNotification({
        subtitle: 'Rubric successfully created',
        status: NotificationStatus.SUCCESS
      })
      router
        .push({
          name: route.name,
          params: { rubricId: res.rubric_id }
        })
        .then(async () => {
          await setCurrentRubric()
        })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      saveLoading.value = false
    })
}

// ==================================================
// Duplicate Rubric
// ==================================================
const duplicateRubricLoading = ref(false)
const duplicateRubric = async () => {
  duplicateRubricLoading.value = true

  const editingRubric = contentStore.editingRubric as RubricOutput
  const duplicatedRubric: CreateRubricInput = {
    public_label: `Copy of ${editingRubric.public_label}`,
    internal_label: `Copy of ${editingRubric.internal_label} ${dayjs().format(
      'DD/MM/YYYY HH:mm:ss'
    )}`,
    internal_description: editingRubric.internal_description,
    minimum_passing_score: editingRubric.minimum_passing_score,
    sections: editingRubric.sections,
    allowlist: editingRubric.allowlist?.map((org) => org.organization_id) || []
  }

  await Api.Content.createRubricEndpoint(duplicatedRubric)
    .then(() => {
      notificationStore.addNotification({
        subtitle: `${editingRubric.public_label} successfully cloned`,
        status: NotificationStatus.SUCCESS
      })

      router.push({
        name: 'Rubrics List'
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      duplicateRubricLoading.value = false
    })
}

// ==================================================
// Delete Rubric
// ==================================================
const confirmDeleteRubric = () => {
  deleteRubricLoading.value = true
  Api.Content.deleteRubricEndpoint({
    rubric_id: route.params.rubricId as string
  })
    .then(() => {
      deleteRubricModalStatus.value = false

      router.push({
        name: 'Rubrics List'
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      deleteRubricLoading.value = false
    })
}

// ==================================================
//  Simulate Rubric
// ==================================================
const previewCharacterId = ref('')
const characterSelectModalOpen = ref(false)
const {
  query,
  characters: characterSearchResults,
  isLoading: isCharacterSearchLoading
} = useSearchCharacters({ notificationStore, authStore })

const onPreviewCharacterSearch = (searchString: string) => {
  // Need to do this because custom input clears the search string on selection
  if (searchString) {
    query.value = searchString
  }
}
const previewRubric = async () => {
  if (!('rubric_id' in contentStore.editingRubric && contentStore.editingRubric.rubric_id)) {
    notificationStore.addNotification({
      subtitle: 'You need to save the rubric first.',
      status: NotificationStatus.WARNING
    })
    return
  }
  const rubricId = ref<string>(contentStore.editingRubric.rubric_id)

  if (!previewCharacterId.value) {
    notificationStore.addNotification({
      subtitle: 'You need to select a character first.',
      status: NotificationStatus.DANGER
    })
    return
  }

  const conversationId = await execute({
    rubricId,
    characterId: previewCharacterId
  })

  if (state.value === CommandState.SUCCESS && conversationId) {
    reset()
    router.push({
      name: 'Cohort Conversation',
      params: { conversationId }
    })
  } else {
    notificationStore.addDANGER('Sorry, there was an error trying to start the conversation.')
    reset()
  }
}

// dump to json
const dumpRubric = async () => {
  if (authStore.isSuperAdminUser) {
    const rubric = contentStore.editingRubric as RubricOutput
    const rubricJson = JSON.stringify(rubric, null, 2)
    await navigator.clipboard.writeText(rubricJson)
    notificationStore.addNotification({
      subtitle: 'Rubric JSON copied to clipboard',
      status: NotificationStatus.SUCCESS
    })
  }
}

// import from json
const importJson = ref('')
const importJsonLoading = ref(false)
const importJsonRubric = async () => {
  if (authStore.isSuperAdminUser) {
    let rubric: CreateRubricInput
    try {
      rubric = JSON.parse(importJson.value) as CreateRubricInput
    } catch (err: any) {
      console.log(err)
      notificationStore.addNotification({
        subtitle: 'Invalid JSON. Error logged to console.',
        status: NotificationStatus.WARNING
      })
      return
    }
    importJsonLoading.value = true
    try {
      const res = await Api.Content.createRubricEndpoint(rubric)
      notificationStore.addNotification({
        subtitle: 'Rubric successfully imported.',
        status: NotificationStatus.SUCCESS
      })
      router.push({
        name: 'Rubric View',
        params: { rubricId: res.rubric_id }
      })
      notificationStore.addNotification({
        subtitle: 'Now viewing imported rubric.',
        status: NotificationStatus.INFO
      })
    } catch (err: any) {
      notificationStore.addNotification({
        subtitle:
          err?.body?.message ||
          'Error importing rubric (valid JSON object, but does not conform to rubric schema).',
        status: NotificationStatus.DANGER
      })
    } finally {
      importJsonLoading.value = false
    }
  }
}
</script>

<template>
  <Breadcrumb :route="{ name: 'Rubric View' }" :name="contentStore.editingRubric?.public_label" />
  <div :class="['flex min-h-full flex-col gap-y-5']">
    <div class="sticky -top-3 z-50 flex flex-col gap-3 bg-white pt-3">
      <div
        v-if="route.name !== 'Rubric Access Controls'"
        class="flex cursor-pointer items-center gap-2 px-5 text-sc-grey-700"
        @click="router.push({ name: 'Rubrics List' })"
      >
        <ChevronLeftIcon class="h-4 w-4" />
        <h4 class="font-normal text-sc-grey-700">Back to all rubrics</h4>
      </div>
      <div class="flex justify-between px-5">
        <h1 class="mr-5 pr-10 text-2xl font-medium">
          {{
            route.query?.mode === 'create'
              ? 'Create Rubric'
              : contentStore.editingRubric.public_label
          }}
        </h1>
        <CustomButton v-if="isReadOnly" buttonType="grey" buttonSize="sm" :canSelect="false">
          READ-ONLY
        </CustomButton>

        <div class="flex gap-3">
          <CustomButton
            v-if="authStore.isSuperAdminUser && route.params.rubricId !== 'new'"
            buttonType="admin-primary"
            :startIcon="CodeBracketSquareIcon"
            @click="dumpRubric"
          >
            Copy JSON
          </CustomButton>
          <template v-if="authStore.isSuperAdminUser && route.params.rubricId === 'new'">
            <Dialog>
              <DialogTrigger asChild>
                <CustomButton buttonType="admin-primary" :startIcon="CodeBracketSquareIcon">
                  Import JSON
                </CustomButton>
              </DialogTrigger>
              <DialogContent class="max-w-4xl">
                <Textarea v-model="importJson" class="h-full min-h-[60vh] w-full font-mono" />
                <DialogFooter>
                  <DialogClose asChild>
                    <Button variant="outline" size="xs">
                      <Cross1Icon class="mr-2 size-4" />
                      <span>Cancel</span>
                    </Button>
                  </DialogClose>
                  <Button
                    variant="default"
                    size="xs"
                    :disabled="importJsonLoading"
                    @click="importJsonRubric"
                  >
                    <DocumentDuplicateIcon class="mr-2 size-4" />
                    <span>Create</span>
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </template>
          <CustomButton
            v-if="route.name === 'Rubric Access Controls'"
            buttonType="admin-secondary"
            @click="router.push({ name: 'Rubric View' })"
          >
            Back to Rubric
          </CustomButton>
          <CustomButton
            v-if="route.params.rubricId === 'new'"
            :disabled="rubricNotComplete"
            :loading="saveLoading"
            buttonType="admin-primary"
            @click="createRubric"
          >
            Create Rubric
          </CustomButton>

          <!-- Copy rubric to organization modal -->
          <template
            v-if="
              route.params.rubricId !== 'new' &&
              authStore.isAtLeastStaffUser &&
              authStore.flagAuthoringEnabled &&
              route.name !== 'Rubric Access Controls'
            "
          >
            <Dialog v-model:open="copyModalOpen">
              <DialogTrigger asChild>
                <CustomButton buttonType="admin-secondary" :startIcon="CopyIcon">
                  Copy to Organization
                </CustomButton>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Copy rubric to organization</DialogTitle>
                  <DialogDescription>
                    Select an organization to copy this rubric to.
                  </DialogDescription>
                </DialogHeader>
                <div class="min-w-full">
                  <SearchSelect
                    v-bind="organizationConfig"
                    v-model:value="targetOrganizationId"
                    :data="organizations"
                    :isLoading="organizationsLoading"
                  />
                </div>
                <DialogFooter>
                  <DialogClose asChild>
                    <Button variant="outline" size="xs">
                      <Cross1Icon class="mr-2 size-4" />
                      <span>Cancel</span>
                    </Button>
                  </DialogClose>
                  <Button
                    variant="default"
                    size="xs"
                    :disabled="copyRubric.state.value === CommandState.IN_PROGRESS"
                    @click="copyRubricToOrganization"
                  >
                    <CopyIcon class="mr-2 size-4" />
                    <span>Copy</span>
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </template>

          <CustomButton
            v-if="
              route.params.rubricId !== 'new' &&
              route.name !== 'Rubric Access Controls' &&
              !isReadOnly
            "
            buttonType="admin-secondary"
            :startIcon="PlayIcon"
            :loading="previewLoading"
            @click="characterSelectModalOpen = true"
          >
            Preview Rubric
          </CustomButton>
          <CustomButton
            v-if="
              route.params.rubricId !== 'new' &&
              route.name !== 'Rubric Access Controls' &&
              !isReadOnly
            "
            :disabled="duplicateRubricLoading"
            buttonType="admin-secondary"
            :startIcon="DocumentDuplicateIcon"
            @click="duplicateRubric"
          />
          <CustomButton
            v-if="
              route.params.rubricId !== 'new' &&
              authStore.isAtLeastStaffUser &&
              route.name !== 'Rubric Access Controls' &&
              !isReadOnly
            "
            buttonType="admin-secondary"
            :startIcon="LockOpenIcon"
            @click="router.push({ name: 'Rubric Access Controls' })"
          />
          <CustomButton
            v-if="
              route.params.rubricId !== 'new' &&
              route.name !== 'Rubric Access Controls' &&
              !isReadOnly
            "
            buttonType="admin-secondary"
            :startIcon="TrashIcon"
            @click="deleteRubricModalStatus = true"
          />
        </div>
      </div>
    </div>

    <div v-if="rubricLoading" class="flex h-full w-full items-center justify-center">
      <AppLoadingSpinner class="py-20" :loading="rubricLoading" />
    </div>
    <RouterView v-else class="px-5" />
  </div>

  <!-- Delete modal -->
  <CustomDeleteModal
    title="Delete Rubric"
    message="Are you sure you want to delete this rubric? This cannot be undone."
    :modalStatus="deleteRubricModalStatus"
    :loading="deleteRubricLoading"
    @confirm="confirmDeleteRubric"
    @cancel="deleteRubricModalStatus = false"
  />
  <!-- Preview Character Selection Modal -->
  <CustomModal v-model="characterSelectModalOpen" @onClose="characterSelectModalOpen = false">
    <div class="flex w-full flex-col gap-5">
      <h3 class="basis-auto text-xl">Select a character to test with</h3>
      <div class="min-w-full">
        <CustomInput
          v-model="previewCharacterId"
          inputType="select-search"
          :options="
            characterSearchResults.map((res) => ({
              name: res.internal_label,
              value: res.character_id
            }))
          "
          label="Select Character"
          :loading="isCharacterSearchLoading"
          @search="onPreviewCharacterSearch"
        />
      </div>
      <div class="flex justify-between gap-5">
        <CustomButton
          buttonType="admin-secondary"
          class="basis-auto self-end"
          @click="characterSelectModalOpen = false"
        >
          Cancel
        </CustomButton>

        <CustomButton
          :startIcon="PlayIcon"
          buttonType="admin-primary"
          class="basis-auto self-end"
          @click="previewRubric"
        >
          Preview Rubric
        </CustomButton>
      </div>
    </div>
  </CustomModal>
</template>
