<script setup lang="ts">
import { provide } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import useGetOrSearchOrganisationAssignments, {
  useGetOrSearchOrganisationAssignmentsKey
} from '@/composables/api/queries/useGetOrSearchOrganisationAssignments'
import { Breadcrumb } from '@/components/modern/page-navigation'

definePage({
  name: 'All Activities Provider',
  redirect: 'Modern Activities - All Activities List',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

const getOrSearchOrganisationAssignments = useGetOrSearchOrganisationAssignments({
  authStore,
  notificationStore
})
provide(useGetOrSearchOrganisationAssignmentsKey, getOrSearchOrganisationAssignments)
</script>

<template>
  <Breadcrumb :route="{ name: 'Modern Activities - All Activities List' }" name="Activities" />
  <RouterView />
</template>
