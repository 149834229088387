// UI Hint icons

import {
  PencilSquareIcon,
  ListBulletIcon,
  UserCircleIcon,
  UserIcon,
  IdentificationIcon,
  ClipboardDocumentListIcon,
  ClipboardDocumentCheckIcon,
  SpeakerWaveIcon,
  AcademicCapIcon,
  FlagIcon,
  BuildingOffice2Icon,
  InboxArrowDownIcon
} from '@heroicons/vue/24/outline'

export const ActivityIcon = PencilSquareIcon
export const TaskIcon = ListBulletIcon

export const RubricIcon = ClipboardDocumentListIcon
export const SectionIcon = ClipboardDocumentCheckIcon

export const MandatoryIcon = FlagIcon
export const AssignIcon = InboxArrowDownIcon

export const CharacterIcon = UserCircleIcon
export const VoiceIcon = SpeakerWaveIcon

export const OrganizationIcon = BuildingOffice2Icon
export const CohortIcon = AcademicCapIcon
export const LearnerIcon = UserIcon
export const EducatorIcon = IdentificationIcon
