<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import { schema, type Schema } from '@/composables/api/mutations/schema/useCreateVoice'
import { CommandState } from '@/types/api'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { AutoForm } from '@/components/modern/ui/auto-form'
import { Breadcrumb, TopLine, BackButton } from '@/components/modern/page-navigation'
import { Button } from '@/components/modern/ui/button'
import { PlusIcon } from '@radix-icons/vue'
import useCreateVoice from '@/composables/api/mutations/useCreateVoice'
import { injectUseListVoices } from '@/composables/api/queries/useListVoices'

definePage({
  name: 'New Voice',
  meta: {
    permissionLevel: 'SuperAdmin',
    isModern: true
  }
})
const router = useRouter()
const authStore = useAuthStore()
const notificationStore = useNotificationStore()

const { refetch } = injectUseListVoices({ authStore, notificationStore })

const { state, execute, reset } = useCreateVoice({
  notificationStore
})

const form = useForm({ validationSchema: toTypedSchema(schema) })

const submit = form.handleSubmit(async (values: Schema) => {
  const voiceId = await execute(values)
  reset()
  if (voiceId) {
    refetch()
    router.push({
      name: 'Edit Voice',
      params: { voiceId }
    })
  }
})
</script>

<template>
  <Breadcrumb :route="{ name: 'New Voice' }" name="New" />
  <TopLine>
    <template #left>
      <BackButton :to="{ name: 'Voices List' }" name="all voices" />
    </template>
    <template #right>
      <Button variant="default" size="xs" @click="submit">
        <PlusIcon class="mr-2 size-4" />
        <span>Create</span>
      </Button>
    </template>
  </TopLine>
  <div class="mx-4 w-full max-w-xl self-center lg:mx-6">
    <AutoForm :schema :form class="mt-4">
      <Button
        variant="default"
        size="xs"
        :disabled="state === CommandState.IN_PROGRESS"
        @click="submit"
      >
        <PlusIcon class="mr-2 size-4" />
        <span>Create</span>
      </Button>
    </AutoForm>
  </div>
</template>
