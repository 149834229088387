<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useNotificationStore } from '@/stores/notifications'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import useFirstSecondIds from '@/composables/useFirstSecondIds'
import useCombineQueries from '@/composables/api/useCombineQueries'
import { injectUseGetAssignmentInstanceStudentInsight } from '@/composables/api/queries/useGetAssignmentInstanceStudentInsight'
import { injectUseGetCohortStudentsProgress } from '@/composables/api/queries/useGetCohortStudentsProgress'
import { injectUseGetCohortAssignmentInsights } from '@/composables/api/queries/useGetCohortAssignmentInsights'
import useDismissTaskAttempt from '@/composables/api/mutations/useDismissTaskAttempt'
import { MultiTable } from '@/components/modern/ui/data-table'
import { useColumns, useGroups } from '@/components/modern/tables/task-attempts'
import { Button } from '@/components/modern/ui/button'
import { DownloadIcon } from '@radix-icons/vue'
import { LoadingCover } from '@/components/modern/ui/loading'

definePage({
  name: 'Modern Educator - Student Attempts on Task',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const route = useRoute('Modern Educator - Student Attempts on Task')
const cohortId = computed(() => route.params.cohortId)
const firstId = computed(() => route.params.firstId)
const secondId = computed(() => route.params.secondId)
// We can arrive at this page from `cohort/learners/...`, or from `cohort/activities/...`
// Examine the id prefix to determine from whence we came, and to where we shall return.
const { userId, assignmentInstanceId, firstIdIsUserId } = useFirstSecondIds({
  firstId,
  secondId
})

const notificationStore = useNotificationStore()

const { track, isLoading, refetch } = useCombineQueries()
const { tasks } = track(
  injectUseGetAssignmentInstanceStudentInsight({
    assignmentInstanceId,
    userId,
    notificationStore
  })
)
track(injectUseGetCohortStudentsProgress({ cohortId, notificationStore }))
track(injectUseGetCohortAssignmentInsights({ cohortId, notificationStore }))

const taskAttemptId = ref<string>('')
const { execute, reset } = useDismissTaskAttempt({ taskAttemptId, notificationStore })
const requestDismissTaskAttempt = (attemptId: string) => (taskAttemptId.value = attemptId)
const confirmDismissTaskAttempt = async () => execute().finally(reset).finally(refetch)

const data = computed(() => tasks.value.flatMap((task) => task.attempts))
const columns = useColumns({
  cohortId,
  firstId,
  secondId,
  requestDismissTaskAttempt,
  confirmDismissTaskAttempt
})
const groups = useGroups(tasks)
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton
        v-if="firstIdIsUserId"
        :to="{
          name: 'Modern Cohorts - Student Progress',
          params: { cohortId, userId }
        }"
        name="learner"
      />
      <BackButton
        v-else
        :to="{
          name: 'Modern Cohorts - Activity Instance',
          params: { cohortId, assignmentInstanceId }
        }"
        name="activity"
      />
    </template>
    <template v-if="false" #right>
      <Button variant="default" size="xs" disabled>
        <DownloadIcon class="mr-2 size-4" />
        <span>Export</span>
      </Button>
    </template>
  </TopLine>
  <LoadingCover :isLoading centerScreen>
    <MultiTable :data :columns :groups :isLoading />
  </LoadingCover>
</template>
