<script setup lang="ts">
import { TaskTypeApiEnum, type Assignment } from '@/open-api/generated'
import type { ConversationTaskMeta, FeedbackTaskMeta } from '@/types/subtypes'
import type { Option } from '@/types/utils'
import { onBeforeMount, ref, computed, reactive } from 'vue'
import { ChevronLeftIcon } from '@heroicons/vue/24/outline'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import CustomTypeformModal from '@/components/utils/CustomTypeformModal.vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomDeleteModal from '@/components/utils/CustomDeleteModal.vue'
import Api from '@/open-api'
import CustomTabs, { type TabOption } from '@/components/utils/CustomTabs.vue'
import { useAssignmentStore } from '@/stores/assignment'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import { useRoute, useRouter } from 'vue-router'
// VR-related imports
import { isVrTaskPredicate } from '@/utils/vr'

definePage({
  name: 'Cohort Assignment Task',
  meta: {
    permissionLevel: 'Educator'
  }
})

// ==================================================
// Init
// ==================================================
const assignmentStore = useAssignmentStore()
const route = useRoute<
  | 'Cohort Assignment Task'
  | 'Cohort Assignment Task Details'
  | 'Cohort Assignment Task Insights'
  | 'Cohort Assignment Task Rubric'
>()
const router = useRouter()
const notificationStore = useNotificationStore()

// ==================================================
// VR
// ==================================================

// VR Task
// isVrTaskPredicate is a VR-free safe way to check a task for VR features
const isVrTask = computed(() => isVrTaskPredicate(assignmentStore.currentCohortTask))

// ==================================================
// assignment
// ==================================================
const assignmentLoading = ref(false)
const assignment = ref<Option<Assignment>>(null)

onBeforeMount(async () => {
  assignmentLoading.value = true
  Api.Assignment.getAssignmentEndpoint(route.params.assignmentId as string)
    .then((res) => {
      assignment.value = res.assignment
      const currentCohortTaskIndex = res.assignment.tasks.findIndex(
        (task) => task.task_id === route.params.taskId
      )

      if (currentCohortTaskIndex === -1) {
        router.push({ name: 'Cohorts' })
      }

      assignmentStore.setCurrentCohortTask(res.assignment.tasks[currentCohortTaskIndex])
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
      router.push({ name: 'Cohorts' })
    })
    .finally(() => {
      assignmentLoading.value = false
    })
})

// ==================================================
// Task Nav
// ==================================================
const tabOptions = computed<TabOption[]>(() => {
  let options: TabOption[] = [
    {
      displayName: 'Details',
      routeName: 'Cohort Assignment Task Details'
    },
    {
      displayName: 'Insights',
      routeName: 'Cohort Assignment Task Insights'
    },
    {
      displayName: 'Rubric',
      routeName: 'Cohort Assignment Task Rubric'
    }
  ]

  if (
    isVrTask.value ||
    assignmentStore.currentCohortTask?.task_type !== TaskTypeApiEnum.CONVERSATION_TASK
  ) {
    options = options.filter((opt) => opt.displayName === 'Details')
  }

  return options
})

// ==================================================
// Task
// ==================================================

const task = reactive({
  deleteLoading: false,
  deleteModalStatus: false
})

// ==================================================
// Typeform
// ==================================================

const typeform = reactive({
  url: '',
  modalStatus: false
})

// ==================================================
//  preview
// ==================================================
const previewLoading = ref(false)

const previewTask = () => {
  if (assignmentStore.currentCohortTask) {
    const query: Record<string, string> = {
      preview: 'preview'
    }

    if (isVrTask.value) {
      // VR task
      const { scene_id } = (assignmentStore.currentCohortTask.meta as ConversationTaskMeta)
        .ConversationTask

      query.sceneId = scene_id as string
    } else if (assignmentStore.currentCohortTask.task_type === TaskTypeApiEnum.CONVERSATION_TASK) {
      // Conversation task
      const { character_id, rubric_id } = (
        assignmentStore.currentCohortTask.meta as ConversationTaskMeta
      ).ConversationTask

      query.characterId = character_id
      query.rubricId = rubric_id
    } else if (assignmentStore.currentCohortTask.task_type === TaskTypeApiEnum.FEEDBACK_TASK) {
      // // Feedback task
      const { typeform_link } = (assignmentStore.currentCohortTask.meta as FeedbackTaskMeta)
        .FeedbackTask
      query.typeformUrl = typeform_link
    }

    const routeData = router.resolve({
      name: 'MyAssignments Task',
      params: { assignmentId: route.params.assignmentId, taskId: route.params.taskId },
      query
    })

    // Add tasks to store for preview
    assignmentStore.addPreviewTask(assignmentStore.currentCohortTask)
    // Add assignment to store for preview
    assignmentStore.setPreviewAssignment(assignment.value!)

    window.open(routeData.href, '_blank')
  }
}

const editInTypeform = () => {
  if (!assignmentStore.currentCohortTask) {
    return
  }
  const { typeform_link } = (assignmentStore.currentCohortTask.meta as FeedbackTaskMeta)
    .FeedbackTask
  const typeformId = typeform_link.split('/')[4]
  if (typeformId) {
    window.open(`https://admin.typeform.com/form/${typeformId}/create`, '_blank')
  }
}

// ==================================================
// Delete
// ==================================================

const deleteTask = () => {
  task.deleteLoading = true

  Api.Assignment.deleteTaskEndpoint({
    task_id: assignmentStore.currentCohortTask.task_id
  })
    .then(() => {
      router.push({ name: 'Cohort Assignment Details' })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      task.deleteLoading = true
    })
}
</script>

<template>
  <div
    :class="[
      'flex flex-col',
      {
        'h-full': ['Character Prompts', 'Character Access Controls'].includes(route.name as string)
      }
    ]"
  >
    <div class="sticky -top-3 z-50 flex flex-col gap-5 bg-white pb-5 pt-3">
      <div
        class="flex cursor-pointer items-center gap-2 px-5 text-sc-grey-700"
        @click="router.push({ name: 'Cohort Assignment Details' })"
      >
        <ChevronLeftIcon class="h-4 w-4" />
        <h4 class="font-normal text-sc-grey-700">Back to Assignment</h4>
      </div>
      <div class="flex justify-between px-5">
        <div class="flex flex-row items-center gap-1">
          <h1 class="mr-5 whitespace-nowrap text-2xl font-medium">
            {{ assignmentStore.currentCohortTask.name }}
          </h1>
        </div>

        <template v-if="route.name === 'Cohort Assignment Task Details'">
          <div class="flex gap-3">
            <CustomButton
              buttonType="admin-secondary"
              :loading="task.deleteLoading"
              @click="() => (task.deleteModalStatus = true)"
            >
              Delete
            </CustomButton>
            <CustomButton buttonType="admin-primary" :loading="previewLoading" @click="previewTask">
              Preview task
            </CustomButton>
            <CustomButton
              v-if="assignmentStore.currentCohortTask.task_type === TaskTypeApiEnum.FEEDBACK_TASK"
              buttonType="admin-primary"
              :loading="previewLoading"
              @click="editInTypeform"
            >
              Edit in Typeform
            </CustomButton>
          </div>
        </template>
      </div>
      <CustomTabs v-if="tabOptions.length > 1" :tabOptions />
    </div>

    <AppLoadingSpinner v-if="assignmentLoading" loading />
    <RouterView v-else class="px-5" />
  </div>

  <!---------------------------- Typeform ---------------------------->
  <CustomTypeformModal
    :modalStatus="typeform.modalStatus"
    :typeformUrl="typeform.url"
    class="[&_.dialog-panel]:h-full [&_.dialog-panel]:w-full"
    @onClose="typeform.modalStatus = false"
  />

  <!-- Delete modal -->
  <CustomDeleteModal
    title="Delete Character"
    message="Are you sure you want to delete this task? This cannot be undone."
    :modalStatus="task.deleteModalStatus"
    :loading="task.deleteLoading"
    @confirm="deleteTask"
    @cancel="task.deleteModalStatus = false"
  />
</template>
