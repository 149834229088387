<script setup lang="ts">
import { computed, provide } from 'vue'
import { useRoute } from 'vue-router'
import { Breadcrumb } from '@/components/modern/page-navigation'
import { useNotificationStore } from '@/stores/notifications'
import { injectUseGetCohortAssignmentInsights } from '@/composables/api/queries/useGetCohortAssignmentInsights'
import useGetAssignmentInstanceStudentInsights, {
  useGetAssignmentInstanceStudentInsightsKey
} from '@/composables/api/queries/useGetAssignmentInstanceStudentInsights'

definePage({
  name: 'Activity Instance Provider',
  redirect: 'Modern Cohorts - Activity Instance',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const route = useRoute('Activity Instance Provider')
const cohortId = computed(() => route.params.cohortId)
const assignmentInstanceId = computed(() => route.params.assignmentInstanceId)

const notificationStore = useNotificationStore()

const { activityInstances } = injectUseGetCohortAssignmentInsights({ cohortId, notificationStore })
const instance = computed(() =>
  activityInstances.value.find((a) => a.assignment_instance_id === assignmentInstanceId.value)
)

const getAssignmentInstanceStudentInsights = useGetAssignmentInstanceStudentInsights({
  assignmentInstanceId,
  notificationStore
})
provide(useGetAssignmentInstanceStudentInsightsKey, getAssignmentInstanceStudentInsights)
</script>

<template>
  <Breadcrumb
    :route="{
      name: 'Modern Cohorts - Activity Instance',
      params: { cohortId, assignmentInstanceId }
    }"
    :name="instance?.name"
  />
  <RouterView />
</template>
