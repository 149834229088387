<script setup lang="ts">
import { useSimulationStore } from '@/stores/simulation'
import CustomButton from '@/components/utils/CustomButton.vue'
import {
  ArrowDownTrayIcon,
  BookOpenIcon,
  ChevronLeftIcon,
  EllipsisHorizontalIcon,
  HandThumbUpIcon
} from '@heroicons/vue/24/outline'
import CustomModal from '@/components/utils/CustomModal.vue'
import { useNotificationStore } from '@/stores/notifications'
import { useUtilsStore } from '@/stores/utils'
import { useAuthStore } from '@/stores/auth'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import { EntryTypeApiEnum, type TranscriptEntryApi } from '@/open-api/generated'
import { computed, onBeforeMount, reactive, ref } from 'vue'
import Api from '@/open-api'
import { NotificationStatus } from '@/types/notification'
import CustomInput from '@/components/utils/CustomInput.vue'
import { useAssignmentStore } from '@/stores/assignment'
import CustomTabs, { type TabOption } from '@/components/utils/CustomTabs.vue'
import { useRoute, useRouter } from 'vue-router'
import type { VrConversationOutput } from '@/vr/types/vr'
import { useCurrentConversation } from '@/composables/useCurrentConversation'
import { generateTranscriptPdf } from '@/utils/pdf'
import usePerformedInvestigations from '@/composables/usePerformedInvestigations'
import { InvestigationModal } from '@/components/conversation'

definePage({
  name: 'History Transcript',
  meta: {
    permissionLevel: 'Student'
  }
})

// ==================================================
// Init
// ==================================================
const router = useRouter()
const route = useRoute('History Transcript')
const notificationStore = useNotificationStore()
const simulationStore = useSimulationStore()
const utilsStore = useUtilsStore()
const authStore = useAuthStore()
const assignmentStore = useAssignmentStore()

const {
  selectionId: selectedCharacter,
  currentConversation,
  currentConversationIndex
} = useCurrentConversation()

const historyTabOptions = computed<TabOption[]>(() => {
  if (currentConversation.value?.ended_at && currentConversation.value?.rubric_version_id) {
    return [
      { displayName: 'Transcript', routeName: 'History Transcript' },
      { displayName: 'Rubric', routeName: 'History Rubric' }
    ]
  } else {
    return [{ displayName: 'Transcript', routeName: 'History Transcript' }]
  }
})

// ==================================================
// Transcript
// ==================================================
const transcripts = ref<(TranscriptEntryApi & { editedMessage?: string })[][]>([])
const transcriptLoading = ref(false)

onBeforeMount(() => {
  if (!currentConversation.value?.ended_at) {
    if (!route.params.taskAttemptId) {
      router.push({ name: 'MyAssignments Conversation' })
      return
    }
  }

  transcriptLoading.value = true

  const axiosRequests = (
    route.params.conversationId !== 'vr-scene'
      ? [simulationStore?.conversation]
      : simulationStore?.vrConversation || []
  )?.map((character) => {
    return Api.Conversation.getTranscriptEndpoint(
      (character as VrConversationOutput)?.conversation_id ||
        (route.params.conversationId as string)
    )
  })

  Promise.all(axiosRequests)
    .then(async (res) => {
      transcripts.value = res.map((response) => response.entries)

      if (authStore.isSuperAdminUser && currentConversation.value?.ended_at) {
        await getEditedTranscripts()
      }
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      transcriptLoading.value = false
    })
})

const usedActions = computed(() => {
  return transcripts.value?.[currentConversationIndex.value]?.filter(
    (entry) => entry.entry_type === EntryTypeApiEnum.PERFORMED_ACTION
  )
})

const usedInvestigations = usePerformedInvestigations({
  conversation: currentConversation,
  transcript: () => transcripts.value?.[currentConversationIndex.value]
})

const generatePdf = async () => {
  const transcript = transcripts.value?.[currentConversationIndex.value]
  const characterName =
    currentConversation.value?.given_name + ' ' + currentConversation.value?.family_name
  if (transcript) {
    try {
      await generateTranscriptPdf(transcript, characterName)
    } catch (error) {
      notificationStore.addDANGER('Failed to generate the requested PDF.')
    }
  }
}

// ==================================================
// Investigation Modal
// ==================================================
const selectedInvestigationIndex = ref(0)
const modalStatus = ref(false)

const openModal = (index: number) => {
  selectedInvestigationIndex.value = index
  modalStatus.value = true
}

const closeModal = () => {
  modalStatus.value = false
  setTimeout(() => {
    selectedInvestigationIndex.value = 0
  }, 300)
}

// ==================================================
// Data Science
// ==================================================

const dataScience = reactive({
  modalStatus: false,
  correctedResponse: '',
  loading: false
})

const getEditedTranscripts = () => {
  dataScience.loading = true
  Api.DataScienceApi.getMessageCorrectionsEndpoint(
    transcripts.value[currentConversationIndex.value][0]?.conversation_id
  )
    .then((res) => {
      res.message_corrections.forEach((editedTranscript) => {
        const foundTranscriptMessageIndex = transcripts.value[
          currentConversationIndex.value
        ].findIndex(
          (transcript) => transcript.transcript_entry_id === editedTranscript.transcript_entry_id
        )

        transcripts.value[currentConversationIndex.value][
          foundTranscriptMessageIndex
        ].editedMessage = editedTranscript.corrected_text
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      dataScience.loading = false
    })
}

const selectedTranscriptEntry = ref<(TranscriptEntryApi & { editedMessage?: string }) | null>(null)

const openDataModal = (transcriptEntry: TranscriptEntryApi) => {
  dataScience.modalStatus = true
  selectedTranscriptEntry.value = transcriptEntry
  dataScience.correctedResponse = selectedTranscriptEntry.value?.editedMessage || ''
}

const submitNewResponse = (currentResponse = false) => {
  dataScience.loading = true
  Api.DataScienceApi.correctCharacterAnswerMessageEndpoint({
    transcript_entry_id: selectedTranscriptEntry.value?.transcript_entry_id || '',
    corrected_text: selectedTranscriptEntry.value?.character_text || dataScience.correctedResponse
  })
    .then(() => {
      notificationStore.addNotification({
        subtitle: currentResponse
          ? 'Response approved successfully'
          : 'Response updated successfully',
        status: NotificationStatus.SUCCESS
      })

      const foundTranscriptMessageIndex = transcripts.value[
        currentConversationIndex.value
      ].findIndex(
        (transcript) =>
          transcript.transcript_entry_id === selectedTranscriptEntry.value?.transcript_entry_id
      )

      transcripts.value[currentConversationIndex.value][foundTranscriptMessageIndex].editedMessage =
        currentResponse
          ? (selectedTranscriptEntry.value?.character_text as string)
          : dataScience.correctedResponse

      selectedTranscriptEntry.value = null
      dataScience.modalStatus = false
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      dataScience.loading = false
    })
}

const approveModelResponse = (transcriptEntry: TranscriptEntryApi) => {
  selectedTranscriptEntry.value = transcriptEntry
  submitNewResponse(true)
}

const closeDataModal = () => {
  dataScience.modalStatus = false
  selectedTranscriptEntry.value = null
}
</script>

<template>
  <div :class="['flex flex-col gap-y-5 md:h-full']">
    <div
      v-if="route.query.taskId && assignmentStore?.currentAssignment?.assignment_id"
      class="flex cursor-pointer items-center gap-2 px-3 text-sc-grey-700 md:px-5"
      @click="
        router.push({
          name: 'MyAssignments Task History',
          params: {
            assignmentId: assignmentStore?.currentAssignment?.assignment_id,
            taskId: route.query.taskId as string
          }
        })
      "
    >
      <ChevronLeftIcon class="h-4 w-4" />
      <h4 class="font-normal text-sc-grey-700">Back to My Assignments History</h4>
    </div>

    <div class="justify-between px-3 md:flex md:px-5">
      <h1 class="hidden whitespace-nowrap text-2xl font-medium md:flex">
        {{ `${currentConversation?.given_name} ${currentConversation?.family_name}` }}
      </h1>

      <div class="hidden items-center gap-3 md:flex">
        <CustomInput
          v-if="transcripts.length > 1 && simulationStore?.vrConversation"
          v-model="selectedCharacter"
          class="!my-0"
          :options="
            simulationStore?.vrConversation.map((character) => {
              return {
                name: `${character.given_name} ${character.family_name}`,
                value: character.conversation_id
              }
            })
          "
          placeholder="Select one..."
          :required="false"
          inputType="select"
          label="Character"
        />

        <CustomButton
          :startIcon="ArrowDownTrayIcon"
          buttonType="admin-secondary"
          @click="generatePdf"
        >
          Download PDF
        </CustomButton>
      </div>
    </div>

    <CustomTabs :tabOptions="historyTabOptions" />

    <div
      :class="['my-3 flex h-full flex-col gap-5 overflow-hidden px-3 md:my-5 md:flex-row md:px-5']"
    >
      <div :class="['flex grow flex-col overflow-hidden md:w-[60%] md:grow-0']">
        <div class="flex h-full flex-col items-center gap-5 overflow-hidden">
          <h2 class="hidden self-start text-lg md:flex">Transcript</h2>

          <div
            id="pdf-element"
            class="flex min-w-full flex-col gap-3 overflow-y-auto rounded-mlg border border-sc-grey-300 bg-white p-4 pt-0"
          >
            <div class="sticky -top-3 z-10 flex flex-col items-center justify-center bg-white pt-3">
              <div class="mt-4 flex h-[70px] w-[70px] self-center rounded-full">
                <img
                  :src="currentConversation?.avatar_url"
                  :alt="`Character avatar for ${currentConversation?.given_name} ${currentConversation?.family_name}`"
                  class="center-cropped h-full w-full rounded-full object-cover"
                />
              </div>

              <div class="flex self-center">
                <p>
                  {{ `${currentConversation?.given_name} ${currentConversation?.family_name}` }}
                </p>
              </div>
            </div>

            <template v-if="!transcriptLoading || true">
              <div
                v-for="(message, index) in transcripts[currentConversationIndex]"
                :key="index"
                :class="[
                  'flex max-w-[75%] items-center gap-3',
                  { 'self-end': !message.character_text }
                ]"
              >
                <div v-if="message?.character_text" class="h-[30px] w-[30px] rounded-full">
                  <img
                    class="center-cropped h-full w-full min-w-[30px] rounded-full object-cover"
                    :src="currentConversation?.avatar_url"
                    :alt="`Character avatar for ${currentConversation?.given_name} ${currentConversation?.family_name}`"
                  />
                </div>

                <div
                  :class="[
                    'relative flex rounded-[20px] p-3',
                    !message.character_text
                      ? 'self-end rounded-tr-none bg-sc-orange-300'
                      : 'rounded-tl-none bg-sc-grey-100'
                  ]"
                >
                  <div
                    v-if="message?.editedMessage"
                    class="absolute right-0 top-[-5px] rounded-full bg-sc-grey-800 px-1 text-[10px] text-white"
                  >
                    Corrected
                  </div>

                  <div v-if="message?.student_text || message?.character_text">
                    {{ message?.student_text || message?.character_text }}
                  </div>
                  <div v-else-if="message?.action_text">
                    <span class="font-medium">Action Taken: </span>{{ message.action_text }}
                  </div>
                  <div v-else-if="message?.investigation_text">
                    <span class="font-medium">Investigated : </span
                    >{{ message?.investigation_text }}
                  </div>
                </div>
                <template
                  v-if="
                    message.character_text &&
                    authStore.isSuperAdminUser &&
                    (currentConversation?.ended_at || route.params.conversationId !== 'vr-scene')
                  "
                >
                  <div
                    class="flex h-6 w-6 min-w-[1.5rem] cursor-pointer items-center justify-center rounded-full bg-sc-grey-100 hover:bg-sc-grey-200"
                    @click="openDataModal(message)"
                  >
                    <EllipsisHorizontalIcon class="h-4 w-4" />
                  </div>
                  <div
                    v-if="!message?.editedMessage"
                    class="flex h-6 w-6 min-w-[1.5rem] cursor-pointer items-center justify-center rounded-full bg-sc-grey-100 hover:bg-sc-grey-200"
                    @click="approveModelResponse(message)"
                  >
                    <HandThumbUpIcon class="h-3.5 w-3.5" />
                  </div>
                </template>
              </div>
            </template>

            <AppLoadingSpinner v-else loading />
          </div>

          <template v-if="!transcriptLoading">
            <CustomButton
              :startIcon="BookOpenIcon"
              buttonSize="lg"
              class="w-full md:hidden"
              @click="() => router.push({ name: 'History' })"
            >
              Back to History
            </CustomButton>
            <CustomButton
              class="w-full md:hidden"
              buttonSize="lg"
              :endIcon="ArrowDownTrayIcon"
              buttonType="admin-secondary"
              @click="generatePdf"
            >
              Download PDF
            </CustomButton>
          </template>
        </div>
      </div>

      <div class="flex flex-col gap-3 md:w-[40%] md:overflow-hidden">
        <div :class="['hidden h-full flex-col gap-3 md:flex md:overflow-hidden']">
          <div
            class="flex flex-col border-b border-sc-grey-300 pb-1.5 md:grow md:overflow-hidden md:border-b-0"
          >
            <div class="flex cursor-pointer items-center justify-between">
              <h3 class="-mt-[15px] flex h-[50px] min-h-[50px] items-center text-lg md:mt-0">
                Investigations Used
                <span class="pl-1 text-base text-sc-grey-600"
                  >({{ usedInvestigations?.length || 0 }})</span
                >
              </h3>
            </div>

            <div
              v-if="!utilsStore.isMobile"
              class="mb-3 flex w-full origin-top flex-col gap-3 transition-transform md:scale-100 md:overflow-hidden"
            >
              <template v-if="usedInvestigations?.length">
                <div class="flex flex-col gap-3 md:overflow-y-auto">
                  <div
                    v-for="{ investigation, investigationIndex } in usedInvestigations"
                    :key="`${investigationIndex}-investigation`"
                    :class="[
                      'flex h-[50px] min-h-[50px] w-full cursor-pointer items-center justify-center rounded-md border hover:bg-sc-grey-50'
                    ]"
                    @click="
                      () => {
                        openModal(investigationIndex)
                      }
                    "
                  >
                    <p class="text-lg">
                      {{ investigation?.investigation_text }}
                    </p>
                  </div>
                </div>
              </template>

              <p v-else class="mt-3 w-full text-center text-sc-grey-600">
                You did not view any investigations during this conversation
              </p>
            </div>
          </div>

          <div class="flex flex-col md:grow md:overflow-hidden">
            <div class="flex cursor-pointer items-center justify-between">
              <h3 class="flex h-[50px] min-h-[50px] items-center text-lg">
                Actions Used
                <span class="pl-1 text-base text-sc-grey-600"
                  >({{ usedActions?.length || 0 }})</span
                >
              </h3>
            </div>
            <div
              v-if="!utilsStore.isMobile"
              class="flex w-full origin-top flex-col gap-3 transition-transform md:overflow-hidden"
            >
              <template v-if="usedActions?.length">
                <div class="flex flex-col gap-3 md:overflow-y-auto">
                  <div
                    v-for="(action, actionIndex) in usedActions"
                    :key="`${actionIndex}-action`"
                    class="flex h-[50px] min-h-[50px] w-full items-center justify-center rounded-md border"
                  >
                    <p class="text-lg">
                      {{ action?.action_text }}
                    </p>
                  </div>
                </div>
              </template>
              <p v-else class="mt-3 w-full text-center text-sc-grey-600">
                You did not use any actions during this conversation
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <InvestigationModal
      v-model="modalStatus"
      :selectedInvestigation="currentConversation?.investigations?.[selectedInvestigationIndex]"
      @closeModal="closeModal"
    />

    <!-- data science modal -->
    <CustomModal v-model="dataScience.modalStatus" @onClose="closeDataModal">
      <div class="flex w-full flex-col gap-5 overflow-hidden">
        <h3 class="basis-auto text-xl">Correct character response</h3>
        <div>
          <CustomInput
            :modelValue="selectedTranscriptEntry?.character_text || ''"
            label="Original Response"
            inputType="textarea"
            readOnly
          />
          <CustomInput
            v-if="!dataScience.loading"
            v-model="dataScience.correctedResponse"
            inputType="textarea"
            :loading="dataScience.loading"
            label="Corrected Response"
          />

          <AppLoadingSpinner v-else loading />
        </div>
        <div class="flex gap-5">
          <CustomButton
            buttonType="admin-secondary"
            class="basis-auto self-end"
            @click="closeDataModal"
          >
            Cancel
          </CustomButton>
          <CustomButton
            class="basis-auto self-end"
            :disabled="dataScience.loading"
            @click="() => submitNewResponse()"
          >
            Submit new response
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  </div>
</template>
