<script setup lang="ts">
import type { ConversationContextApi, ConversationSummary } from '@/open-api/generated'
import { ref, onBeforeMount, reactive } from 'vue'
import dayjs from 'dayjs'
import CustomList from '@/components/CustomList.vue'
import Api from '@/open-api'
import { ChevronRightIcon } from '@heroicons/vue/24/outline'
import ConversationCompletionIndicator from '@/components/ConversationCompletionIndicator.vue'
import { onMounted } from 'vue'
import { useUtilsStore } from '@/stores/utils'
import { VR_SCENES_CHARACTER_IDS } from '@/vr/vr-constants'
import { useSimulationStore } from '@/stores/simulation'
import { useNotificationStore } from '@/stores/notifications'
import { NotificationStatus } from '@/types/notification'
import { useRouter, useRoute } from 'vue-router'
import { Breadcrumb } from '@/components/modern/page-navigation'
import type { VrConversationOutput } from '@/vr/types/vr'
import { companionConversationsOf, getParentTaskAttemptId } from '@/utils/vr'

definePage({
  name: 'History',
  meta: {
    permissionLevel: 'Student'
  }
})

// ==================================================
// Init
// ==================================================
const router = useRouter()
const route = useRoute('History')
const initLoading = ref(true)
const utilsStore = useUtilsStore()
const notificationStore = useNotificationStore()
const simulationStore = useSimulationStore()

function formatDateTime(formatMe: string) {
  return dayjs(formatMe).format('MMM DD, h:mma')
}

// ==================================================
// Table Management
// ==================================================

onMounted(() => {
  if (utilsStore.isMobile) {
    historyHeaders.value = [
      {
        name: 'Character',
        value: 'given_name'
      },
      {
        name: 'Status',
        value: 'custom'
      }
    ]
  } else {
    historyHeaders.value = [
      {
        name: 'Character',
        value: 'given_name'
      },
      {
        name: 'Task',
        value: 'task_name'
      },
      {
        name: 'Assignment',
        value: 'assignment_name'
      },
      {
        name: 'Cohort',
        value: 'cohort_name'
      },
      {
        name: 'Started on',
        value: 'started_at'
      },
      {
        name: 'Status',
        value: 'custom'
      },
      {
        name: 'Actions',
        value: 'custom-view'
      }
    ]
  }
})

const historyHeaders = ref<{ name: string; value: string }[]>([])

const conversationsPagination = reactive({ items_per_page: 15, page: 1, total: 0 })

// ==================================================
// Conversations
// ==================================================
let conversationsList = reactive<(ConversationSummary & Partial<ConversationContextApi>)[]>([])

onBeforeMount(async () => {
  let querySearch = ''

  if (route.query.search) {
    search.value = route.query.search as string
    querySearch = route.query.search as string
  }

  setConversationList(querySearch)
})

const search = ref('')

const setConversationList = async (searchString: string) => {
  initLoading.value = true

  try {
    if (searchString !== search.value) {
      conversationsPagination.page = 1
    }

    search.value = searchString
    const conversations = await Api.Conversation.listConversationsEndpoint(
      conversationsPagination.items_per_page,
      conversationsPagination.page,
      undefined,
      searchString || undefined // '' is falsy so this will replace with undefined
    )

    router.push({
      query: {
        ...route.query,
        search: search.value
      }
    })
    conversationsPagination.total = conversations.pagination.total_pages || 1

    conversationsList = conversations.conversations.map((conv) => ({
      ...conv,
      started_at: formatDateTime(conv.started_at) || '',
      ended_at: conv.ended_at ? formatDateTime(conv.ended_at) : '',
      ...conv?.context,
      status: !!conv.ended_at
    }))
  } catch (err: any) {
    notificationStore.addNotification({
      subtitle: err?.body?.message,
      status: NotificationStatus.DANGER
    })
  } finally {
    initLoading.value = false
  }
}

const changePage = (page: number) => {
  conversationsPagination.page = page
  setConversationList(search.value)
}

const canView = (conversation: ConversationSummary & Partial<ConversationContextApi>) => {
  if (conversation.ended_at) {
    return true
  } else if (conversation?.context?.conversation_task_attempt_id) {
    if (!conversation.context.task_scene_id) {
      return true
    }
  } else if (!VR_SCENES_CHARACTER_IDS.includes(conversation.character_id)) {
    return true
  }
  return false
}

const onView = (data: { id: string; index: number }) => {
  const conversation = conversationsList.find((conv) => conv.conversation_id === data.id)

  if (conversation) {
    if (utilsStore.historyUserId && !conversation.ended_at) {
      // "View user's history" --> Settings -> Users
      notificationStore.addNotification({
        subtitle: 'Sorry, you can only view completed conversations',
        status: NotificationStatus.DANGER
      })
    } else if (conversation.ended_at) {
      // Cordoning: only runs this logic if the character_id is found in the hardcoded list of VR character ids
      if (VR_SCENES_CHARACTER_IDS.includes(conversation.character_id)) {
        // Completed vr conversation
        // find all other conversations that
        //  1. ended at this time
        //  2. are by the same user_id
        // ... in order to recreate simulationStore.vrConversation
        const convBundle = conversationsList.filter(companionConversationsOf(conversation))
        simulationStore.setVrConversation(convBundle as unknown as VrConversationOutput[])
        const taskAttemptId = getParentTaskAttemptId(convBundle)

        if (taskAttemptId) {
          router.push({
            name: 'MyAssignments Conversation Transcript',
            params: { conversationId: 'vr-scene', taskAttemptId }
          })
        } else {
          notificationStore.addINFO('The parent task for this conversation is on the next page.')
        }
      } else {
        // Completed regular conversation
        router.push({
          name: 'History Transcript',
          params: {
            conversationId: data.id,
            taskAttemptId: conversation?.context?.conversation_task_attempt_id
          }
        })
      }
    } else if (conversation?.context?.conversation_task_attempt_id) {
      if (!conversation.context.task_scene_id) {
        // Incomplete conversation task attempt
        router.push({
          name: 'MyAssignments Conversation',
          params: {
            taskAttemptId: conversation?.context?.conversation_task_attempt_id,
            conversationId: conversation.conversation_id
          }
        })
      }
    } else if (VR_SCENES_CHARACTER_IDS.includes(conversation.character_id)) {
      // Incomplete conversation from VR scene
      notificationStore.addNotification({
        subtitle:
          'Sorry, you cannot access incomplete conversations associated with VR scenes at this time',
        status: NotificationStatus.DANGER
      })
    } else {
      // Fallback
      notificationStore.addNotification({
        subtitle: 'Sorry, you cannot access this conversation at this time',
        status: NotificationStatus.DANGER
      })
    }
  }
}
</script>

<template>
  <Breadcrumb :route="{ name: 'History' }" name="My History" />
  <CustomList
    :pagination="conversationsPagination"
    :hasListOptions="false"
    viewType="table"
    :listHeaders="historyHeaders"
    :listData="conversationsList || []"
    hasHeader
    :loading="initLoading"
    :search
    @onView="onView"
    @onChangePage="(page: number) => changePage(page)"
    @onSearch="(search: string) => setConversationList(search)"
  >
    <template #custom="{ data }: { data: any }">
      <ConversationCompletionIndicator :completed="data.status" />
    </template>

    <template #custom-view="{ data }: { data: any }">
      <div v-if="canView(data)" class="z-20 flex items-center gap-1.5 [&>*]:text-sc-grey-600">
        <p>View</p>
        <ChevronRightIcon class="h-2.5" />
      </div>
    </template>
  </CustomList>
</template>
