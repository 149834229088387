<script setup lang="ts">
import { ref } from 'vue'
import { SCHEDULED_DOWNTIME } from '@/constants/environment'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import useHandledInterval from '@/composables/useHandledInterval'

dayjs.extend(relativeTime)

const downtime = ref<
  | null // Downtime is far away, do not show downtime component
  | string // Downtime is soon, show "time until" and link
  | true // Downtime is now, auto redirect to downtime page
>(null)

const downtimeSoon = dayjs(SCHEDULED_DOWNTIME).subtract(4, 'hour')
const downtimeNow = dayjs(SCHEDULED_DOWNTIME)
const downtimeOver = dayjs(SCHEDULED_DOWNTIME).add(10, 'minute')

useHandledInterval().set(() => {
  const now = dayjs()
  if (now.isBefore(downtimeSoon)) {
    downtime.value = null
  } else if (now.isBefore(downtimeNow)) {
    downtime.value = downtimeNow.fromNow()
  } else if (now.isBefore(downtimeOver)) {
    downtime.value = true
    window.location.href = '/downtime.html'
  }
}, 1000)
</script>

<template>
  <template v-if="downtime">
    <a
      href="/downtime.html"
      class="absolute right-4 top-2 text-sm font-bold text-sc-orange-400 hover:underline"
      title="SimConverse will be down for maintenance for approximately 10 minutes."
    >
      Maintenance {{ downtime }}
    </a>
  </template>
</template>
