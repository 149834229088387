<script setup lang="ts">
import { useNotificationStore } from '@/stores/notifications'
import { injectUseGetMyCohorts } from '@/composables/api/queries/useGetMyCohorts'
import { DataTable } from '@/components/modern/ui/data-table'
import { useColumns } from '@/components/modern/tables/educator-cohorts'
import { TopLine } from '@/components/modern/page-navigation'

definePage({
  name: 'Modern Cohorts - My Cohorts List',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const notificationStore = useNotificationStore()
const { isLoading, cohorts } = injectUseGetMyCohorts({ notificationStore })

const columns = useColumns()
</script>

<template>
  <TopLine>
    <template #left>
      <span>My Cohorts</span>
    </template>
  </TopLine>
  <DataTable :data="cohorts" :columns :isLoading />
</template>
