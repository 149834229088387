<script setup lang="ts">
import { computed, onBeforeMount } from 'vue'
import { useRouter, type RouterLinkProps } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import AppBrainIcon from '@/components/AppBrainIcon.vue'

definePage({
  name: '/',
  meta: {
    permissionLevel: 'ANY'
  }
})

const authStore = useAuthStore()
const toAppropriateHomePage = computed<RouterLinkProps['to']>(() => {
  if (authStore.isAtLeastInstitutionAdminUser) {
    if (authStore.flagModernEnabled) {
      return {
        name: 'Modern Cohorts - Cohort Administration'
      }
    } else {
      return {
        name: 'Cohorts'
      }
    }
  }
  if (authStore.isAtLeastEducatorUser) {
    if (authStore.flagModernEnabled) {
      return {
        name: 'Modern Cohorts - My Cohorts List'
      }
    } else {
      return {
        name: 'Cohorts'
      }
    }
  } else {
    if (authStore.flagModernEnabled) {
      return {
        name: 'Modern - My Activities'
      }
    } else {
      return {
        name: 'MyAssignments'
      }
    }
  }
})

const router = useRouter()
onBeforeMount(() => router.push(toAppropriateHomePage.value))
</script>

<template>
  <div class="flex h-full flex-col">
    <div class="flex flex-1" />
    <div class="flex flex-1 flex-col gap-6">
      <AppBrainIcon class="mx-auto aspect-square max-h-64 max-w-64" />
      <h1 class="flex flex-1 items-center justify-center text-2xl font-medium text-sc-grey-800">
        Welcome to SimConverse.
      </h1>
      <RouterLink
        class="flex flex-1 items-center justify-center text-base text-blue-400 underline"
        :to="toAppropriateHomePage"
      >
        Click here if you are not redirected.
      </RouterLink>
    </div>
    <div class="flex flex-1" />
  </div>
</template>
